const Typologies = {
    STATUS: 48,
        ACTIVE: 49,
        DELETED: 51,
    VIDEO_PROVIDER: 1190,
        VT_YOUTUBE: 1191,
        VT_EXTERN_VIDEO: 1192,
        VT_LOCAL_VIDEO: 1193,
        VT_VIMEO: 1194,
        VT_TWITCH: 1195,
        VT_LIVESESSION: 1196,
    SUBSCRIPTION_STATUS: 1260,
        SS_ACTIVE: 1261,
        SS_INACTIVE: 1262,
        SS_EXPIRED: 1263,
        SS_PAUSED: 1264,
        SS_DELETED: 1265,
    PROJECTION_TYPE: 1280,
        PT_STANDARD: 1281,
        PT_360: 1282,
        PT_360_CUBIC: 1283,
        PT_360_TB: 1284,
        PT_180: 1285,
        PT_180_MONO: 1286,
        PT_EAC: 1287,
        PT_EAC_LR: 1288,
    USER_ACCOUNT_TYPE: 1290,
        UAT_NORMAL: 1291,
        UAT_PROFESSIONAL: 1292,
    LOCK_MARKER_SUMMARY_OPTION: 1295,
        LMSO_ACTIVE: 1296,
        LMSO_INACTIVE: 1297,
        LMSO_TIMER: 1298
}

export { Typologies };
