import axios from 'axios';

function listMarkersByVideo(hashId) {
    return axios.get(`/vtg-markers/by-video/${hashId}`);
}

function listMarkerCounts(hashId) {
    return axios.get(`/vtg-markers/by-video/n/${hashId}`);
}

function addMarkerAtVideo(newMarkerVideo) {
    return axios.post('/vtg-markers/at-video', newMarkerVideo);
}

function importMarkersForVideo(videoHashId, markersToImport) {
    const adjustedMarkers = markersToImport.map(marker => ({
        ...marker,
        videoTime: adjustVideoTimeFormat(marker.videoTime)
    }));
    return axios.post(`/vtg-markers/import-for-video/${videoHashId}`, adjustedMarkers);
}

function importMarkersDirect(videoHashId, markersToImport) {
    return axios.post(`/vtg-markers/import/${videoHashId}`, markersToImport);
}

function updateMarkerAtVideo(currentMarker, videoMarkerId) {
    return axios.put(`/vtg-markers/at-video/${videoMarkerId}`, currentMarker);
}

function offsetMarkers(videoId, offset, markerIds) {
    const request = { videoId, offset, markerIds };
    return axios.put(`/vtg-markers/move-all/`, request);
}

function deleteMarkerAtVideo(videoMarkerId) {
    return axios.delete(`/vtg-markers/at-video/${videoMarkerId}`);
}

function addCommentAtMarker(videoMarkerId, comment) {
    const request = { comment, videoMarkerId };
    return axios.post(`/vtg-markers/comment`, request);
}

function listAllCommentsByMarker(videoMarkerId) {
    return axios.get(`/vtg-markers/all-comments/`, {params: {markerId:videoMarkerId} });
}

function analyzeSubtitles(format, content, tags) {
    const request = {format, content, tags};
    return axios.post('/vtg-markers/analyze/from-subtitles', request);
}  

function adjustVideoTimeFormat(videoTime) {
    return videoTime.replace(/:(\d{3})$/, '.$1');
}

export { 
    listMarkersByVideo, listMarkerCounts,
    importMarkersForVideo, offsetMarkers,
    addMarkerAtVideo, updateMarkerAtVideo, deleteMarkerAtVideo,
    addCommentAtMarker, listAllCommentsByMarker,
    analyzeSubtitles, importMarkersDirect
};
