import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPause, faPlay, faVolumeUp, faVolumeLow, faVolumeOff,faVolumeMute } from "@fortawesome/free-solid-svg-icons";
import { convertSecondsToTime } from "../../utils/NabUtils";
import './VimeoPlayer.css';
import { useLocation } from "react-router-dom";
import { config } from '../../config';
import { Typologies } from '../../utils/TypologiesEnum';

function VimeoPlayer({
        vimeoVideoId,
        videoPlaying, setVideoPlaying,
        videoDuration, setVideoDuration,
        currentVideoTime, setCurrentVideoTime,
        externSeekTo, externSetVimeoPlayer,
        showModal, showSummary, showFavs, lockMarkerVitagSummary, disableKeys, showShareModal, showModalMoreInfo
    }) {

    const [vimeoPlayer, setVimeoPlayer] = useState();
    const [vimeoIsPlaying, setVimeoIsPlaying] = useState(false);
    const [vimeoDurationText, setVimeoDurationText] = useState('-:--');
    const [vimeoCurrentTimeText, setVimeoCurrentTimeText] = useState('-:--');
    const [vimeoProgressPercent, setVimeoProgressPercent] = useState(0);
    const [vimeoVolume, setVimeoVolume] = useState(100);
    const [vimeoIsMuted, setVimeoIsMuted] = useState(false);
    const [vimeoTimeIndicator, setVimeoTimeIndicator] = useState('-:--');
    const [vimeoShowTimeIndicator, setVimeoShowTimeIndicator] = useState(false);
    const [vimeoPositionTimeIndicator, setVimeoPositionTimeIndicator] = useState(0);
    const location = useLocation();

    useEffect(() => {
        setTimeout(() => {
            var iframe = document.getElementById('vimeo-player');
            var player = new window.Vimeo.Player(iframe);
            player.on('timeupdate', data => {
                setVimeoProgressPercent(data.percent * 100);
                changeVideoCurrentTime(data.seconds);
                changeVideoDuration(data.duration);
            });
            player.on('pause', _ => {
                setVimeoIsPlaying(false);
                setVideoPlaying(false);
            });
            player.on('play', _ => {
                setVimeoIsPlaying(true);
                setVideoPlaying(true);
            });
            player.ready().then(() => {
                if(config.IS_BETA) console.log('Vimeo player is ready');
                player.pause();
                player.getDuration().then(duration => {
                    changeVideoDuration(duration);
                    changeVideoCurrentTime(0.0);
                });
                const newTime = location.state?.last || 0;
                player.setCurrentTime(newTime);
                setCurrentVideoTime(newTime);
            });
            setVimeoPlayer(player);
            externSetVimeoPlayer(player);
        }, 800);
    }, []);

    useEffect(() => {
        videoPlaying ? vimeoPlay() : vimeoPause();
    }, [videoPlaying]);

    useEffect(() => {
        if(config.IS_BETA) console.log('Extern seek-to: ' + externSeekTo);
        changeVideoCurrentTime(externSeekTo);
        vimeoSeekTo(externSeekTo);
    }, [externSeekTo]);

    const changeVideoDuration = (timeInSeconds) => {
        const duration = convertSecondsToTime(timeInSeconds);
        setVideoDuration(timeInSeconds);
        setVimeoDurationText(duration);
    }

    const changeVideoCurrentTime = (timeInSeconds) => {
        const currentTime = convertSecondsToTime(timeInSeconds);
        setCurrentVideoTime(timeInSeconds);
        setVimeoCurrentTimeText(currentTime);
    }

    const handleMoveToClick = evt => {
        const progressBarContainer = document.getElementById('vimeo-progress-bar').getBoundingClientRect();
        const position = evt.clientX - progressBarContainer.left;
        const moveToTime = videoDuration * (position / progressBarContainer.width);
        vimeoSeekTo(moveToTime);
    }

    const handleProgressBarInside = evt => {
        setVimeoShowTimeIndicator(true);
    }

    const handleProgressBarOutside = evt => {
        setVimeoShowTimeIndicator(false);
    }

    const handleProgressBarMovement = evt => {
        const pbRect = document.getElementById('vimeo-progress-bar').getBoundingClientRect();
        const time = convertSecondsToTime(videoDuration * (evt.clientX - pbRect.left) / pbRect.width);
        setVimeoTimeIndicator(time);
        setVimeoPositionTimeIndicator(evt.clientX - 32);
    }

    const handleVolumeChange = evt => {
        const newVolume = evt.target.value;
        setVimeoVolume(newVolume);
        vimeoPlayer?.setVolume(newVolume / 100).then(_ => {
            if(config.IS_BETA) console.log('Volume updated...')
        });
    }

    const vimeoPlay = () => {
        vimeoPlayer?.play();
    }

    const vimeoPause = () => {
        vimeoPlayer?.pause();
    }

    const vimeoSeekTo = newTime => {
        setCurrentVideoTime(newTime);
        vimeoPlayer?.setCurrentTime(newTime);
    }

    useEffect(() => {
    
        if (!vimeoPlayer) {
          return;
        }
    
        const handleKeyDown = (e) => {
          if (!disableKeys && !showShareModal && !showModalMoreInfo && !showModal  && !showFavs && (lockMarkerVitagSummary === Typologies.LMSO_INACTIVE || !showSummary)) {
            switch (e.key) {
              case ' ':
                if (vimeoIsPlaying) {
                    vimeoPause();
                } else {
                    vimeoPlay();
                }
                e.preventDefault(); 
                break;
              case 'm':
                vimeoPlayer.getVolume().then(volume => {
                    if (volume > 0) {
                        vimeoPlayer.setVolume(0);
                        setVimeoIsMuted(true);
                    } else {
                        vimeoPlayer.setVolume(vimeoVolume / 100);
                        setVimeoIsMuted(false);
                    }
                });
              break;
              case ',':
                vimeoPlayer.pause().then(() => {
                    vimeoSeekTo(currentVideoTime - 1 / 30);
                });
                break;
              case '.':
                vimeoPlayer.pause().then(() => {
                    vimeoSeekTo(currentVideoTime + 1 / 30);
                });
                break;
              case 'ArrowLeft':
                if (e.shiftKey) {
                    vimeoPlayer.pause().then(() => {
                        vimeoSeekTo(currentVideoTime - 1 / 30);
                    });
                } else {
                    vimeoSeekTo(currentVideoTime - 5);
                }
                break;
              case 'ArrowRight':
                if (e.shiftKey) {
                    vimeoPlayer.pause().then(() => {
                        vimeoSeekTo(currentVideoTime + 1 / 30);
                    });
                } else {
                    vimeoSeekTo(currentVideoTime + 5);
                }
                break;
              case 'j':
                vimeoSeekTo(currentVideoTime - 10);
                break;
              case 'l':
                vimeoSeekTo(currentVideoTime + 10);
                break;
              case 'ArrowUp':
                const newVolumeUp = Math.min(vimeoVolume + 5, 100);
                    setVimeoVolume(newVolumeUp);
                    vimeoPlayer.setVolume(newVolumeUp / 100);
                break;
              case 'ArrowDown':
                const newVolumeDown = Math.max(vimeoVolume - 5, 0);
                    setVimeoVolume(newVolumeDown);
                    vimeoPlayer.setVolume(newVolumeDown / 100);
                break;
              case '1':
              case '2':
              case '3':
              case '4':
              case '5':
              case '6':
              case '7':
              case '8':
              case '9':
                const percent = parseInt(e.key, 10) / 10;
                    vimeoSeekTo(videoDuration * percent);
                break;
              case '0':
              case 'Home':
                vimeoSeekTo(0);
                break;
              case 'End':
                vimeoSeekTo(videoDuration);
                break;
              default:
                break;
            }
          }
        };
    
        window.addEventListener('keydown', handleKeyDown);
    
        return () => {
          window.removeEventListener('keydown', handleKeyDown);
        };
      }, [vimeoPlayer, vimeoIsMuted, vimeoIsPlaying, vimeoVolume, currentVideoTime, videoDuration, disableKeys, showModal, showSummary, showFavs, lockMarkerVitagSummary, showShareModal, showModalMoreInfo, vimeoSeekTo, vimeoPause, vimeoPlay]);

    return (
        <>
            <iframe
                id='vimeo-player' frameBorder="0" crossOrigin="anonymous"
                style={{position:'absolute', width:'100%', height:'100%'}}
                src={`https://player.vimeo.com/video/${vimeoVideoId}?autoplay=1&muted=1&loop=0&background=1`}>
            </iframe>
            <div className="d-flex w-100 position-absolute p-3 gap-3"
                style={{bottom:0,backgroundColor:'rgba(57,57,57,0.12)'}}>
                { ! vimeoIsPlaying ?
                <button onClick={vimeoPlay} className="btn text-light">
                    <FontAwesomeIcon icon={faPlay}/>
                </button> :
                <button onClick={vimeoPause} className="btn text-light">
                    <FontAwesomeIcon icon={faPause}/>
                </button> }
                <span className="my-auto small text-light" style={{minWidth:'80px'}}>
                    { vimeoCurrentTimeText + ' / ' + vimeoDurationText }
                </span>
                { vimeoShowTimeIndicator &&
                <span className="time-indicator position-absolute" style={{left:vimeoPositionTimeIndicator+'px'}}>
                    {vimeoTimeIndicator}
                </span>}
                <div id="vimeo-progress-bar" className="my-auto vimeo-progress-bar" style={{flexGrow:'1'}}
                    onClick={handleMoveToClick} onMouseMove={handleProgressBarMovement}
                    onMouseEnter={handleProgressBarInside} onMouseLeave={handleProgressBarOutside}>
                    <div className="le-progress position-absolute" style={{top:0,bottom:0,width:vimeoProgressPercent+'%'}}></div>
                </div>
                <div className="dropdown">
                    {!vimeoIsMuted && <button className="btn text-light" style={{width:'48px', textAlign:'left'}} data-toggle="dropdown">
                        { vimeoVolume >= 66 ? <FontAwesomeIcon icon={faVolumeUp}/>
                          : vimeoVolume >= 33 ? <FontAwesomeIcon icon={faVolumeLow}/>
                          : vimeoVolume > 0 ? <FontAwesomeIcon icon={faVolumeOff}/> 
                          : <FontAwesomeIcon icon={faVolumeMute}/>
                        }
                    </button>}
                    {vimeoIsMuted && <button className="btn text-light" style={{width:'48px', textAlign:'left'}} data-toggle="dropdown">
                        <FontAwesomeIcon icon={faVolumeMute}/>
                    </button>}
                    <div className="dropdown-menu dropdown-menu-right p-0 m-0" style={{backgroundColor:'#ededed',lineHeight:'0'}}>
                        <input id="volume-control" className="volume-control my-0 mx-3 py-1 rounded" type="range" style={{backgroundColor:'#ededed'}}
                            onChange={handleVolumeChange} min={0} max={100} step={2} value={vimeoVolume}/>
                    </div>
                </div>
            </div>
        </>
    );
}

export { VimeoPlayer }