import axios from 'axios';

function listFavoriteTagsByVideo(videoId) {
    return axios.get(`/vtg-tags/favorites/${videoId}`);
}

function addFavoriteTagsAtVideo(videoId, favTags, overrideGlobalFavs = false) {
    return axios.put(`/vtg-tags/favorites/${videoId}`, favTags, {params:{global:overrideGlobalFavs}});
}

export { 
    listFavoriteTagsByVideo, addFavoriteTagsAtVideo
};
