import React, { useState, useEffect } from "react";
import classNames from 'classnames';
import { Parameters } from '../utils/ParametersEnum';
import { getParameterByName } from "../services/CoreService";
import { listAllPlans } from "../services/VitagSubscriptionsService";
import { useTranslation } from 'react-i18next';

function UpgradeComponent({showModal, setShowModal, showCheckout}) {

  const [isMonthlyPrice, setIsMonthlyPrice] = useState(false);
  const [isMonthly, setIsMonthly] = useState(false);
  const { t } = useTranslation();
  const [annualPrice, setAnnualPrice] = useState('');
  const [monthlyPrice, setMonthlyPrice] = useState('');
  const [fadeClass, setFadeClass] = useState('');
  const [allPlans, setAllPlans] = useState([]);

  const handleToggle = (monthly) => {
    setFadeClass('fade-out');

    setTimeout(() => {
      setIsMonthlyPrice(monthly); 
      setFadeClass('fade-in');
    }, 350); 
     
    setIsMonthly(monthly);  
  };

  useEffect(() => {
    if (showModal) {
      initModal();
    }
  }, [showModal]);

  const initModal = () => {
    getParameterByName(Parameters.PRO_ACCOUNT_MONTHLY_PRICE_IN_EUROS)
        .then(resp => { 
            setMonthlyPrice(resp.data.value1);
        });
    getParameterByName(Parameters.PRO_ACCOUNT_ANNUAL_PRICE_IN_EUROS)
        .then(resp => { 
            setAnnualPrice((parseFloat(resp.data.value1)/12).toFixed(2));
        });
    listAllPlans()
        .then(resp => { 
          setAllPlans(resp.data);
        });
  }

  const selectPlan = () => {
    if (isMonthlyPrice) {
      const monthPlanId = allPlans.find(plan => plan.cycle === 'month');
      showCheckout(monthPlanId);
    } else {
      const yearPlanId = allPlans.find(plan => plan.cycle === 'year');
      showCheckout(yearPlanId);
    }
  }

    return (
      <div className="container d-flex flex-column top-margin px-0 mx-0">
        <div className="align-items-center text-center mt-1">
          <div className="d-inline-block mt-3">
            <div className="d-flex p-1 tier-qty-container cursor-pointer" style={{borderRadius: '24px', backgroundColor: '#f8f9fa'}}>
              <div onClick={() => handleToggle(true)} className={classNames('tier-qty h7 fw-bold', { 'active': isMonthly })}>{t('modal_upgradepricing_monthly')}</div>
              <div onClick={() => handleToggle(false)} className={classNames('tier-qty h7 fw-bold', { 'active': !isMonthly })}>{t('modal_upgradepricing_annual')}</div>
            </div>
          </div>
          <div className="col-lg-6 col-12 my-3 mx-auto px-1">
            <div className="" style={{padding: '24px 12px', borderRadius: '12px', color: '#0c0c0c', backgroundColor: '#f8f9fa'}}>
              <p className="h1 fw-bold">PRO</p>
              <div className="row mt-2">
                <div className={`col-12 ${fadeClass}`}>
                  {isMonthlyPrice && <span className="h4 fw-bold">{monthlyPrice}€</span>}
                  {!isMonthlyPrice && <span className="h4 fw-bold">{annualPrice}€</span>}
                </div>
                <span className={"col-12"}>{t('modal_upgradepricing_permonth')}</span>
                <div className={`col-12 mt-2 ${fadeClass}`}>
                  {!isMonthlyPrice && <span className="" style={{fontSize: '14px'}}>{t('modal_upgradepricing_billedannually')}</span>}
                </div>
              </div>
              <button onClick={() => selectPlan()} className="btn btn-md btn-primary bg-vitag fw-bold mt-3 w-100">{t('modal_upgradepricing_button')}</button>
              <p className="text-light bg-dark mt-3 mb-3 py-2 h16 d-flex justify-content-center align-items-center">
              {t('modal_upgradepricing_feature1')}
              </p>
              <p className="h16">{t('modal_upgradepricing_feature2')}</p>
              <hr className="solid"/>
              <p className="h16">{t('modal_upgradepricing_feature3')}</p>
            </div>
          </div>
        </div>
      </div>
    );
}

export { UpgradeComponent };
