import React, { useEffect, useState } from 'react';
import urlParser from 'nab-js-video-url-parser';
import { Row, Col, Modal, Alert, Spinner } from 'react-bootstrap';
import Papa from 'papaparse';
import { listTypologiesByInternalId } from '../services/CoreService';
import { Typologies } from '../utils/TypologiesEnum';
import { importVideos } from '../services/VitagVideosService';
import moment from 'moment';
import { ConfirmModal } from '../general/ConfirmModal';
import { Trans, useTranslation } from 'react-i18next';
import { config } from '../config';
import { useCustomToast } from '../utils/toasts/useCustomToast';

function ModalImportVideos({ showModal, setShowModal, successListener } ) {

    const [toImport, setToImport] = useState([]);
    const [fileName, setFileName] = useState('');
    const [videoProviders, setVideoProviders] = useState([]);
    const [importSummary, setImportSummary] = useState();
    const [working, setWorking] = useState(false);
    const [hasErrors, setHasErrors] = useState(false);
    const { addToast } = useCustomToast();
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const { t } = useTranslation();

    const COLUMNS = {
        TITLE:0, URL:1, SHARE_TYPE:2, FROM_DATE:3, THRU_DATE:4, EMAILS:5, GROUPS:6
    };

    useEffect(() => {
        listTypologiesByInternalId(Typologies.VIDEO_PROVIDER)
            .then(resp => {
                const vps = {};
                resp.data.forEach(typ => {
                    switch(typ.internalId) {
                        case Typologies.VT_LOCAL_VIDEO:
                            vps.localVideoProvider = typ; break;
                        case Typologies.VT_YOUTUBE:
                            vps.youtubeProvider = typ; break;
                        case Typologies.VT_VIMEO:
                            vps.vimeoProvider = typ; break;
                        case Typologies.VT_TWITCH:
                            vps.twitchProvider = typ; break;
                        case Typologies.VT_EXTERN_VIDEO: 
                            vps.externVideoProvider = typ; break;
                        case Typologies.VT_LIVESESSION: 
                            vps.liveSessionProvider = typ; break;
                    }
                });
                setVideoProviders(vps);
            })
            .catch(err => {
                if(config.IS_BETA) console.error('Error getting typologies', err)
            });        
    }, [])
    
    
    const initModal = () => {
        setImportSummary(null);
        setShowSuccessMessage(false);
        setFileName('');
        setToImport([]);
        setHasErrors(false);
        setWorking(false);
    }

    const closeThisModal = () => {
        setShowModal(false);
    }

    const startImportVideos = () => {
        setWorking(true);
        setTimeout(() =>
            importVideos(toImport)
                .then(_ => {
                    closeThisModal();
                    setShowSuccessMessage(true);
                })
                .catch(err => {
                    if(config.IS_BETA) console.error('Error', err)
                    if(err.response.data == 'No space for more videos on free account'){
                        
                        addToast({
                            header: t('error_no_more_videos_title'),
                            body: t('error_no_more_videos_body'),
                            variant: 'error'
                        });
                    }
                })
                .finally(_ => setWorking(false))
            , 4000);
    }


    const startFileSelection = () => {
        document.getElementById('import-video-input').click();
    }

    const handleFileSelection = (event) => {
        var file = event.target.files[0];
        setFileName(file.name);
        Papa.parse(file, { complete:handleParseResult });
    }

    const handleParseResult = (result) => {
        const newSummary = {
            errors: [],
            rows: result.data.length - 1
        };
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const shareTypeRegexp = /^(lectura|readonly)|(individual)|(colaborativo|colaborativa|collaborative)$/i;
        const shortShareTypeRegexp = /^\(((L|R)|(I)|(C))\)$/i;
        const importRegexpForEmails = /^(\([LRCI]\))?(\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4}))+$/i;
        const importRegexpForGroups = /^(\([LRCI]\))?(.+)$/i;
        const rows = result.data.slice(1)
            .filter(rowData => rowData.length > 1)
            .map((row, idx) => {
                const newRow = {};
                let rowShareTypeEmails = [];
                newRow.emailsReadonly = [];
                newRow.emailsIndividual = [];
                newRow.emailsCollaborative = [];
                newRow.videoTitle = row[COLUMNS.TITLE];
                newRow.videoUrl = row[COLUMNS.URL];
                newRow.onlyRead = false;
                newRow.individualEdit = false;
                newRow.collaborativeEdit = false;
                const urlDetails = parseVideoURL(row[COLUMNS.URL]);
                newRow.videoProvider = urlDetails.vp;
                newRow.videoOnlineId = urlDetails.onlineId;
                if (row[COLUMNS.SHARE_TYPE]) {
                    const shareType = row[COLUMNS.SHARE_TYPE].trim().toLowerCase();
                    const shareTypeResult = shareTypeRegexp.exec(shareType);
                    if (! shareTypeResult) {
                        if(config.IS_BETA) console.error(`Invalid share-type on row ${idx + 1}, found: '${shareType}'`);
                        newSummary.errors.push({row:idx+1, message:`Valor incorrecto en modo de compartir '${shareType}'`});
                        return null;
                    }
                    if (shareTypeResult[1]) {
                        newRow.onlyRead = true;
                        rowShareTypeEmails = newRow.emailsReadonly;
                    } else if (shareTypeResult[2]) {
                        newRow.individualEdit = true;
                        rowShareTypeEmails = newRow.emailsIndividual;
                    } else if (shareTypeResult[3]) {
                        newRow.collaborativeEdit = true;
                        rowShareTypeEmails = newRow.emailsCollaborative;
                    }
                    newRow.shareType = shareType;
                } else {
                    newRow.shareType = 'N/A';
                }
                newRow.tz = timezone;
                if (row[COLUMNS.FROM_DATE]) {
                    const fromDate = moment(row[COLUMNS.FROM_DATE], 'DD/MM/yyyy HH:mm', true);
                    if (! fromDate.isValid()) {
                        newSummary.errors.push({row:idx+1, message:`Fecha inicio incorrecta: '${row[COLUMNS.FROM_DATE]}' el formato debe ser DD/MM/AAAA HH:mm`})
                        return null;
                    }
                    newRow.fromDate = fromDate.format('yyyy-MM-DD HH:mm:ss');
                }
                if (row[COLUMNS.THRU_DATE]) {
                    const thruDate = moment(row[COLUMNS.THRU_DATE], 'DD/MM/yyyy HH:mm', true);
                    if (! thruDate.isValid()) {
                        newSummary.errors.push({row:idx+1, message:`Fecha fin incorrecta: '${row[COLUMNS.THRU_DATE]}' el formato debe ser DD/MM/AAAA HH:mm`})
                        return null;
                    }
                    newRow.thruDate = thruDate.format('yyyy-MM-DD HH:mm:ss');
                }
                if (row[COLUMNS.EMAILS]) {
                    row[COLUMNS.EMAILS].split(',')
                        .forEach(rec => {
                            const result = importRegexpForEmails.exec(rec);
                            if (! result) {
                                if(config.IS_BETA) console.error(`Invalid email at ${idx + 1}, found: ${rec}`);
                                return;
                            }
                            const email = result[2];
                            const shortShareType = result[1];
                            if (! shortShareType) {
                                rowShareTypeEmails.push(email);
                                return;
                            }
                            const shortShareTypeResult = shortShareTypeRegexp.exec(shortShareType);
                            if(config.IS_BETA) console.log('Shore-type for: ' + email, shortShareTypeResult);
                            if (shortShareTypeResult[2]) {
                                newRow.emailsReadonly.push(email);
                            } else if (shortShareTypeResult[3]) {
                                newRow.emailsIndividual.push(email);
                            } else if (shortShareTypeResult[4]) {
                                newRow.emailsCollaborative.push(email);
                            }
                        });
                }
                if (row[COLUMNS.GROUPS]) {
                    newRow.groups = row[COLUMNS.GROUPS].split(',');
                }
                return newRow;
            })
            .filter(row => row != null);
        setImportSummary(newSummary);
        setToImport(rows);
        if(config.IS_BETA) console.error('Errors on import...', newSummary.errors);
        setHasErrors(newSummary.errors.length > 0);
    }

    const handleURLClick = (event) => {
        if (! event.ctrlKey) {
            event.preventDefault();
        }
    }

    const parseVideoURL = (url) => {
        const info = urlParser.parse(url);
        if (info?.provider?.toLowerCase() === 'youtube') {
            return {vp: videoProviders.youtubeProvider, onlineId: info.id};
        }
        if (info?.provider?.toLowerCase() === 'vimeo') {
            return {vp: videoProviders.vimeoProvider, onlineId: info.id};
        }
        if (info?.provider?.toLowerCase() === 'twitch') {
            return {vp: videoProviders.twitchProvider, onlineId: info.id};
        }
        return {vp: videoProviders.externVideoProvider, onlineId: ''};
    };


    return(<>
        <Modal show={showModal} onShow={initModal} onHide={closeThisModal} size='lg'>
            <Modal.Header>
                <div className="d-flex justify-content-between w-100">
                    <button onClick={closeThisModal} className="btn btn-sm text-secondary"
                        title={t('modal_videos_uploadvideos_cancel_titleattr')}>
                        <span className="material-icons-round">close</span>
                    </button>
                    <Modal.Title>{t('modal_videos_uploadvideos_title')}</Modal.Title>
                    { (toImport.length > 0 && ! hasErrors) ?
                    <button onClick={startImportVideos} className="btn btn-sm text-vitag"
                        title={t('modal_videos_uploadvideos_upload_titleattr')}>
                        <span className="material-icons-round">upload</span>
                    </button>
                    : <span className="btn-placeholder"></span> }
                </div>
            </Modal.Header>
            <Modal.Body className='px-5 pb-4 pt-3'>
                <Row>
                    <Col sm={12}>
                        <p>
                            <Trans i18nKey="modal_videos_uploadvideos_text">
                                Acá puedes realizar la carga de múltiples videos listados en un archivo CSV con un formato especial.
                                    Puedes descargar una plantilla de ejemplo en <a className='vitag-text' download='plantilla-videos.csv' href='/assets/videos.csv'>este enlace</a>.
                            </Trans>
                        </p>
                        <div className="input-group mb-3">
                            <input onClick={startFileSelection} value={fileName}
                                type="text" readOnly className="form-control"
                                placeholder={t('modal_videos_uploadvideos_placeholder_selectfile')}/>
                            <div className="input-group-append">
                                <button onClick={startFileSelection} className="btn btn-outline-secondary">
                                    {t('modal_videos_uploadvideos_label_selectfile')}
                                </button>
                            </div>
                        </div>
                        { importSummary?.errors.length > 0 &&
                        <Alert variant='danger'>
                            <div>{t('modal_videos_uploadvideos_alert', {errors:importSummary?.errors.length})}</div>
                            <table className="table table-sm table-borderless m-0">
                                <tr>
                                    <th>{t('modal_videos_uploadvideos_tablecol_title1')}</th>
                                    <th>{t('modal_videos_uploadvideos_tablecol_title2')}</th>
                                </tr>
                                { importSummary?.errors.slice(0, 3).map((err,idx) =>
                                    <tr key={idx}>
                                        <td>{err.row}</td>
                                        <td>{err.message}</td>
                                    </tr>)}
                                { importSummary?.errors.length > 3 &&
                                    <tr className='text-danger'>
                                        <td colSpan={2}>{importSummary?.errors.length - 3} {t('modal_videos_uploadvideos_tableerrors')}</td>
                                    </tr> }
                            </table>
                        </Alert>}
                    </Col>
                    <Col sm={12}>
                        { toImport.length > 0 &&
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>{t('modal_videos_uploadvideos_th_title')}</th>
                                    <th>{t('modal_videos_uploadvideos_th_url')}</th>
                                    <th>{t('modal_videos_uploadvideos_th_sharetype')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                { toImport.slice(0, 3).map((res, idx) =>
                                <tr key={idx}>
                                    <td title={res?.videoTitle} className='text-truncate' style={{maxWidth:'120px'}}>
                                        {res?.videoTitle}
                                    </td>
                                    <td title={t('modal_videos_uploadvideos_td_videourl_titleattr')} className='text-truncate' style={{maxWidth:'180px'}}>
                                        <a onClick={handleURLClick} href={res?.videoUrl} target='_blank' className='vitag-text'>
                                            {res?.videoUrl}
                                        </a>
                                    </td>
                                    <td>{res?.shareType}</td>
                                </tr>)}
                                { toImport.length > 3 && <tr>
                                    <td className='text-muted' colSpan={3}>
                                        {t('modal_videos_uploadvideos_message_morerecords',{n:toImport.length - 3})}
                                    </td>
                                </tr>}
                            </tbody>
                        </table>}
                        { working && 
                        <div className="d-flex justify-content-end align-items-center gap-2 w-100">
                            <Spinner animation='border' size='sm'></Spinner> 
                            <p className='m-0'>{t('general_loading_text')}</p>
                        </div> }

                    </Col>
                </Row>
                <input id='import-video-input' onChange={handleFileSelection} type='file' className='d-none' accept='.csv'/>
            </Modal.Body>
        </Modal>
        <ConfirmModal
            modalTitle={t('modal_videos_uploadvideos_submodal_success_title')}
            modalText={t('modal_videos_uploadvideos_submodal_success_text')}
            showModal={showSuccessMessage}
            setShowModal={setShowSuccessMessage}
            showCancelButton={false}
            acceptCallback={successListener}/>
    </>)
}

export { ModalImportVideos };