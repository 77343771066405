import React, { useState, useEffect, useContext } from "react";
import { Parameters } from '../utils/ParametersEnum';
import { getParameterByName } from "../services/CoreService";
import { useTranslation } from 'react-i18next';
import { GeneralContext } from "../contexts/general-context";
import { config } from "../config";
import { Modal } from 'react-bootstrap';
import { createSetupIntent, listPaymentMethods, updatePreference, deletePaymentMethod } from '../services/StripeService';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { ModalNewPaymentMethod } from './ModalNewPaymentMethod';
import { useCustomToast } from '../utils/toasts/useCustomToast';
import { ModalPaymentMethods } from './ModalPaymentMethods';
import { createSubscription } from "../services/VitagSubscriptionsService";

function ModalCheckout({showModal, setShowModal, plan}) {

  const { t } = useTranslation();
  const[cycle, setCycle] = useState('');
  const[currentPrice, setCurrentPrice] = useState(0);
  const[subtotal, setSubtotal] = useState(0);
  const[total, setTotal] = useState(0);
  const[fees, setFees] = useState(0);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [defaultMethod, setDefaultMethod] = useState();
  const { profile } = useContext(GeneralContext);
  const stripePromise = loadStripe(config.STRIPE_API_KEY);
  const[clientSecret, setClientSecret] = useState('');
  const[showNewCard, setShowNewCard] = useState(false);
  const { addToast } = useCustomToast();
  const [showPaymentMethods, setShowPaymentMethods] = useState(false);
  const { currentContext } = useContext(GeneralContext);
  const [ oldPaymentMethods, setOldPaymentMethods] = useState([]);

  useEffect(() => {
    if (showModal) {
      initModal();
    }
  }, [showModal]);

  useEffect(() => {
    const parsedSubtotal = parseFloat(subtotal) || 0;
    const { fees, total } = calculateFeesAndTotal(parsedSubtotal);
    setFees(fees);
    setTotal(total);
  }, [subtotal]);

  useEffect(() => {
      if ((!showPaymentMethods || !showNewCard) || JSON.stringify(paymentMethods) !== JSON.stringify(oldPaymentMethods)) {
        if (! profile) {
          console.error('Profile is required');
          return;
        }
        listPaymentMethods(profile.userId)
          .then(resp => {
            const paymentMethods = resp.data;
            const defaultMethod = paymentMethods.find(method => method.defaultMethod === 1) || paymentMethods[0];
            setPaymentMethods(paymentMethods);
            setOldPaymentMethods(paymentMethods);
            setDefaultMethod(defaultMethod); 
          })
          .catch(err => {
              if(config.IS_BETA) console.error('Impossible retrieve payment methods', err)
          })
      }

  }, [showPaymentMethods, showNewCard]);

  const getCardLogo = (brand) => {
    const lowerBrand = brand.toLowerCase();
    const assetsPath = '/assets/imgs/cards/';
    let cardLogo;

    switch (lowerBrand) {
      case 'amex':
        cardLogo = `${assetsPath}american_express.png`;
        break;
      case 'diners':
        cardLogo = `${assetsPath}diners_club.png`;
        break;
      case 'discover':
        cardLogo = `${assetsPath}discover.png`;
        break;
      case 'jcb':
        cardLogo = `${assetsPath}jcb.png`;
        break;
      case 'mastercard':
        cardLogo = `${assetsPath}mastercard.png`;
        break;
      case 'unionpay':
        cardLogo = `${assetsPath}china_union.png`;
        break;
      case 'visa':
        cardLogo = `${assetsPath}visa.png`;
        break;
      default:
        cardLogo = `${assetsPath}credit_card.png`;
        break;
    }

    return cardLogo;
  }

  const initModal = () => {
    if (plan.cycle === 'month') {
      setCycle('modal_checkout_monthly_plan');
      getParameterByName(Parameters.PRO_ACCOUNT_MONTHLY_PRICE_IN_EUROS)
        .then(resp => { 
          setCurrentPrice(parseFloat(resp.data.value1).toFixed(2) || 0);
          setSubtotal(parseFloat(resp.data.value1).toFixed(2) || 0);
        });
    } else {
      setCycle('modal_checkout_annually_plan');
      getParameterByName(Parameters.PRO_ACCOUNT_ANNUAL_PRICE_IN_EUROS)
        .then(resp => { 
          setCurrentPrice((parseFloat(resp.data.value1)).toFixed(2) || 0);
          setSubtotal((parseFloat(resp.data.value1)).toFixed(2) || 0);
        });
    }

    listPaymentMethods(profile.userId)
    .then(resp => {
      const paymentMethods = resp.data;
      const defaultMethod = paymentMethods.find(method => method.defaultMethod === 1) || paymentMethods[0];

      setPaymentMethods(paymentMethods);
      setOldPaymentMethods(paymentMethods);
      setDefaultMethod(defaultMethod); 
    })
    .catch(err => {
        if(config.IS_BETA) console.error('Impossible retrieve payment methods', err)
    })

    const parsedSubtotal = parseFloat(subtotal) || 0;

    const { fees, total } = calculateFeesAndTotal(parsedSubtotal);
    setFees(fees);
    setTotal(total);
  }

  const closeThisModal = () => {
    setShowModal(false);
  };

  const buySubscription = () => {
    createSubscription(true,'Subscription purchase via Stripe', currentContext?.organizationId, plan.planId, true)
      .then(r => { 
        setShowModal(false);
          addToast({
              header: t('page_videos_toastsuccesssubscription_title'),
              body: t('page_videos_toastsuccesssubscription_body'),
              variant: 'success'
          });
          setTimeout(() => window.location.reload(), 1500);
      })
      .catch(err => {
        if(config.IS_BETA) console.error('Unable to purchase subscription', err)
      });
  };

  const calculateFeesAndTotal = (subtotal) => {
    const parsedSubtotal = parseFloat(subtotal) || 0;
    
    const stripePercentage = 0.029; 
    const stripeFixedFee = 0.30; 
    const fees = (parsedSubtotal * stripePercentage) + stripeFixedFee;
  
    const total = parsedSubtotal + fees;
    
    return {
      fees: parseFloat(fees.toFixed(2)),
      total: parseFloat(total.toFixed(2)),
    };
  };

  const openNewCardModal = () => {
    createSetupIntent(profile.userId)
    .then(resp => {
        if(config.IS_BETA) console.log('Setup successful', resp.data);
        setClientSecret(resp.data.clientSecret);
        setShowNewCard(true);
    })
    .catch(err => {
      if(config.IS_BETA) console.error('Impossible Setup', err);

      addToast({
          header: t('msg_new_paymentmethod_error_title'),
          body: t('msg_new_paymentmethod_errorsetup_body'),
          variant: 'error'
      });
    })
  }

  return (<>
    <Modal show={showModal} onShow={initModal} onHide={closeThisModal} size='lg' className='py-5' style={{ zIndex: 1070 }}>
        <Modal.Header>
            <button onClick={closeThisModal}
                title={t('general_cancel')}
                className="btn btn-sm text-muted">
                <span className='material-icons-round'>close</span>
            </button>
            <Modal.Title>{t('modal_checkout_title')}</Modal.Title>
            <div className="d-flex gap-2"></div>
        </Modal.Header>
        <Modal.Body className='px-5'>
        <div className="my-2 position-relative">
            <div className="row d-flex gap-0 justify-content-between">
                <div className="col-12 col-lg-6">
                    <div className="w-100 mb-5">
                      <div className="col w-100 gap-2 p-2" style={{boxShadow: '0 0 16px rgba(0, 0, 0, .08)', height: 'fit-content'}}>
                        <div className="col gap-2 mb-3">
                          <div className="scrollable-area">
                            <div className="fs-lg fw-bold">
                              <h4 className="fs-xl fw-bold">{t('modal_checkout_paymentmethods')}</h4>
                            </div>

                            <div className="d-flex align-items-center">
                              <span className="material-icons-round">credit_card</span>
                              {paymentMethods.length === 0 && <p className="text-muted m-0 mx-4">{t('modal_checkout_notregistered_methods')}</p>}
                              {paymentMethods.length > 0 &&<p className="text-muted  m-0 mx-3">{paymentMethods.length} {t('modal_checkout_registered_methods')}</p>}
                              <button title={t('modal_paymentmethods_button_new')} onClick={openNewCardModal}
                                      className="btn btn-sm text-vitag">
                                <span className="material-icons-round">add_circle</span>
                              </button>
                              <div className="dropdown">
                                  <button className='btn btn-sm dropdown-toggle' data-toggle="dropdown">
                                      <span className="material-icons-round">settings</span>
                                  </button>
                                  <div className="dropdown-menu">
                                      <div className="dropdown-item cursor-pointer" onClick={() => setShowPaymentMethods(true)}>
                                        {t('modal_checkout_button_changedefault')}
                                      </div>
                                  </div>
                              </div>
                            </div>

                            {defaultMethod && <div className="my-2 d-flex align-items-center">
                              <p className="subtitle-1 m-0 mr-2">
                                <img src={getCardLogo(defaultMethod.brand)} alt="{defaultMethod.brand}" class="card-brand"/>
                              </p>
                              <p className="subtitle-1 m-0 mx-3">**** **** **** {defaultMethod.number}</p>
                              <p className="subtitle-1 m-0 mx-3">{(defaultMethod.monthExpired + '/' + defaultMethod.yearExpired)}</p>
                            </div>}

                            <button title={t('modal_paymentmethods_button_new')} onClick={buySubscription}
                                    disabled={paymentMethods.length === 0 && !defaultMethod}
                                    className="btn btn-primary bg-vitag w-100 mt-3">{t('modal_checkout_button_buysubscription')}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="w-100">
                      <div className="col w-100 gap-2 p-2" style={{boxShadow: '0 0 16px rgba(0, 0, 0, .08)', height: 'fit-content'}}>
                        <div className="col gap-2">
                            <div className="scrollable-area">
                              <div className="fs-lg fw-bold">
                                  <h4 className="fs-xl fw-bold">{t('modal_checkout_summary')}</h4>
                              </div>
                              <div className="">
                                  <div className="d-flex align-items-center">
                                    <div className="flex-grow">
                                        <h4 className="fs-lg fw-bold h-my-0 align-items-center">{t('modal_checkout_planpro')}</h4>
                                    </div>
                                  </div>
                                  <div  className="d-flex justify-content-between align-items-center body-1">
                                    <div>{t(cycle)}</div>
                                    <div className="d-flex">
                                        <b>EUR€&nbsp;{currentPrice}</b>
                                    </div>
                                  </div>
                              </div>
                              <div className="border-bottom my-2"></div>
                            </div>
                            <div className="align-items-center justify-content-between d-flex">
                              <div className="d-flex align-items-center">
                                  <div className="fs-lg fw-bold">{t('modal_checkout_subtotal')}</div>
                              </div>
                              <div className="d-flex align-items-center">
                                  <div className="fs-lg">EUR€&nbsp;{subtotal}</div>
                              </div>
                            </div>
                            <div className="d-block my-2">
                              <div className="d-flex align-items-center justify-content-between">
                                  <div className="d-flex align-items-center body-1">
                                    {t('modal_checkout_fees')}
                                  </div>
                                  <div class="body-1" >EUR€&nbsp;{fees}</div>
                              </div>
                            </div>
                            <div className="border-bottom my-2"></div>
                            <div className="d-block">
                              <div className="d-flex align-items-center justify-content-between">
                                  <div className="fs-lg fw-bold">{t('modal_checkout_total')}</div>
                                  <div className="fs-lg fw-bold" >EUR€&nbsp;{total}</div>
                              </div>
                            </div>
                        </div>
                      </div>
                  </div>
                </div>
            </div>
          </div>
        </Modal.Body>
    </Modal>
    <Elements stripe={stripePromise}>
      <ModalNewPaymentMethod 
      clientSecret={clientSecret} 
      showModal={showNewCard}
      setShowModal={setShowNewCard}
      paymentMethods={paymentMethods}
      setPaymentMethods={setPaymentMethods}
      />
    </Elements>
    <ModalPaymentMethods
      showModal={showPaymentMethods}
      setShowModal={setShowPaymentMethods}
      zIndexVar={1090}
      isEdit={true}

    />
    </>);
}

export { ModalCheckout };
