import { config } from '../config';

const compressImage = (base64Image) => {
    let copyOfBase64 = base64Image;
    const imageSource = document.createElement('img');
    imageSource.src = copyOfBase64;
    return new Promise((response, reject) => {
        let compressRatio = 1;
        if (copyOfBase64.length > 100_000_000) {
            compressRatio = 0.12;
        } else if (copyOfBase64.length > 10_000_000) {
            compressRatio = 0.15;
        } else if (copyOfBase64.length > 1_000_000) {
            compressRatio = 0.40;
        } else if (copyOfBase64.length > 500_000) {
            compressRatio = 0.50;
        }
        if (compressRatio === 1) {
            response(base64Image);
            return;
        }
        imageSource.onload = () => {
            if(config.IS_BETA) console.log(`Image details, h:${imageSource.height} w:${imageSource.width} size:${copyOfBase64.length}`);
            if (compressRatio < 1) {
                const canvasDest = document.createElement('canvas');
                canvasDest.width = imageSource.width * compressRatio;
                canvasDest.height = imageSource.height * compressRatio;
                const canvasDestContext = canvasDest.getContext('2d');
                canvasDestContext.drawImage(
                    imageSource, 0, 0, imageSource.width, imageSource.height,
                    0, 0, canvasDest.width, canvasDest.height
                );
                copyOfBase64 = canvasDest.toDataURL();
            }
            response(copyOfBase64);
        };
        imageSource.onerror = err => {
            reject(err);
        };
    });
}

const defaultNoProfilePicture = 'https://vitag-storage.s3.eu-west-1.amazonaws.com/assets/custom/no-profile-picture.png';

export { compressImage, defaultNoProfilePicture };
