import React from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

function SimpleModal({
    modalTitle, modalText, showCancelButton=true, showNextButton=false, target="/",
    acceptIcon='check', okText='Aceptar', cancelText='Cancelar', showModal, setShowModal, size='md',
    acceptCallback, cancelCallback, children
}) {

    const completeAcceptCallback = () => {
        if(acceptCallback){ acceptCallback(); }
        closeThisModal();
    }

    const completeCancelCallback = () => {
        if(cancelCallback){ cancelCallback(); }
        closeThisModal();
    }

    const closeThisModal = () => setShowModal(false);

    return (<Modal show={showModal} onHide={closeThisModal} size={size}>
        <Modal.Header className={! showCancelButton && "pl-5"}>
            {showCancelButton && <button onClick={completeCancelCallback} className="btn btn-sm text-muted" title={cancelText}>
                <span className='material-icons-round'>close</span>
            </button>}
            <Modal.Title>{modalTitle}</Modal.Title>
            {! showNextButton && <button onClick={completeAcceptCallback} className="btn btn-sm text-vitag" title={okText}>
                <span className='material-icons-round'>{acceptIcon}</span>
            </button>}
            {showNextButton && <Link to={target} className="btn btn-sm text-vitag" title={okText}>
                <span className="material-symbols-outlined">arrow_outward</span>
            </Link>}
        </Modal.Header>
        <Modal.Body className="px-5">
            {modalText && <p className="mt-3">{modalText}</p>}
            {children}
        </Modal.Body>
    </Modal>);
}

export { SimpleModal };
