import axios from 'axios';

function createTag(newVtgTag, addToFavs, videoId) {
    return axios.post('/vtg-tags', newVtgTag,
        { params: { tofav: addToFavs, video: videoId } }
    );
}

function updateTag(tagId, newVtgTag) {
    return axios.put(`/vtg-tags/${tagId}`, newVtgTag);
}

function cloneTag(tagId, cloneRequest) {
    return axios.post(`/vtg-tags/clone/${tagId}`, cloneRequest);
}

function shareTag(tagId, shareRequest) {
    return axios.post(`/vtg-tags/share/${tagId}`, shareRequest);
}

function ignoreTag(tagId) {
    return axios.post(`/vtg-tags/ignore/${tagId}`, {});
}

function restoreTrainingTags() {
    return axios.put('/vtg-tags/restore-all/', {});
}

function listAllTags(
    searching = '', start = 0, max = 50,
    includeSubTags = false, includeFavIndicator = false,
    sortCriteria = undefined
) {
    start = start * max;
    return axios.get('/vtg-tags', { params: {
        searching, start, max,
        asc:sortCriteria?.asc, columnOrder:sortCriteria?.name,
        subtags:includeSubTags, fav:includeFavIndicator
    }});
}

function listForExport(searching = '') {
    return axios.get('/vtg-tags/export', { params: { searching } });
}

function importListOfTags(parentTags) {
    return axios.post('/vtg-tags/import', parentTags);
}

function softDeleteTag(tagId) {
    return axios.delete(`/vtg-tags/${tagId}`);
}

function tagUsage(tagId) {
    return axios.get(`/vtg-tags/usage/${tagId}`);
}

function markFavoriteTag(tagId) {
    return axios.put(`/vtg-tags/fav/${tagId}`, {});
}

function removeFavoriteTag(tagId) {
    return axios.delete(`/vtg-tags/fav/${tagId}`);
}

export { 
    listAllTags, listForExport, importListOfTags,
    createTag, updateTag, cloneTag, shareTag, tagUsage,
    ignoreTag, restoreTrainingTags, softDeleteTag,
    markFavoriteTag, removeFavoriteTag
};
