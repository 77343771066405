import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { config } from '../config';
import { VitagFooter } from '../general/VitagFooter';
import { VitagTitleBar } from '../general/VitagTitleBar';
import { changePasswordFromRecovery, verificateRecoveryPassword } from '../services/CoreService';
import { useCustomToast } from '../utils/toasts/useCustomToast';

function VerificatePasswordRecovery() {
    
    let { token } = useParams();
    const { t } = useTranslation();
    const { addToast } = useCustomToast();
    const[verifying, setVerifying] = useState(true);
    const[verified, setVerified] = useState(false);
    const[errorVerifying, setErrorVerifying] = useState(false);
    const[userId, setUserId] = useState(-1);
    const[password, setPassword] = useState('');
    const[passwordConfirmation, setPasswordConfirmation] = useState('');
    const[showPass, setShowPass] = useState(false);
    const[showSuccessPasswordRecovery, setShowSuccessPasswordRecovery] = useState(false);

    useEffect(() => {
        setTimeout(_ => {
            verificateRecoveryPassword(token)
            .then(resp => {
                if(config.IS_BETA) console.log("Verified.");
                setUserId(resp.data.result);
                setVerifying(false);
                setVerified(true);
                setErrorVerifying(false);
            })
            .catch(err => {
                if(config.IS_BETA) console.error('Error verifying account...', err);
                setVerifying(false);
                setVerified(false);
                setErrorVerifying(true);
            })
        }, 2400);
        document.title = config.TITLE_BASE + t('title_password_recovery');
    }, []);

    const handlePasswordInput = (event) => {
        const value = event.target.value;
        setPassword(value);
    }

    const handlePasswordConfirmationInput = (event) => {
        const value = event.target.value;
        setPasswordConfirmation(value);
    }

    const changeShowPass = () => {
        const value = ! showPass;
        setShowPass(value);
    }

    const doChangePassword = () => {
        changePasswordFromRecovery(userId, token, password)
            .then(_ => {
                if(config.IS_BETA) console.log('Password changed successful...');
                setShowSuccessPasswordRecovery(true);
            })
            .catch(err => {
                if(config.IS_BETA) console.error('Impossible change password', err);
                setShowSuccessPasswordRecovery(false);
                addToast({
                    header: t('page_passwordreset_toast_error_title'),
                    body: t('page_passwordreset_toast_error_text'),
                    variant: 'error'
                });
            });
    }

    return (
        <div className='container-fluid full-container bg-vitag px-4 px-md-5'>
            <div className="row justify-content-center">
                <div className="w-100 d-block d-sm-none" style={{paddingTop:'1em'}}></div>
                <div className="w-100 d-none d-sm-block" style={{paddingTop:'6em'}}></div>
                <div className="col-12 col-sm-10 col-md-9 col-lg-6 col-xl-5 px-md-4 px-4 py-3 mt-3 bg-white border extra-rounded" style={{maxWidth:'640px'}}>
                    <VitagTitleBar titleAtBar={t('page_passwordreset_title')}/>
                    { ! showSuccessPasswordRecovery && verifying &&
                    <Alert variant='secondary'>
                        <Alert.Heading>
                            <div className="spinner-grow spinner-grow-sm mb-2 mr-2" role="status">
                                <span className="sr-only">{t('page_passwordreset_label_loading')}</span>
                            </div>
                            {t('page_passwordreset_alert_validating_title')}
                        </Alert.Heading>
                        <p className="mb-0">{t('page_passwordreset_alert_validating_text')}</p>
                    </Alert> }
                    { ! showSuccessPasswordRecovery && verified && <>
                        <Alert variant='secondary' dismissible={false}>
                            <Alert.Heading>{t('page_passwordreset_alert_valid_title')}</Alert.Heading>
                            <p className='mb-0'>
                                {t('page_passwordreset_alert_valid_text')}
                            </p>
                        </Alert>
                        <div className="form-group mt-2">
                            <label htmlFor="userPass">
                                {t('page_passwordreset_label_newpassword')}
                            </label>
                            <div className="input-group">
                                <input className="form-control" 
                                    value={password} onChange={handlePasswordInput} type={showPass ? 'text' : 'password'}
                                    placeholder={t('page_passwordreset_placeholder_newpassword')} required/>
                                <div className="input-group-append"
                                    onClick={changeShowPass}
                                    title={t('page_passwordreset_btn_showpassword_titleattr')}>
                                    <span className="input-group-text material-icons-round">visibility</span>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="userPassConfirmation">
                                {t('page_passwordreset_label_passwordconfirm')}
                            </label>
                            <div className="input-group">
                                <input className="form-control" placeholder={t('page_passwordreset_placeholder_passwordconfirm')} required
                                    value={passwordConfirmation} onChange={handlePasswordConfirmationInput} type={showPass ? 'text' : 'password'}/>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center py-2">
                            <button
                                onClick={doChangePassword}
                                disabled={! password.trim() || ! passwordConfirmation.trim() || password !== passwordConfirmation}
                                className='btn btn-primary px-5 bg-vitag'>
                                {t('page_passwordreset_btn_changepassword')}
                            </button>
                        </div>
                    </> }

                    { ! showSuccessPasswordRecovery && errorVerifying &&
                    <Alert variant='danger'>
                        <Alert.Heading>{t('page_passwordreset_alert_invalid_title')}</Alert.Heading>
                        <p className='mb-0'>{t('page_passwordreset_alert_invalid_text')}</p>
                    </Alert> }

                    { showSuccessPasswordRecovery &&
                    <Alert variant='success'>
                        <Alert.Heading>{t('page_passwordreset_alert_success_title')}</Alert.Heading>
                        <p>{t('page_passwordreset_alert_success_text')}</p>
                        <hr/>
                        <Link to="/login" className="btn btn-outline-success">
                            {t('page_passwordreset_alert_success_btn')}
                        </Link>
                    </Alert> }

                </div>
            </div>

            <VitagFooter/>

        </div>)
}

export { VerificatePasswordRecovery };