import axios from "axios";
import { config } from "../config"

function getYoutubeVideoInfo(videoId) {
    const API_KEY = config.GOOGLE_API_KEY;
    return fetch(`https://www.googleapis.com/youtube/v3/videos?id=${videoId}&key=${API_KEY}&part=snippet,contentDetails`)
        .then(resp => {
            if(resp.ok) {
                return resp.json();
            } else {
                return Promise.reject('Error getting video info for YouTube API');
            }
        });
}

function getVimeoVideoInfo(videoId) {
    const ACCESS_TOKEN = '0ab9fe4f0c94630c9062814213096cd6';
    return fetch(
            `https://api.vimeo.com/videos/${videoId}`,
            { method:'GET', headers:{Authorization:'Bearer ' + ACCESS_TOKEN} }
        )
        .then(resp => {
            if(resp.ok) {
                return resp.json();
            } else {
                return Promise.reject('Error getting video info for Vimeo API');
            }
        });
}

function getTwitchVideoInfo(videoId) {
    return axios.get('external-videos/twitch', {params:{vod:videoId}});    
}


export { getYoutubeVideoInfo, getVimeoVideoInfo, getTwitchVideoInfo }