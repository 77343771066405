import React, { useEffect, useState, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { config } from '../config';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { GeneralContext } from "../contexts/general-context";
import { createSetupIntent, createPaymentMethod } from '../services/StripeService';
import { useCustomToast } from '../utils/toasts/useCustomToast';

function ModalNewPaymentMethod({ showModal, setShowModal, clientSecret, paymentMethods, setPaymentMethods }) {

    const { t } = useTranslation();
    const stripe = useStripe();
    const elements = useElements();
    const { addToast } = useCustomToast();
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [name, setName] = useState("");
    const [confirmingCard, setConfirmingCard] = useState(false);
    const { profile } = useContext(GeneralContext);

    const closeThisModal = () => {
        setShowModal(false);
    };

    const initModal = () => {
      setSuccess(false);
      setConfirmingCard(false);
      setName("");
      setError(null);
    }

    const handleSubmit = async (event) => {
      event.preventDefault();
  
      if (!stripe || !elements || !clientSecret) {
        return;
      }

      setConfirmingCard(true);
  
      const cardElement = elements.getElement(CardElement);
      
      try {
        const { setupIntent, stripeError } = await stripe.confirmCardSetup(clientSecret, {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: name.toUpperCase(),
              email: profile.email,
            },
          },
        });
  
        if (stripeError) {
          setError(stripeError.message);
          setConfirmingCard(false);
        } else if (setupIntent.status === 'succeeded') {
          
          createPaymentMethod(profile.userId, setupIntent.payment_method)
          .then(resp => {
              if(config.IS_BETA) console.log('Payment Method successfully created ', resp.data);

              setPaymentMethods(prevPaymentMethods => [...prevPaymentMethods, resp.data]);
              setSuccess(true);
              addToast({
                header: t('msg_new_paymentmethod_sucesssave_title'),
                body: t('msg_new_paymentmethod_sucesssave_body'),
                variant: 'success'
              });
              setError(null);
              closeThisModal();

          })
          .catch(err => {
              if(config.IS_BETA) console.error('Error creating Payment Method', err);
              addToast({
                header: t('msg_new_paymentmethod_error_title'),
                body: t('msg_new_paymentmethod_error_body'),
                variant: 'error'
              });
          });
        }
      } catch (error) {
        if(config.IS_BETA) console.error("Error during card confirmation:", error);
        setError("An unexpected error occurred.");
        setConfirmingCard(false);
      }
    };


    return (<>
    <Modal show={showModal} onShow={initModal} onHide={closeThisModal} size='md' className='py-5' style={{ zIndex: 1090 }}>
        <Modal.Header>
            <button onClick={closeThisModal}
                title={t('general_cancel')}
                className="btn btn-sm text-muted">
                <span className='material-icons-round'>close</span>
            </button>
            <Modal.Title>{t('modal_new_paymentmethod_title')}</Modal.Title>
            <div className="d-flex gap-2">
                <button onClick={handleSubmit} disabled={!stripe || confirmingCard || name.length <= 3}
                    title={t('modal_new_paymentmethod_button_save')}
                    className="btn btn-sm text-vitag">
                    <span className='material-icons-round'>save</span>
                </button>
            </div>
        </Modal.Header>
        <Modal.Body className='px-5'>
          <form className='row pb-4'>
              <label className='col-12'>
                {t('modal_new_paymentmethod_label_card')}
              </label>
              <CardElement className='col-12' disabled={confirmingCard}
                options={{
                  hidePostalCode: true,
                  style: {
                    base: {
                      fontSize: "16px",
                      color: "#424770",
                      "::placeholder": {
                        color: "#aab7c4",
                      },
                    },
                    invalid: {
                      color: "#9e2146",
                    },
                  },
                }}
              />
              <label className='col-12 pt-3'>
                {t('modal_new_paymentmethod_label_name')}
              </label>
              <input className='form-control mx-3'
                type="text"
                disabled={confirmingCard}
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
          </form>
        </Modal.Body>
    </Modal>
    </>);
}

export { ModalNewPaymentMethod };