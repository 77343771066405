import { useEffect } from "react";
import { loadUserPreferences } from "./user-preferences";
import { config } from '../../config';

function InjectUserPreferences () {

    useEffect(() => {
        if(config.IS_BETA) console.log('This effect (loading preferences) is called once');
        loadUserPreferences();
    }, []);

    return null;
}

export { InjectUserPreferences };
