import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { components } from "react-select";

const { Option } = components;
const { SingleValue } = components;

const IconOption = props => (
    <Option {...props}>
        <div className="d-flex gap-2">
            <span className="material-icons-round text-dark">{props.data.icon}</span>
            <span className="my-auto">{props.data.label}</span>
        </div>
    </Option>
);

const IconSingleValue = props => (
    <SingleValue {...props}>
        <div className="d-flex gap-2">
            <span className="material-icons-round text-dark">{props.data.icon}</span>
            <span className="my-auto">{props.data.label}</span>
        </div>
    </SingleValue>
);

const FAIconOption = props => (
    <Option {...props}>
        <div className="d-flex gap-2">
            <FontAwesomeIcon icon={props.data.icon} className="my-auto"/>
            <span className="my-auto">{props.data.label}</span>
        </div>
    </Option>
);

const FAIconSingleValue = props => (
    <SingleValue {...props}>
        <div className="d-flex gap-2">
            <FontAwesomeIcon icon={props.data.icon} className="my-auto"/>
            <span className="my-auto">{props.data.label}</span>
        </div>
    </SingleValue>
);

export { IconOption, IconSingleValue, FAIconOption, FAIconSingleValue }