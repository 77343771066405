import axios from 'axios';

function listShareDetails(entityId, entityType) {
    return axios.get(`/vtg-share-control/by-${entityType}/${entityId}`);
}

function stopShare(sharedElementId) {
    return axios.delete(`/vtg-share-control/stop/${sharedElementId}`);
}

function tryStopShare(videoId) {
    return axios.delete(`/vtg-share-control/try-stop/${videoId}`);
}

function stopShareReason(videoId) {
    return axios.get(`/vtg-share-control/lock-by/${videoId}`);
}

export { 
    listShareDetails, stopShare, tryStopShare, stopShareReason
};
