import React, { useEffect, useRef, useState, useCallback } from 'react';
import { t } from 'i18next';
import { extractScreenshot } from '../../services/VitagVideosService';
import { useParams, useHistory, useLocation } from 'react-router';
import { config } from '../../config';
import { uploadImageToS3 } from '../../services/CoreService';

function ExtractScreenshot({ transparentButtonClass, timeAsMillis, setImageLoadError, setUploadingImage, uploadingImage, setMarkerThumbnail, markerThumbnail }) {

  let { hashId } = useParams();

  const [defaultThumbnail, setDefaultThumbnail] = useState(markerThumbnail);

  useEffect(() => {
    if (!defaultThumbnail) {
      setDefaultThumbnail(markerThumbnail);
    }
  }, [markerThumbnail]);

  const safeExtractScreenshot = () => {
    setUploadingImage(true);
    extractScreenshot(hashId, timeAsMillis)
      .then(resp => {
        if (resp && resp.data && resp.data.imageUrl) {
          handleImageSelection(resp.data.imageUrl)
        }
      })
      .catch(_ => {
          setUploadingImage(false);
          if(config.IS_BETA) console.error('Impossible getting video screenshot');
          setMarkerThumbnail(defaultThumbnail);
      });
  }

  const handleImageSelection = (image) => {
    const s3Url = image;
    const uniqueUrl = `${s3Url}?t=${new Date().getTime()}`;
    setMarkerThumbnail(uniqueUrl);
    setUploadingImage(false);
}

useEffect(() => {
  if (markerThumbnail){
    const loadImage = (url, attempts) => {
      
      if(attempts == undefined || attempts <= 0) {
          if(config.IS_BETA) console.error('Not available attempts');
          return;
      }
      const img = new Image();
      img.src = url;
      img.onload = () => {
        if(config.IS_BETA) console.log('Image loaded successfully');
        setImageLoadError(false);
        setUploadingImage(false);
      }
      img.onerror = () => {
          setUploadingImage(true);
          if(config.IS_BETA) console.error('Failed to load image');
          setImageLoadError(true);
          setTimeout(() => {
            loadImage(url, attempts - 1);
          }, 3000);
      }
      img.src = url;
    };
    loadImage(markerThumbnail, 3);
  }
}, [markerThumbnail]);


  return (
    <button onClick={safeExtractScreenshot}
            title={t('modal_managemarker_title_extractscreenshot')}
            className={'btn btn-sm border-0 line-0 align-self-center ' + transparentButtonClass}>
      { uploadingImage && 
        <div className="d-flex" style={{bottom:'8px',left:'8px'}}>
          <div className="spinner-border spinner-border-sm my-auto"></div>
        </div> 
      }
      { !uploadingImage && <span className="material-icons-round">photo_camera</span>}
      
    </button> );
}

export { ExtractScreenshot };
