import Moment from 'moment';
import React, { useContext, useState } from "react";
import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { GeneralContext } from "../contexts/general-context";
import { ConfirmModal } from "../general/ConfirmModal";
import usePermission from '../hooks/UsePermission';
import { leaveAccess } from "../services/CoreService";
import { PERSONAL_CONTEXT } from "../utils/ContextUtils";
import { config } from '../config';

function ProfileTabContexts() {

    const { contexts, loadContextsFromBackend } = useContext(GeneralContext);
    const [showConfirmLeave, setShowConfirmLeave] = useState(false);
    const [selectedContext, setSelectedContext] = useState();
    const { t } = useTranslation();
    const { hasPermission: canViewSubscriptionDetails } = usePermission(260113);

    const confirmLeaveContext = (ctx) => {
        setSelectedContext(ctx);
        setShowConfirmLeave(true);
    }

    const doLeaveContext = () => {
        leaveAccess(selectedContext.organizationId)
            .then(_ => loadContextsFromBackend())
            .catch(_ => {
                if(config.IS_BETA) console.error('Impossible leave context')
        });
    }

    const formatDate = (date) => {
        return Moment(date).format('YYYY-MM-DD');
    }

    return <>
        <div className="d-flex flex-column px-2 py-1 px-md-5 gap-3">
            <div className="w-100">
                <span className="text-muted">
                    { t('page_profile_section_contexts_description') }
                </span>
            </div>
            { contexts.map(ctx =>
            <div key={ctx.organizationId} className='d-flex w-100 border rounded p-2 bg-white'>
                <img src={ctx.organizationPicture} className="context-image mr-2 my-auto"/>
                <div className="d-flex flex-column">
                    <strong>{ctx.name}</strong>
                    { ctx.currentSub != undefined && canViewSubscriptionDetails &&
                    <div className="d-flex align-items-center gap-2">
                        <span className={"status-presentation " + ctx.currentSub.subscriptionStatus.description}>
                                {t(ctx.currentSub.subscriptionStatus.description + '_extended')}
                        </span>
                        <span className="text-muted subscription-date">
                            {t('page_profile_section_contexts_expiration_date', 
                                {startDate:formatDate(ctx.currentSub.startDate),
                                 thruDate: formatDate(ctx.currentSub.thruDate)
                                })}
                        </span>
                    </div>}
                </div>
                { ctx.organizationId !== PERSONAL_CONTEXT &&
                <span onClick={() => confirmLeaveContext(ctx)}
                    className="material-icons-round cursor-pointer ml-auto my-auto text-danger"
                    title={t('page_profile_section_contexts_button_leavecontext_title')}>cancel</span> }
            </div>)}
            <Alert variant="warning">
                { t('page_profile_section_contexts_warning') }
            </Alert>
        </div>
        <ConfirmModal
            modalTitle={t('modal_leavecontext_title')}
            modalText={t('modal_leavecontext_text', {context:selectedContext?.name})}
            acceptCallback={doLeaveContext}
            cancelCallback={() => setSelectedContext(null)}
            showModal={showConfirmLeave} setShowModal={setShowConfirmLeave}>
        </ConfirmModal>
    </>
}

export { ProfileTabContexts };
