import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { GeneralContext } from "../contexts/general-context";

function SimpleContextSelector({ changeContextListener }) {

    const [selectedCtx, setSelectedCtx] = useState();
    const { contexts } = useContext(GeneralContext);

    useEffect(() => {
        const ctx = contexts[0];
        if (ctx) {
            handleContextChange(ctx);
        }
    }, [contexts]);

    const handleContextChange = (ctx) => {
        setSelectedCtx(ctx);
        changeContextListener && changeContextListener(ctx);
    }

    return (
        <div className='w-100 d-flex'>
            { selectedCtx?.organizationPicture &&
            <div className="my-auto rounded mx-3">
                <img src={selectedCtx?.organizationPicture} style={{maxHeight:'32px',maxWidth:'32px',objectFit:'scale-down'}} alt="#"></img>
            </div> }
            <div className="dropdown my-auto">
                <button className="btn btn-outline-secondary border dropdown-toggle" type="button" id="simpleContextSelector" data-toggle="dropdown" aria-expanded="false">
                    <div className="d-inline-flex flex-column">
                        <span>{ selectedCtx?.name }</span>
                    </div>
                </button>
                <ul className="dropdown-menu drowdown-context" aria-labelledby="simpleContextSelector">
                    { contexts.map(ct =>
                    <li key={ct.organizationId} onClick={() => handleContextChange(ct)} className="dropdown-item">
                        {ct.name}
                    </li>
                    )}
                </ul>
            </div>
        </div>
    )
}

export { SimpleContextSelector };
