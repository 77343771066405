import React from 'react';
import { useTranslation } from "react-i18next";


function EmptyPanel({extraClass = "py-5", message = null}) {
    const { t } = useTranslation();
    return (
    <div className={"p-4 extra-rounded bg-light w-100 d-flex " + extraClass}>
        <h6 className="text-muted w-100 my-auto text-center">{message ? message : t('emptypanel_message_default')}</h6>
    </div>
    )
}

export { EmptyPanel };
