import React, { useState } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import 'videojs-vr/dist/videojs-vr.css';
import "videojs-vr";
import { Typologies } from '../utils/TypologiesEnum';
import { config } from '../config';

export const VideoJS = ( props ) => {

  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  const { options, onReady, videoId, projectionType, allProjections, showModal, showSummary, showFavs, lockMarkerVitagSummary, disableKeys, showShareModal, showModalMoreInfo} = props;
  const [isChrome, setIsChrome] = useState(!!window.chrome);
  const [currentProjection, setCurrentProjection] = useState(null);


  React.useEffect(() => {
    const fetchProjection = async () => {
      const match = allProjections.find(item => item.typologyId === projectionType);
      
      let projection;
      if (match) {
        switch(match.internalId) {
          case Typologies.PT_STANDARD:
            projection = 'NONE';
              break;
          case Typologies.PT_360:
              projection = '360';
              break;
          case Typologies.PT_360_CUBIC:
              projection = '360_CUBE';
              break;
          case Typologies.PT_360_TB:
              projection = '360_TB';
              break;
          case Typologies.PT_180:
              projection = '180';
              break;
          case Typologies.PT_180_MONO:
              projection = '180_MONO';
              break;
          case Typologies.PT_EAC:
              projection = 'EAC';
              break;
          case Typologies.PT_EAC_LR:
              projection = 'EAC_LR';
              break;
          default:
            projection = 'NONE';
          break;
        }  
      }
      setCurrentProjection(projection);
    }

    fetchProjection();
  }, []);

  React.useEffect(() => {
    // make sure Video.js player is only initialized once

    if (currentProjection) {
      if (!playerRef.current) {
        const videoElement = videoRef.current;
        if (!videoElement) {
          if(config.IS_BETA) console.error('Invalid video tag element');
          return;
        }
        if(config.IS_BETA) console.log('Options to use in video-js:', options);
        const player = playerRef.current = videojs(
          videoElement, {...options, controlBar:  { fullscreenToggle:false, 
                                                    pictureInPictureToggle:false },
                                     userActions: { doubleClick: false }},
          () => {
            if(config.IS_BETA) console.log("player is ready");
            if (onReady) {
              onReady(player);
            }
          }
        );

        if (!player.mediainfo) {
          player.mediainfo = {};
        }
        
        if (!player.mediainfo.projection) {
          player.mediainfo.projection = currentProjection;
        }

        player.vr({projection: currentProjection});
      } else {
        // you can update player here [update player through props]
        const player = playerRef.current;
        player.autoplay(options.autoplay);
        player.src(options.sources);

        if (!player.mediainfo) {
          player.mediainfo = {};
        }
        
        if (!player.mediainfo.projection) {
          player.mediainfo.projection = currentProjection;
        }

        player.vr(
          {projection: currentProjection}
        );

      }
    }
  }, [options]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, []);

  React.useEffect(() => {
    const player = playerRef.current;

    if (!player) {
      return;
    }

    const handleKeyDown = (e) => {
      if (!disableKeys && !showShareModal && !showModalMoreInfo && !showModal  && !showFavs && (lockMarkerVitagSummary === Typologies.LMSO_INACTIVE || !showSummary)) {
        switch (e.key) {
          case ' ':
            if (player.paused()) {
              player.play();
            } else {
              player.pause();
            }
            break;
          case 'm':
            player.muted(!player.muted());
            break;
          case ',':
            player.currentTime(player.currentTime() - (1 / 60));
            break;
          case '.':
            player.currentTime(player.currentTime() + (1 / 60));
            break;
          case 'ArrowLeft':
            if (e.shiftKey) {
              player.currentTime(player.currentTime() - (1 / 60));
            } else {
              player.currentTime(player.currentTime() - 5);  
            }
            break;
          case 'ArrowRight':
            if (e.shiftKey) {
              player.currentTime(player.currentTime() + (1 / 60));
            } else {
              player.currentTime(player.currentTime() + 5);  
            }
            break;
          case 'j':
            player.currentTime(player.currentTime() - 10);
            break;
          case 'l':
            player.currentTime(player.currentTime() + 10);
            break;
          case 'ArrowUp':
            player.volume(Math.min(player.volume() + 0.05, 1));
            break;
          case 'ArrowDown':
            player.volume(Math.max(player.volume() - 0.05, 0));
            break;
          case '1':
          case '2':
          case '3':
          case '4':
          case '5':
          case '6':
          case '7':
          case '8':
          case '9':
            player.currentTime(player.duration() * (parseInt(e.key) * 10 / 100));
            break;
          case '0':
            player.currentTime(0);
            break;
          case '>':
            player.playbackRate(player.playbackRate() + 0.1);
            break;
          case '<':
            player.playbackRate(player.playbackRate() - 0.1);
            break;
          case 'Home':
            player.currentTime(0);
            break;
          case 'End':
            player.currentTime(player.duration());
            break;
          default:
            break;
        }
      }
    };

    // Add event listener for keydown
    window.addEventListener('keydown', handleKeyDown);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [playerRef.current, showModal, showSummary, showFavs, lockMarkerVitagSummary, disableKeys, showShareModal, showModalMoreInfo]);

  React.useEffect(() => {
    if(config.IS_BETA) console.log("playerRef changed:", playerRef.current);
  }, [playerRef.current]);

  return (
    <div data-vjs-player style={{width:'100%',height:'100%',position:'absolute'}}>
      <video id={videoId} ref={videoRef}
        onContextMenu={e => e.preventDefault()}
        className={"video-js vjs-big-play-centered w-100 " + (isChrome ? 'for-chrome' : '')}
        data-setup='{ "inactivityTimeout":0 }' crossOrigin="anonymous">
      </video>
    </div>
  );
}

export default VideoJS;