import React, { useState } from "react";
import { Modal, Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IconPill } from "../general/IconPill";
import { analyzeSubtitles, importMarkersDirect, importMarkersForVideo } from "../services/VitagMarkersService";
import { convertSecondsToTime } from "../utils/NabUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { useCustomToast } from "../utils/toasts/useCustomToast";
import { config } from '../config';
function ModalMarkersFromSubtitles({showModal, setShowModal, vtgVideo, availableTags = [], forceMarkersRefresh}) {

    const { t } = useTranslation();
    const { addToast } = useCustomToast();
    const [markersToImport, setMarkersToImport] = useState([]);
    const [analyzing, setAnalyzing] = useState(false);
    const [errorOnAnalyzing, setErrorOnAnalyzing] = useState(false);
    const [atLeastOneMarkerSelected, setAtLeastOneMarkerSelected] = useState(false);
    const [srtContent, setSrtContent] = useState();
    const [fileName, setFileName] = useState();
    const [tags, setTags] = useState(availableTags)
    const [steps] = useState([
        {
            key:'step-1',title:'modal_importfromcc_step1_subtitle',
            continue:'modal_importfromcc_step1_button', showPrev:false, showContinue:true
        },
        {
            key:'step-2',title:'modal_importfromcc_step2_subtitle',
            continue:'modal_importfromcc_step2_button', previous:'modal_importfromcc_step2_prev',
            showPrev:true, showContinue:true
        },
        {
            key:'step-3',title:'modal_importfromcc_step3_subtitle',
            previous:'modal_importfromcc_step3_prev', showPrev:true, showContinue:false
        },
        {
            key:'step-4',title:'modal_importfromcc_step4_subtitle',
            continue:'modal_importfromcc_step4_button', showPrev:false, showContinue:false
        }
    ]);
    const [currentStep, setCurrentStep] = useState(steps[0]);

    const closeThisModal = () => {
        setShowModal(false);
    }

    const modalInit = () => {
        setMarkersToImport([]);
        setCurrentStep(steps[0]);
        setAnalyzing(false);
        setErrorOnAnalyzing(false);
        setFileName('');
        setSrtContent('');
        const copyOfAvailableTags = new Map();
        availableTags.forEach(tag => {
            copyOfAvailableTags.set(tag.tagId, tag);
            (tag.subTags || []).forEach(
                st => copyOfAvailableTags.set(st.tagId, st)
            );
        });
        setTags(Array.from(copyOfAvailableTags.values()));
    }

    const startAnalyzeFileContent = () => {
        setAnalyzing(true);
        analyzeSubtitles('srt', srtContent, tags)
            .then(resp => {
                (resp.data || []).forEach(
                    (marker, idx) => {
                        marker.tempId = idx;
                        marker.startTime = convertSecondsToTime(marker.startSecond);
                        marker.selected = true;
                    });
                setMarkersToImport(resp.data || []);
                setAtLeastOneMarkerSelected(true);
                setErrorOnAnalyzing(false);
            })
            .catch(err => {
                if(config.IS_BETA) console.error('Impossible analyze subtitles', err);
                setErrorOnAnalyzing(true);
            })
            .finally(() => setAnalyzing(false));
    }

    const startMarkerImports = () => {
        const selectedMarkers = markersToImport.filter(m => m.selected);
        selectedMarkers.forEach(marker => {
            marker.videoId = vtgVideo.videoId;
            marker.thumbnail = vtgVideo.miniature;
        });
        importMarkersDirect(vtgVideo.hashId, selectedMarkers)
            .then(_ => {
                closeThisModal();
                forceMarkersRefresh && forceMarkersRefresh();
                addToast({
                    header: t('modal_importfromcc_toast_success_title'),
                    body: t('modal_importfromcc_toast_success_text'),
                    variant: 'success'
                });
            })
            .catch(err => {
                addToast({
                    header: t('modal_importfromcc_toast_error_title'),
                    body: t('modal_importfromcc_toast_error_text'),
                    variant: 'error'
                });
                if(config.IS_BETA) console.log('Error adding markers', err);
            });
    }

    const nextStep = () => {
        switch(currentStep.key) {
            case "step-1": //starts step-2
                setCurrentStep(steps[1]);
                break;
            case "step-2": //starts step-3
                setCurrentStep(steps[2]);
                startAnalyzeFileContent();
                break;
            case "step-3": //starts step-4
                setCurrentStep(steps[3]);
                break;
        }
    }

    const prevStep = () => {
        switch(currentStep.key) {
            case "step-2":
                setCurrentStep(steps[0]);
                break;
            case "step-3":
                setCurrentStep(steps[1]);
                break;
            case "step-4":
                setCurrentStep(steps[2]);
                break;
        }
    }

    const removeTag = (id) => {
        const newTags = tags.filter(t => t.tagId != id);
        setTags(newTags);
    }

    const changeSelectionForAllMarkers = (select) => {
        const newMarkers = [...markersToImport];
        newMarkers.forEach(m => m.selected = select);
        if(config.IS_BETA) console.log('Change all to: ' + select);
        setMarkersToImport(newMarkers);
        changeAtLeastOneSelected(newMarkers);
    }

    const triggerChecked = (id) => {
        const newMarkers = [...markersToImport];
        newMarkers.forEach(m => {
            if (m.tempId == id) {
                m.selected = ! m.selected;
            }
        });
        setMarkersToImport(newMarkers);
        changeAtLeastOneSelected(newMarkers);
    }

    const changeAtLeastOneSelected = (markers) => {
        setAtLeastOneMarkerSelected(markers.some(m => m.selected));
    }

    const handleFileSelection = (event) => {
        var file = event.target.files[0];
        setFileName(file.name);
        const reader = new FileReader();
        reader.addEventListener('load', (content) => {
          setSrtContent(content.target.result)
        });
        reader.readAsText(file);
    }

    return (
    <Modal show={showModal} onHide={closeThisModal} onShow={modalInit} size="xl">
        <Modal.Header >
            <div className="d-flex justify-content-between w-100">
                <button onClick={closeThisModal} className="btn btn-sm text-secondary"
                    title={t('general_cancel')}>
                    <span className="material-icons-round">close</span>
                </button>
                <div className="d-flex flex-column text-center">
                    <Modal.Title className="my-auto">
                        {t('modal_importfromcc_title')}
                    </Modal.Title>
                    <p className="m-0">{t(currentStep.title)}</p>
                </div>
                <span className="btn-placeholder"></span>
            </div>
        </Modal.Header>
        <Modal.Body>
            <div className="w-100 px-4">
                <Tab.Container id="wizard-for-subtitles-analyze" activeKey={currentStep.key} defaultActiveKey="step-1">
                    <Tab.Content>
                        <Tab.Pane eventKey="step-1">
                            <p>{t('modal_importfromcc_step1_text')}</p>
                            <div className="input-group mb-2">
                                <div className="custom-file">
                                    <input onChange={handleFileSelection} type="file" accept=".srt,.SRT"
                                        className="custom-file-input" id="inputSRTFile" name="inputSRTFile" />
                                    <label className="custom-file-label" htmlFor="inputSRTFile" data-browse={t("file_select")}>
                                        { fileName || t('modal_importfromcc_step1_fileinput_placeholder') }
                                    </label>
                                </div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="step-2">
                            <p>{t('modal_importfromcc_step2_text')}</p>
                            <div className="d-flex flex-wrap gap-2 mb-4">
                                { tags.map(t =>
                                    <IconPill key={t.tagId} 
                                        iconId={t.tagId} iconText={t.icon} iconName={t.name} iconColor={t.color}
                                        pillText={t.name} closable={true} onCloseClick={removeTag}/>
                                )}
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="step-3">
                            <div className="d-flex flex-column">
                                { markersToImport.length > 0 && <>
                                <div className="row bg-light border border-bottom-0 extra-rounded-top">
                                    <div className="col-2 col-sm-2 col-lg-1 col-xl-1 p-2 text-center">
                                        {t('modal_importfromcc_step3_th1')}
                                    </div>
                                    <div className="col-2 col-sm-2 col-lg-1 col-xl-1 p-2 text-center">
                                        {t('modal_importfromcc_step3_th2')}
                                    </div>
                                    <div className="col-4 col-sm-5 col-lg-6 col-xl-7 p-2">
                                        {t('modal_importfromcc_step3_th3')}
                                    </div>
                                    <div className="col-4 col-sm-3 col-lg-4 col-xl-3 4 p-2">
                                        {t('modal_importfromcc_step3_th4')}
                                    </div>
                                </div>
                                <div className="row border extra-rounded-bottom"
                                    style={{overflowY:'scroll',overflowX:'hidden',maxHeight:'320px'}}>
                                    { markersToImport.map(marker => <>
                                    <div onClick={() => triggerChecked(marker.tempId)}
                                        className="col-2 col-sm-2 col-lg-1 col-xl-1 p-2 text-center cursor-pointer">
                                        <FontAwesomeIcon className="cursor-pointer"
                                            icon={marker.selected ? faCheckSquare : faSquare}/>
                                    </div>
                                    <div onClick={() => triggerChecked(marker.tempId)}
                                        className="col-2 col-sm-2 col-lg-1 col-xl-1 p-2 text-center cursor-pointer">
                                        {marker.startTime}
                                    </div>
                                    <div onClick={() => triggerChecked(marker.tempId)}
                                        className="col-4 col-sm-5 col-lg-6 col-xl-7 p-2 cursor-pointer">
                                        <span title={marker.description} className="d-block text-muted text-truncate">
                                            {marker.description}
                                        </span>
                                    </div>
                                    <div onClick={() => triggerChecked(marker.tempId)}
                                        className="col-4 col-sm-3 col-lg-4 col-xl-3 4 p-2 d-flex gap-1 cursor-pointer">
                                        { marker.markerTags.map(mt =>
                                            <IconPill key={mt.vtgTag.tagId} iconId={mt.vtgTag.tagId} iconSize="sm"
                                                iconText={mt.vtgTag.icon} iconName={mt.vtgTag.name} iconColor={mt.vtgTag.color}
                                                pillText={mt.vtgTag.name}/> )}
                                    </div> </>)}
                                </div>
                                <div className="d-flex justify-content-end gap-2 mt-3">
                                    <button onClick={() => changeSelectionForAllMarkers(true)} className="btn btn-sm btn-link vitag-text">
                                        {t('modal_importfromcc_step3_buttonselectall')}
                                    </button>
                                    <button onClick={() => changeSelectionForAllMarkers(false)} className="btn btn-sm btn-link vitag-text">
                                        {t('modal_importfromcc_step3_buttonunselect')}
                                    </button>
                                </div>
                                </> }
                                { markersToImport.length == 0 &&
                                <div className="w-100 p-5 text-center bg-light extra-rounded">
                                    <p className="m-0 py-4">
                                        {t('modal_importfromcc_step3_notmatches')}
                                    </p>
                                </div> }
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <div className="w-100 d-flex justify-content-between">
                <div>
                    { currentStep.showPrev &&
                    <button onClick={prevStep} className="btn btn-secondary">
                        {t('modal_importfromcc_previous')}
                    </button>}
                </div>
                { currentStep.key == 'step-3' && 
                    <button onClick={startMarkerImports} disabled={! atLeastOneMarkerSelected} className="btn btn-vitag">
                        {t('modal_importfromcc_savemarkers')}
                    </button> }
                { currentStep.showContinue &&
                <button onClick={nextStep} className="btn btn-vitag">
                    { t(currentStep.continue) }
                </button> }
            </div>
        </Modal.Footer>
    </Modal>
    );
}

export { ModalMarkersFromSubtitles }