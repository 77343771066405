import React, { useState, useEffect } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { defaultNoProfilePicture } from "../utils/ImageUtils";
import { useTranslation } from "react-i18next";

function FloatingProfileIndicator({ profile, placement = "bottom" }) {

    const [avatarImage, setAvatarImage] = useState();
    const { t } = useTranslation();

    useEffect(() => {
        setAvatarImage(profile?.avatarImage);
    }, [profile]);

    const putFallbackImage = () => {
        setAvatarImage(defaultNoProfilePicture);
    }

    const popover = <Popover style={{maxWidth:'400px'}}>
        <Popover.Content>
            <div className="d-flex p-1">
                <img src={avatarImage} onError={putFallbackImage}
                    className="profile-image text-center bg-white"
                    style={{width:'44px', height:'44px', borderWidth:'1px', lineHeight:'2.5em'}}/>
                <div className="d-flex flex-column align-items-start px-3">
                    { profile?.fullName &&
                        <span className='fw-bold text-dark'>
                            {profile?.fullName}
                        </span> }
                    <span className='vitag-text-light'>
                        {profile?.nickname}
                    </span>
                    <div className="d-flex gap-3">
                        { profile?.videos > 0 && <span className="text-muted text-center">
                            {t('floating_indicatorvideos_title')} <br/>{profile?.videos}
                        </span> }
                        { profile?.tags > 0 && <span className="text-muted text-center">
                            {t('floating_indicatortags_title')} <br/>{profile?.tags}
                        </span> }
                        { profile?.lists > 0 && <span className="text-muted text-center">
                            {t('floating_indicatorlists_title')} <br/>{profile?.lists}
                        </span> }
                    </div>
                </div>
            </div>
        </Popover.Content>
    </Popover>

    return (
        <OverlayTrigger overlay={popover} trigger="click" placement={placement} rootClose="true">
            <span className="text-vitag">{profile?.fullName}</span>
        </OverlayTrigger>
    );
}

export { FloatingProfileIndicator };
