import moment from "moment";
import React, { createContext, useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { overrideLogin } from "../services/AuthorizarionService";
import { getProfileAccess, getProfileInfo } from "../services/CoreService";
import { PREFERENCES, savePreference } from "../utils/preferences/user-preferences";
import { subscriptionsByOrganization, createSubscription, getSubsInfo } from "../services/VitagSubscriptionsService";
import { Typologies } from '../utils/TypologiesEnum';
import { config } from '../config';

const GeneralContext = createContext([]);

function GeneralContextProvider ({ children }) {

    const [profile, setProfile] = useState();
    const [subs, setSubs] = useState();
    const [contexts, setContexts] = useState([]);
    const [currentContext, setCurrentContext] = useState();
    const { i18n } = useTranslation();
    const [isProUser, setIsProUser] = useState(false);

    const loadContextsFromBackend = () => {
        getProfileAccess()
            .then(resp => {
                const ctxs = resp.data;
                ctxs.forEach(org => {
                    subscriptionsByOrganization(org.organizationId)
                        .then(resp => org.currentSub = resp.data[0])
                        .catch(err => {
                            if(config.IS_BETA) console.error('Error getting subscriptions', err)
                        });
                })
                setContexts(ctxs);
            })
            .catch(_ => {
                if(config.IS_BETA) console.error('Error getting list of contexts')
            });
    }

    const loadProfileFromBackend = () => {
        getProfileInfo()
            .then(resp => setProfile(resp.data))
            .catch(_ => {
                if(config.IS_BETA) console.error('Error getting profile')
            });
    }

    const loadCurrentsSubs = () => {
        getSubsInfo()
            .then(resp => {
                if (resp.data[0] === undefined) {
                    createSubscription(false, 'DEFAULT', currentContext?.organizationId)
                    .then(r => {
                        setSubs([r.data]);
                    }).catch(err => {
                        if(config.IS_BETA) console.error('Error creating subscription', err);
                    });
                } else {
                    setSubs(resp.data);
                }
            })
            .catch(err => {
                if(config.IS_BETA) console.error('Error getting subscriptions', err)
            });
    }

    useEffect(() => {
        if (subs && subs.length > 0) {
            const latest = subs.reduce((latestSub, currentSub) => {
                const latestStartDate = new Date(latestSub.startDate);
                const currentStartDate = new Date(currentSub.startDate);
                return currentStartDate > latestStartDate ? currentSub : latestSub;
            }, subs[0]);

            if (latest.tpUserAccountType.internalId === Typologies.UAT_PROFESSIONAL && latest.tpStatus.internalId === Typologies.ACTIVE) {
                setIsProUser(true);
            } else {
                setIsProUser(false);
            }
        }
    }, [subs]);

    const overrideCurrentContext = (newCurrentContext) => {
        if (!newCurrentContext) {
            if(config.IS_BETA) console.error('Impossible override current context, new-current-context is null');
            return;
        }
        overrideLogin(newCurrentContext.organizationId)
            .then(data => {
                localStorage.setItem('nibelungo', data.authc);
                setCurrentContext(newCurrentContext);
                i18n.setDefaultNamespace(newCurrentContext.organizationId + '');
                i18n.changeLanguage(i18n.language);
                setTimeout(() => {
                    savePreference(PREFERENCES.LAST_CONTEXT, newCurrentContext.organizationId);
                }, 1000);
            })
            .catch(error => {
                if(config.IS_BETA) console.log('Override context error: ', error)
            });
    }

    useEffect(() => {
        loadContextsFromBackend();
        loadProfileFromBackend();
        loadCurrentsSubs();
        i18n.init({debug:false})
        i18n.changeLanguage();
        moment.locale(i18n.language);
    }, []);

    useEffect(() => {
        if (currentContext) {
            loadCurrentsSubs();
        }
    }, [currentContext]);

    return (
        <GeneralContext.Provider value={{
            currentContext, overrideCurrentContext,
            contexts, loadContextsFromBackend,
            profile, loadProfileFromBackend,
            subs, loadCurrentsSubs,
            isProUser}}>
            {children}
        </GeneralContext.Provider>
    )

}

export { GeneralContext, GeneralContextProvider };
