import React, { useContext, useEffect, useState } from "react";
import { addCommentAtMarker, listAllCommentsByMarker } from "../services/VitagMarkersService";
import { presentDate } from "../utils/NabUtils";
import { useTranslation } from "react-i18next";
import { config } from '../config';
import { defaultNoProfilePicture } from "../utils/ImageUtils";
import { GeneralContext } from "../contexts/general-context";
import moment from "moment";

function CommentsPanel({videoMarkerId, saveForNewMarker, isNewMarker, setUnsavedComments, unsavedComments}) {

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const [comments, setComments] = useState([]);
    const [commentText, setCommentText] = useState('');
    const { t } = useTranslation();
    const { profile } = useContext(GeneralContext);
    const [avatarImage, setAvatarImage] = useState('');

    useEffect(() => {
        if (profile) {
            setAvatarImage(profile.avatarImage);
        } else {
            setAvatarImage(defaultNoProfilePicture);
        }
        
    }, [profile]);

    useEffect(() => {
        setComments([]);
        if(config.IS_BETA) console.log('Getting comments for ' + videoMarkerId);
        loadComments();
    }, [videoMarkerId]);

    const loadComments = () => {
        if (videoMarkerId) {
            listAllCommentsByMarker(videoMarkerId)
                .then(resp => setComments(resp.data))
                .catch(err => {
                    if(config.IS_BETA) console.error('Error getting comments', err)
                });
        }
    }

    const publishComment = () => {
        if (videoMarkerId) {
            addCommentAtMarker(videoMarkerId, commentText)
                .then(_ => {
                    if(config.IS_BETA) console.log('Comment added successful');
                    setCommentText('');
                    loadComments();
                })
                .catch(err => {
                    if(config.IS_BETA) console.error('Error posting comment', err)
                });
        } else {
            const now = moment().tz(timezone).utc();

            const entryDate = now.format('YYYY-MM-DD HH:mm:ss');

            const newComment = {
                comment: commentText,
                entryDate: entryDate,
                userFullName: profile.fullName,
                userPicture: avatarImage
            };

            setUnsavedComments(prevUnsaved => [...(prevUnsaved || []),newComment]);
            setComments(prevComments => [newComment, ...prevComments]);
            setCommentText('');
        }
    }

    const handleCommentChange = (evt) => {
        setCommentText(evt.target.value);
    }

    return <>
        <div className="p-2">
            <div className="mt-1 mb-3">
                <label htmlFor="new-comment">{t('commentspanel_label_newcomment')}</label>
                <div className="d-flex align-items-center gap-2 rounded border">
                    <textarea value={commentText} onChange={handleCommentChange} placeholder={t('commentspanel_placeholder_newcomment')}
                        name="new-comment" id="new-comment" rows="2" style={{resize:'none'}}
                        className="form-control form-control-sm border-0 without-border">
                    </textarea>
                    <div className="p-2">
                        <button onClick={publishComment} className="btn btn-circle p-0 line-0 bg-vitag text-white">
                            <span className="material-icons-round" style={{fontSize:'28px'}}>send</span>
                        </button>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-column pl-4 gap-2" style={{minHeight:'140px',maxHeight:'220px',overflowY:'scroll'}}>
                { comments.map(c =>
                    <div className="d-flex flex-column py-1 px-2 border extra-rounded">
                        <div className="d-flex gap-2">
                            <div>
                                <div style={{width:'36px',height:'36px'}}
                                    className="border rounded-circle align-items-center text-center">
                                    { c.userPicture ?
                                        <img src={c.userPicture} className="rounded-circle" style={{width:'36px',height:'36px',objectFit:'contain'}}/> :
                                        <span className="material-icons-round mt-1">person</span> }
                                </div>
                            </div>
                            <div className="small">
                                <p className="m-0 fw-bold">{c.userFullName}</p>
                                <p className="m-0">{c.comment}</p>
                            </div>
                        </div>
                        <div className="d-flex">
                            <span className="small text-muted ml-auto">
                                {presentDate(c.entryDate, timezone)}
                            </span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    </>
}

export { CommentsPanel };
