import axios from 'axios';

function createList(listName) {
    return axios.post(`/vtg-lists/`, { listName });
}

function changeListName(listHashId, listName) {
    return axios.put(`/vtg-lists/change-name/${listHashId}`, { listName });
}

function deleteList(listHashId) {
    return axios.delete(`/vtg-lists/${listHashId}`);
}

function assingListsForVideo(videoId, selectedLists) {
    return axios.post(`/vtg-lists/for-video/${videoId}`, { selectedLists });
}

function assingListsForVideos(selectedVideos, selectedLists) {
    return axios.post(`/vtg-lists/for-videos/`, { selectedVideos, selectedLists });
}

function listAllLists() {
    return axios.get('/vtg-lists/');
}

function listAllListsWithVideoAssign(videoId) {
    return axios.get(`/vtg-lists/by-video/${videoId}`);
}

export { 
    createList, changeListName, deleteList,
    assingListsForVideo, assingListsForVideos,
    listAllLists, listAllListsWithVideoAssign
};
