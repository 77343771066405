import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as faEmptyStar }  from '@fortawesome/free-regular-svg-icons';
import { faStar as faFillStar }  from '@fortawesome/free-solid-svg-icons';
import { markFavoriteVideo, quitFavoriteVideo } from '../services/VitagVideosService';
import { fixDate, presentDate } from '../utils/NabUtils';
import { VideoProviderIcon } from './VideoProviderIcon';
import { useEffect } from 'react';
import { config } from '../config';

function VideoItem({
    video, loggedUser, timezone,
    showSelectionIndicator, showAsGrid,
    showFavoriteIndicator = true, disableItem = false, forRecyclerBin = false,
    resumeLiveSession, notifyVideoSelection,
    customOptions = [], handleOptionClick = (video, option) => {
        if(config.IS_BETA) console.log(`Option clicked: '${option}'`, video)
    }
}) {

    const history = useHistory();
    const { t } = useTranslation();
    const [videoDto, setVideoDto] = useState(video);

    useEffect(() => {
        setVideoDto(video);
    }, [video])
    

    const handleVideoClick = (evt) => {
        if(disableItem) {
            if(config.IS_BETA) console.log('Disabled click for this element.');
            return;
        }
        if (evt.shiftKey) {
            localStorage.setItem(videoDto.videoId, 'true');
            const wnd = window.open(videoDto.url, "_blank").focus();
            setTimeout(() => {
                if(config.IS_BETA) console.log('Trying to close the new window');
                wnd?.close();
            }, 3500);
            return;
        }
        // if (videoDto.videoProvider.internalId === Typologies.VT_EXTERN_VIDEO) {
        //     const videoPresent = localStorage.getItem(videoDto.videoId);
        //     if (videoPresent) {
        //         history.push(`/video/${videoDto.hashId}`);
        //         return;
        //     }
        //     showHelpMessageForVideo();
        //     return;
        // }
        if(videoDto.fromLiveSession && ! videoDto.onlineId) {
            if(config.IS_BETA) console.log('Click on: ', videoDto);
            resumeLiveSession();
            return;
        }
        history.push({pathname:`/video/${videoDto.hashId}`, state:{last:video.lastPlaying}});
    }

    const safeNotifyVideoSelection = (event) => {
        notifyVideoSelection && notifyVideoSelection(videoDto.videoId, event.target.checked);
    }

    const handleFavorite = () => {
        if (! videoDto.isFav) {
            markFavoriteVideo(videoDto.hashId)
                .then(_ => {
                    if(config.IS_BETA) console.log('Fav saved');
                    setVideoDto({...videoDto, isFav: true});
                })
                .catch(err => {
                    if(config.IS_BETA) console.error('Error saving fav', err)
                });
            return;
        }
        quitFavoriteVideo(videoDto.hashId)
            .then(_ => {
                if(config.IS_BETA) console.log('Fav quited');
                setVideoDto({...videoDto, isFav: false});
            })
            .catch(err => {
                if(config.IS_BETA) console.error('Error quiting fav', err)
            });
    }

    const videoOptionsDropdown = (
        customOptions.filter(opt => opt.show).length > 0 && <div className="dropdown">
            <div title={t('page_videos_videoitem_button_options_title')}
                className="nab-circle-btn border cursor-pointer" data-toggle="dropdown">
                <span className="material-icons-round cursor-pointer">
                    more_vert
                </span>
            </div>
            <div className="dropdown-menu dropdown-menu-left shadow">
                { customOptions
                    .filter(opt => opt.show)
                    .map(option => 
                    <button key={option.action} className="dropdown-item"
                        onClick={() => handleOptionClick(videoDto, option)}>
                        { option.label }
                    </button>) }
            </div> 
        </div> );

    const extraVideoInfoIndicators = (
        <div className="d-flex pb-1 gap-2">
            <div className="circle-mini-badge bg-light">
                <VideoProviderIcon videoProvider={videoDto.videoProvider}/>
            </div>
            { loggedUser?.userId !== videoDto.userId && ! videoDto.isTrainingMaterial &&
            <div title={t(`generalitem_sharedwithme_${videoDto.shareType}_title`)} className="circle-mini-badge bg-light">
                <span className="material-icons-round text-dark">share</span>
            </div> }
            { videoDto.isTrainingMaterial &&
            <div title={t('page_videos_videoitem_trainingmaterial_title')} className="circle-mini-badge bg-light">
                <span className="material-icons-round text-dark">school</span>
            </div> }
        </div> );

    const favoriteIndicator = (
        <span onClick={handleFavorite}
            title={t(! videoDto.isFav ? 'page_videos_videoitem_label_markasfavorite' : 'page_videos_videoitem_label_removefavorite')}
            className={(videoDto.isFav ? 'text-warning' : 'text-light') + ' cursor-pointer'}>
            <FontAwesomeIcon icon={videoDto.isFav ? faFillStar : faEmptyStar} style={{fontSize:'1.2em'}}/>
        </span>
    );

    const progressBar = (
        videoDto?.lastPlaying > 0 && <div className="position-absolute rounded-bottom w-100 overflow-hidden" style={{bottom:0,backgroundColor:'#c4c4c4'}}>
            <div className="bg-vitag"
                style={{borderBottomLeftRadius:'0.25rem', height:'4px',width:(100 * videoDto.lastPlaying / videoDto.timeInSeconds) + '%'}}>
            </div>
        </div>
    );

    return (
        <React.Fragment>
            { showAsGrid ? /* grid view */
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-2 mb-3">
                <div className="position-relative d-flex w-100 bg-dark rounded">
                    <img onClick={handleVideoClick} src={videoDto.miniature} className="w-75 cursor-pointer mx-auto video-preview-img"/>
                    { showSelectionIndicator &&
                    <input checked={videoDto.selected} onChange={safeNotifyVideoSelection}
                        type='checkbox' className='video-select-indicator'/> }
                    { ! showSelectionIndicator && <div className="corner-tl">
                        { videoOptionsDropdown }
                    </div>  }
                    <div className="corner-br">
                        { extraVideoInfoIndicators }
                    </div>
                    { showFavoriteIndicator && <div className="corner-tr">
                        { favoriteIndicator }
                    </div>}
                    { progressBar }
                </div>
                <span onClick={handleVideoClick} style={{textDecoration:'none'}} title={videoDto.title}
                    className={ (disableItem ? 'text-muted' : 'text-dark') + " cursor-pointer d-block font-weight-bold text-truncate mt-1"}>
                    {videoDto.title}
                </span>
                { loggedUser?.userId === videoDto.userId ?
                    <span className={ (disableItem ? 'text-muted' : 'text-dark') + " d-block small font-weight-light"}>
                        {t('page_videos_videoitem_label_uploadedbyme')}
                    </span> :
                    <span className={ (disableItem ? 'text-muted' : 'text-dark') + " d-block small"}>
                        {t('page_videos_videoitem_label_uploadedby', {user:videoDto.userNickname})}
                    </span> }
                { ! forRecyclerBin &&
                <span className={ (disableItem ? 'text-muted' : 'text-dark') + " d-block small"} title={fixDate(videoDto.entryDate, timezone)}>
                    {presentDate(videoDto.entryDate)}
                </span> }
                { forRecyclerBin && 
                <span className={ (disableItem ? 'text-muted' : 'text-dark') + " d-block small" }
                    title={fixDate(videoDto.deletedDate, timezone)}>
                    {t('page_videos_videoitem_label_deleted')} { videoDto.deletedDate ? presentDate(videoDto.deletedDate) : t('page_videos_videoitem_label_deletedlongago')}
                </span> }
            </div> :
            /* list view */
            <div className="col-12 col-md-6 mt-3 mb-2 position-relative">
                <div className="d-flex">
                    <div className='position-relative rounded cursor-pointer flex-grow-0 flex-shrink-0 text-center bg-dark mr-3' style={{width:'160px'}}>
                        <img onClick={handleVideoClick} src={videoDto.miniature}
                            className='w-75' style={{objectFit:'cover', height:'82px'}}/>
                        { showSelectionIndicator &&
                        <input checked={videoDto.selected} onChange={safeNotifyVideoSelection}
                            type='checkbox' className='video-select-indicator'/> }
                        { ! showSelectionIndicator &&
                        <div className="corner-tl">
                            { videoOptionsDropdown }
                        </div> }
                        <div className="corner-br">
                            { extraVideoInfoIndicators }
                        </div>
                        { showFavoriteIndicator && <div className="corner-tr">
                            { favoriteIndicator }
                        </div>}
                        { progressBar }
                    </div>
                    <div className="d-flex flex-column my-auto pr-5" style={{minWidth:0}}>
                        <span onClick={handleVideoClick} style={{textDecoration:'none'}} title={videoDto.title}
                            className={ (disableItem ? 'text-muted' : 'text-dark') + " cursor-pointer d-block font-weight-bold text-truncate"}>
                            {videoDto.title}
                        </span>
                        { loggedUser?.userId === videoDto.userId ?
                        <span className={ (disableItem ? 'text-muted' : 'text-dark') + " d-block small font-weight-light"}>
                            {t('page_videos_videoitem_label_uploadedbyme')}
                        </span> :
                        <span className={ (disableItem ? 'text-muted' : 'text-dark') + " d-block small" }>
                            {t('page_videos_videoitem_label_uploadedby', {user:videoDto.userNickname})}
                        </span> }
                        { ! forRecyclerBin && 
                            <span className={ (disableItem ? 'text-muted' : 'text-dark') + " d-block small" }
                                title={fixDate(videoDto.entryDate, timezone)}>
                                {presentDate(videoDto.entryDate)}
                            </span> }
                        { forRecyclerBin && 
                            <span className={ (disableItem ? 'text-muted' : 'text-dark') + " d-block small" }
                                title={fixDate(videoDto.deletedDate, timezone)}>
                                {t('page_videos_videoitem_label_deleted')} { videoDto.deletedDate ? presentDate(videoDto.deletedDate) : t('page_videos_videoitem_label_deletedlongago')}
                            </span> }
                    </div>
                </div>
            </div>}
        </React.Fragment>
    );
}

export { VideoItem };
