import React, { useEffect, useRef, useState } from 'react';
import { Col, Alert, Button, Modal } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { config } from '../config';
import { VitagFooter } from '../general/VitagFooter';
import { VitagTitleBar } from '../general/VitagTitleBar';
import { signUpUser, isEmailUsed, checkOrganizationCode, checkInvitationHash } from '../services/CoreService';
import { useCustomToast } from '../utils/toasts/useCustomToast';
import GoogleSignin from '../login/GoogleSignIn';

function SignUp() {

    let { token } = useParams();
    let location = useLocation();
    const { t } = useTranslation();
    const { addToast } = useCustomToast();
    const lastTimeoutId = useRef(-1);
    const history = useHistory();

    const [accountInfo, setAccountInfo] = useState({
        email:'', firstName:'', lastName:'', password:'', confirmPassword:'', organizationName: ''
    });
    const [showPassword, setShowPassword] = useState(false);
    const [repeatedEmail, setRepeatedEmail] = useState(false);
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [invalidOrgCode, setInvalidOrgCode] = useState(false);
    const [validOrgCode, setValidOrgCode] = useState(false);
    const [validInvitation, setValidInvitation] = useState(false);
    const [fromInvitation, setFromInvitation] = useState(false);
    const [conditionsAccepted, setConditionsAccepted] = useState(false);
    const [signUpSuccessful, setSignUpSuccessful] = useState(false);
    const [organizationCode, setOrganizationCode] = useState('');
    const [nextPage, setNextPage] = useState();

    useEffect(() => {
        document.title = config.TITLE_BASE + t('title_sign_up');
        setNextPage(location.state?.next);
    }, []);

    useEffect(() => {
        if (location.pathname.startsWith('/invitation/')) {
            setFromInvitation(true);
            checkInvitation(token);
        } else {
            setFromInvitation(false);
            debouncedCheckOrganizationCode('');
        }
    }, [location]);

    const handleInputChange = (event, name) => {
        const value = event.target.value;
        const newAccountInfo = { ...accountInfo};
        newAccountInfo[name] = value;
        setAccountInfo(newAccountInfo);
    }

    const handleConditionsAcceptedChange = (event) => {
        const checked = event.target.checked;
        setConditionsAccepted(checked);
    }

    const handleOrganizationCode = (event) => {
        const value = event.target.value;
        setOrganizationCode(value);
        debouncedCheckOrganizationCode(value);
    }

    const debouncedCheckOrganizationCode = (code) => {
        if (lastTimeoutId.current !== -1) {
            clearTimeout(lastTimeoutId.current);
        }
        lastTimeoutId.current = setTimeout(checkCode, 600, code);
    }

    const checkCode = (code) => {
        checkOrganizationCode(code)
            .then(resp => {
                if(config.IS_BETA) console.log('Verified....', resp.data);
                const invalidCode = code !== '' && ! resp.data.ownerOrganizationId;
                setInvalidOrgCode(invalidCode);
                const validCode = code !== '' && resp.data.ownerOrganizationId;
                setValidOrgCode(validCode);
                const newAccountInfo = { ...accountInfo};
                newAccountInfo.cliId = resp.data.cliOrganizationId;
                newAccountInfo.ownId = validCode ? resp.data.ownerOrganizationId : undefined;
                newAccountInfo.organizationName = resp.data.organizationName;
                setAccountInfo(newAccountInfo);
            })
            .catch(err => {
                if(config.IS_BETA) console.error('Error validating...', err)
            });
    }

    const checkInvitation = (leToken) => {
        checkInvitationHash(leToken)
            .then(resp => {
                if(config.IS_BETA) console.log('Invitation status?', resp.data);
                const isValidInvitation = resp.data.ownerOrganizationId !== undefined;
                setValidInvitation(isValidInvitation);
                const newAccountInfo = { ...accountInfo};
                newAccountInfo.cliId = resp.data.cliOrganizationId;
                newAccountInfo.ownId = resp.data.ownerOrganizationId;
                newAccountInfo.organizationName = resp.data.organizationName;
                newAccountInfo.email = resp.data.invitationEmail;
                newAccountInfo.invitationHash = leToken;
                setAccountInfo(newAccountInfo);
            })
            .catch(err => {
                if(config.IS_BETA) console.error('Error validating invitation...', err)
            });
    }

    const doSignUp = () => {
        const user = {
            nickname: accountInfo.email,
            email1: accountInfo.email,
            password: accountInfo.password,
            cliOrganizationId: accountInfo.cliId,
            ownerOrganizationId: accountInfo.ownId,
            admPerson: {
                cliOrganizationId: accountInfo.cliId,
                ownerOrganizationId: accountInfo.ownId,
                firstName: accountInfo.firstName,
                lastName: accountInfo.lastName,
            }
        };
        signUpUser(user, token)
            .then(_ => setSignUpSuccessful(true))
            .catch(err => {
                if(config.IS_BETA) console.error('Error creating user', err);
                addToast({
                    header: t('page_signup_toast_title'),
                    body: t('page_signup_toast_text'),
                    variant: 'error'
                });
            });
    }

    const validateEmail = (event) => {
        const email = event.target.value;
        const emailRegexp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/;
        const validEmail = emailRegexp.test(email);
        setInvalidEmail(! validEmail);
        if (validEmail) {
            isEmailUsed(email)
                .then(resp => {
                    if(config.IS_BETA) console.log('Is email used? ' + resp.data);
                    setRepeatedEmail(resp.data)
                })
                .catch(err => {
                    if(config.IS_BETA) console.error(':(', err)
                });
        } else {
            setRepeatedEmail(false);
        }
    }
    
    const changeShowPassword = () => setShowPassword(! showPassword);

    const goLogin = () => {
        history.push({pathname:'/login', state:{next:nextPage}});
    };

    const goSignUp = () => {
        history.push({pathname:'/sign-up', state:{next:nextPage}});
    };
    
    return(
        <div className="full-container bg-vitag px-4 px-md-5" style={{overflowY:'scroll'}}>
            <div className="w-100 d-block d-sm-none" style={{paddingTop:'1em'}}></div>
            <div className="w-100 d-none d-sm-block" style={{paddingTop:'6em'}}></div>
            { fromInvitation && ! validInvitation ?
            <Alert variant='danger' className='w-50 px-5 py-4 mx-auto extra-rounded'>
                <Alert.Heading>{t('page_signup_alert_invalidinvitation_title')}</Alert.Heading>
                <p>{t('page_signup_alert_invalidinvitation_text')}</p>
                <hr/>
                <div className="d-flex justify-content-end">
                    <Button onClick={goSignUp} variant='outline-danger'>
                        {t('page_signup_alert_invalidinvitation_btn_createaccount')}
                    </Button>
                </div>
            </Alert> :
            <div className="d-flex" style={{maxWidth:'800px',margin:'0 auto'}}>
                <div className="row bg-white extra-rounded px-3 px-md-4 py-3 mb-5" style={{zIndex:12}}>
                    <VitagTitleBar titleAtBar={t('page_signup_title')}/>
                    <input id="username" className='d-none' type="text" name="fakeusernameremembered"/>
                    <input id="password" className='d-none' type="password" name="fakepasswordremembered"/>
                    { fromInvitation && validInvitation &&
                        <div className="col-12 px-4">
                            <Alert variant='success'>
                                <h5 className='mb-0'>{t('page_signup_alert_validinvitation_title')}</h5>
                                <p className='mb-0'>
                                    <Trans i18nKey='page_signup_alert_validinvitation_text' values={{email:accountInfo.email}}>
                                        Esta cuenta será creada para el correo '<b>tu correo</b>'
                                    </Trans>
                                </p>
                            </Alert>
                        </div> }
                    { ! fromInvitation && <Col xs={12} className="form-group">
                        <label htmlFor='signUpEmail'>
                            {t('page_signup_label_email')}
                        </label>
                        <input id="signUpEmail" name="signUpEmail" autoComplete="nope"
                            className={"form-control" + ((repeatedEmail || invalidEmail) ? ' is-invalid' : '')}
                            value={accountInfo.email} onChange={evt => {handleInputChange(evt, 'email'); validateEmail(evt)}}
                            type="email" placeholder={t('page_signup_placeholder_email')} required/>
                        { repeatedEmail &&
                            <div className="invalid-feedback">
                                {t('page_signup_errors_email_alreadysignupemail')}
                            </div>}
                        { invalidEmail &&
                            <div className="invalid-feedback">
                                {t('page_signup_errors_email_invalidemail')}
                            </div>}
                    </Col> }
                    { false && <Col xs={12} sm={6} className="form-group">
                        <label htmlFor='orgCode'>
                            {t('page_signup_label_coupon')}
                        </label>
                        <input id="orgCode" name="orgCode"
                            className={"form-control" + (invalidOrgCode ? ' is-invalid' : '') + (validOrgCode ? ' is-valid' : '')}
                            value={organizationCode} onChange={handleOrganizationCode}
                            type="text" placeholder={t('page_signup_placeholder_coupon')}/>
                        <div className="invalid-feedback">
                            {t('page_signup_errors_code_invalidcode')}
                        </div>
                        <div className="valid-feedback">
                            {t('page_signup_success_code_validfor', {org: accountInfo.organizationName})}
                        </div>
                    </Col>}
                    { ((fromInvitation && validInvitation) || (! fromInvitation)) && <>
                    <Col xs={12} sm={6} className="form-group">
                        <label htmlFor='firstName'>{t('page_signup_label_name')}</label>
                        <input id="firstName" name="firstName" className="form-control"
                            value={accountInfo.firstName} onChange={evt => handleInputChange(evt, 'firstName')}
                            type="text" placeholder={t('page_signup_placeholder_name')} required/>
                    </Col>
                    <Col xs={12} sm={6} className="form-group">
                        <label htmlFor='lastName'>{t('page_signup_label_lastname')}</label>
                        <input id="lastName" name="lastName" className="form-control"
                            value={accountInfo.lastName} onChange={evt => handleInputChange(evt, 'lastName')}
                            type="text" placeholder={t('page_signup_placeholder_lastname')} required/>
                    </Col>
                    <Col xs={12} sm={6} className="form-group">
                        <label htmlFor="userPass">{t('page_signup_label_password')}</label>
                        <div className="input-group">
                            <input className="form-control" id="userPass" name="userPass" autoComplete="new-password"
                                value={accountInfo.password} onChange={evt => handleInputChange(evt, 'password')}
                                type={showPassword ? 'text' : 'password'}
                                placeholder={t('page_signup_placeholder_password')} required/>
                            <div onClick={changeShowPassword} className="input-group-append cursor-pointer">
                                <span className="input-group-text material-icons-round"
                                    title={t('page_signup_btn_showpassword_titleattr')}>visibility</span>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} sm={6} className="form-group">
                        <label htmlFor='confirmPassword'>{t('page_signup_label_confirmpassword')}</label>
                        <input id="confirmPassword" name="confirmPassword" autoComplete="new-password"
                            value={accountInfo.confirmPassword} onChange={evt => handleInputChange(evt, 'confirmPassword')}
                            type={showPassword ? 'text' : 'password'}
                            className={"form-control" + ((accountInfo.password && accountInfo.confirmPassword && accountInfo.password !== accountInfo.confirmPassword) ? ' is-invalid' : '')}
                            placeholder={t('page_signup_placeholder_confirmpassword')} required/>
                        <div className="invalid-feedback">
                            {t('page_signup_errors_passwordsdonotmatch')}
                        </div>
                    </Col>
                    </>}
                    <Col xs={12} className="mt-4 d-flex justify-content-center flex-column">
                        <div className="form-check mx-auto mb-3 d-block">
                            <input value={conditionsAccepted} onChange={handleConditionsAcceptedChange} className="form-check-input" type="checkbox" id="sign-up-accept-vitag"/>
                            <label className="form-check-label text-muted" htmlFor="sign-up-accept-vitag" style={{whiteSpace:'normal'}}>
                                <Trans i18nKey='page_signup_label_agreement'>
                                    Acepto las <a href='https://vitag.es/home/aviso-legal/' className='vitag-text-light' target='_blank'>Condiciones</a>,
                                    la <a href='https://vitag.es/home/politicas-de-privacidad/' className='vitag-text-light' target='_blank'>Política de datos</a>,
                                    la <a href='https://vitag.es/home/politica-de-cookies/' className='vitag-text-light' target='_blank'>Política de cookies</a> y 
                                    la responsabilidad del contenido que publico.
                                </Trans>
                            </label>
                        </div>
                        <div className="d-flex flex-column">
                            <div className="d-flex justify-content-center">
                                <button onClick={doSignUp}
                                    disabled={! conditionsAccepted || repeatedEmail || invalidEmail || ! accountInfo.email || ! accountInfo.firstName || ! accountInfo.lastName || ! accountInfo.password  || accountInfo.password !== accountInfo.confirmPassword}
                                    className="btn btn-primary bg-vitag mx-auto px-5">
                                    {t('page_signup_btn_signup')}
                                </button>
                            </div>
                            <div className="d-flex flex-column align-items-center">
                                <div className="w-100 border-top my-3"></div>
                                <p className='my-2'>O también puedes darte de alta usando tu cuenta de Google</p>
                                <GoogleSignin/>
                            </div>
                        </div>
                        <div className="w-100 my-3 border-bottom"></div>
                        <p className="body-1 text-center mb-3">
                            {t('page_signup_text_alreadyhaveaccount')}
                        </p>
                        <button onClick={goLogin} className="btn btn-block btn-link vitag-text">
                            {t('page_signup_btn_signin')}
                        </button>
                    </Col>
                </div>
            </div> }

            <div className="w-100 d-block d-sm-none" style={{paddingBottom:'1em'}}></div>

            <Modal show={signUpSuccessful} onHide={() => setSignUpSuccessful(false)}
                backdrop="static" keyboard={false}>
                <Modal.Header>
                    <div className="btn-placeholder"></div>
                    <Modal.Title className='mx-auto'>
                        {t('modal_signup_successcreateaccount_title')}
                    </Modal.Title>
                    <button onClick={goLogin}
                        title={t('modal_signup_successcreateaccount_btn_gotosignin_titleattr')}
                        className="btn btn-sm text-vitag">
                        <span className="material-icons-round">check</span>
                    </button>
                </Modal.Header>
                <Modal.Body className='px-5'>
                    <p>{t('modal_signup_successcreateaccount_text')}</p>
                    <p className="small">
                        <Trans i18nKey='modal_signup_successcreateaccount_subtext'>
                            Si tienes alguna duda puedes escribirnos a <a href="mailto:info@vitag.es">info@vitag.es</a>
                        </Trans>
                    </p>
                </Modal.Body>
            </Modal>

            <VitagFooter />

        </div>
    );

}

export { SignUp };