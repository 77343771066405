import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconIndicator } from "./IconIndicator";

function FavItem({ parentId, currentTag, position, setRefresh, filter, isReOrder = false, moveUp, moveDown }) {

    const [tag, setTag] = useState();
    const [selectedCounter, setSelectedCounter] = useState(0);
    const [recalc, setRecalc] = useState();
    const { t } = useTranslation();

    useEffect(() => {
        setTag(currentTag);
        calculateSelectedSubTags(currentTag.subTags);
    }, [currentTag.selected, currentTag.subTags]);

    useEffect(() => {
        if (recalc) {
            const newSubTags = [...tag.subTags];
            const idToReplace = recalc[0];
            const indexToReplace = tag.subTags.findIndex(st => st.tagId === idToReplace);
            newSubTags[indexToReplace] = recalc[1];
            const newTag = {...tag, subTags: newSubTags};
            setTag(newTag);
            sendRefresh(newTag);
            calculateSelectedSubTags(newSubTags);
        }
    }, [recalc, currentTag, currentTag.subTags]);

    const handleChecked = (event) => {
        const checked = event.target.checked;
        const newTag = {...tag};
        newTag.selected = checked;
        setTag(newTag);
        sendRefresh(newTag);
    }

    const calculateSelectedSubTags = (sTags) => {
        setSelectedCounter(sTags.filter(st => st.selected).length);
    }

    const sendRefresh = (leTag) => {
        setRefresh([position, leTag]);
    }

    const matchTag = (st) => {
        const searchFilter = filter.toLowerCase();
        return st.name.toLowerCase().includes(searchFilter);
    }

    return (
        <div>
            <div id={'heading' + tag?.tagId} style={{backgroundColor:(tag?.color + '11')}} className="px-2 d-flex justify-content-between">
                <div className="d-flex">
                    {isReOrder &&
                    <div className="align-middle my-auto mr-2">
                        <div className='d-flex m-0 p-0'>
                            <button onClick={moveUp} className='btn btn-sm m-0 p-0' style={{backgroundColor:(tag?.color + '13')}}>
                                <span className='material-icons-round'>expand_less</span>
                            </button>
                            <button onClick={moveDown} className='btn btn-sm m-0 p-0' style={{backgroundColor:(tag?.color + '13')}}>
                                <span className='material-icons-round'>expand_more</span>
                            </button>
                        </div>
                    </div>}
                    <div style={{backgroundColor:(tag?.color + '55')}}
                        className="border-0 rounded cursor-pointer rounded-pill pr-2 d-inline-flex mr-2 my-1 align-middle">
                        {!isReOrder &&
                        <input style={{transform:'scale(1.2)'}} id={'fav' + tag?.tagId} type="checkbox" className="cursor-pointer ml-3 mr-2 my-auto"
                            checked={tag?.selected} onChange={handleChecked}/>}
                        <IconIndicator iconColor={tag?.color} iconText={tag?.icon}/>
                        {!isReOrder &&
                        <span className="cursor-pointer text-dark mx-2 my-auto" style={{textShadow:'1px 1px 2px #ececec'}}
                            data-toggle="collapse" data-target={'#collapse' + tag?.tagId} aria-expanded="false" aria-controls={'collapse' + tag?.tagId}>{tag?.name}</span> 
                        }
                        {isReOrder &&
                        <span className="cursor-pointer text-dark mx-2 my-auto" style={{textShadow:'1px 1px 2px #ececec'}}>{tag?.name}</span> 
                        }
                                                   
                        { tag?.isFav && <span className="material-icons-round text-warning my-auto">star</span> }
                    </div>
                </div>
                {!isReOrder &&
                    <span className="small my-auto">
                    {t('modal_managefavs_selectedtagscounter', {n:selectedCounter, total:tag?.subTags?.length})}
                </span>}
            </div>
            {!isReOrder &&
            <div id={'collapse' + tag?.tagId} className="collapse" aria-labelledby={'heading' + tag?.tagId} data-parent={'#' + parentId}>
                <div className="mt-2">
                    { tag?.subTags?.length === 0 &&
                    <p className="w-100 ml-5 text-muted small">
                        {t('modal_managefavs_message_notags')}
                    </p>}
                    { tag?.subTags?.filter(st => matchTag(st))?.map((st, i) =>
                        <SubFavItem key={st.tagId} subTag={st} position={i} setRecalc={setRecalc}/> )}
                </div>
            </div>}
        </div>
    );
}

function SubFavItem({ subTag, position, setRecalc }) {

    useEffect(() => setTag(subTag), [subTag]);
    const[tag, setTag] = useState();

    const handleChecked = (event) => {
        const checked = event.target.checked;
        const newTag = {...tag};
        newTag.selected = checked;
        setTag(newTag);
        setRecalc([newTag.tagId, newTag]);
    }

    return (
        <div className="d-flex mx-5 my-2">
            <div style={{backgroundColor:(tag?.color + '55')}} className="border-0 rounded cursor-pointer rounded-pill d-inline-flex mr-2 align-middle"
                data-toggle="collapse" data-target={'#collapse' + tag?.tagId} aria-expanded="false" aria-controls={'collapse' + tag?.tagId}>
                <input style={{transform:'scale(1.2)'}} id={'sfi' + tag?.tagId}
                    type="checkbox" className="cursor-pointer my-auto ml-3 mr-2"
                    checked={tag?.selected} value={tag?.name} onChange={handleChecked}/>
                <IconIndicator iconColor={tag?.color} iconText={tag?.icon}/>
                <label htmlFor={'sfi' + tag?.tagId} style={{textShadow:'1px 1px 2px #ececec'}}
                    className="text-dark ml-1 mr-3 my-auto cursor-pointer">
                    {tag?.name}
                </label>
            </div>
        </div>
    );
}

export { FavItem };
