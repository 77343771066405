import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useCustomToast } from '../utils/toasts/useCustomToast';
import { Row, Modal, Col, Form, InputGroup, Alert } from 'react-bootstrap';
import { TagRow } from './helper/TagRow';
import { createSetupIntent, listPaymentMethods, updatePreference, deletePaymentMethod } from '../services/StripeService';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { config } from "../config";
import { ModalNewPaymentMethod } from './ModalNewPaymentMethod';
import { GeneralContext } from "../contexts/general-context";


function ModalPaymentMethods({ showModal, setShowModal, zIndexVar = 1050, isEdit = false }) {
  const { t } = useTranslation();
  const { addToast } = useCustomToast();
  const stripePromise = loadStripe(config.STRIPE_API_KEY);
  const[showNewCard, setShowNewCard] = useState(false);
  const[currentPaymentMethod, setCurrentPaymentMethod] = useState(null);
  const[clientSecret, setClientSecret] = useState('');
  const { profile } = useContext(GeneralContext);

  const [paymentMethods, setPaymentMethods] = useState([]);

  const closeThisModal = () => setShowModal(false);

  const initModal = () => {
    listPaymentMethods(profile.userId)
    .then(resp => {
        setPaymentMethods(resp.data);
    })
    .catch(err => {
        if(config.IS_BETA) console.error('Impossible retrieve payment methods', err)
    })
  }

  useEffect(() => {
    if (paymentMethods.length > 0) {
      paymentMethods.forEach(
        pm => {
            if (pm.defaultMethod === '1') setCurrentPaymentMethod(pm);
        }
      );
    }
}, [paymentMethods])

  const getCardLogo = (brand) => {
    const lowerBrand = brand.toLowerCase();
    const assetsPath = '/assets/imgs/cards/';
    let cardLogo;

    switch (lowerBrand) {
      case 'amex':
        cardLogo = `${assetsPath}american_express.png`;
        break;
      case 'diners':
        cardLogo = `${assetsPath}diners_club.png`;
        break;
      case 'discover':
        cardLogo = `${assetsPath}discover.png`;
        break;
      case 'jcb':
        cardLogo = `${assetsPath}jcb.png`;
        break;
      case 'mastercard':
        cardLogo = `${assetsPath}mastercard.png`;
        break;
      case 'unionpay':
        cardLogo = `${assetsPath}china_union.png`;
        break;
      case 'visa':
        cardLogo = `${assetsPath}visa.png`;
        break;
      default:
        cardLogo = `${assetsPath}credit_card.png`;
        break;
    }

    return cardLogo;
  }

  const selectMethod = (paymentMethod) => {
    if (currentPaymentMethod !== paymentMethod) {
      paymentMethods.forEach(
        pm => {
            if (paymentMethod === pm) {
              pm.defaultMethod = '1';
            } else {
              pm.defaultMethod = '0';
            }
        }
      );
      setCurrentPaymentMethod(paymentMethod);
    }
  }

  const openNewCardModal = () => {
    createSetupIntent(profile.userId)
    .then(resp => {
        if(config.IS_BETA) console.log('Setup successful', resp.data);
        setClientSecret(resp.data.clientSecret);
        setShowNewCard(true);
    })
    .catch(err => {
      if(config.IS_BETA) console.error('Impossible Setup', err);

      addToast({
          header: t('msg_new_paymentmethod_error_title'),
          body: t('msg_new_paymentmethod_errorsetup_body'),
          variant: 'error'
      });
    })
  }

  const savePreference = () => {
    updatePreference(currentPaymentMethod.userPaymentMethodId, profile.userId)
    .then(resp => {
      closeThisModal();
      addToast({
        header: t('msg_new_paymentmethod_sucessdefault_title'),
        body: t('msg_new_paymentmethod_sucessdefault_body'),
        variant: 'success'
      });
    })
    .catch(err => {
      if(config.IS_BETA) console.error(err);
      addToast({
          header: t('msg_new_paymentmethod_error_title'),
          body: t('msg_new_paymentmethod_errordefault_body'),
          variant: 'error'
      });
    })
  }

  const deletePM = (pm) => {
    deletePaymentMethod(pm.userPaymentMethodId)
    .then(resp => {
      setPaymentMethods(prevMethods => 
        prevMethods.filter(method => method.userPaymentMethodId !== pm.userPaymentMethodId)
      );
      addToast({
        header: t('msg_new_paymentmethod_sucessdelete_title'),
        body: t('msg_new_paymentmethod_sucessdelete_body'),
        variant: 'success'
      });
    })
    .catch(err => {
      if(config.IS_BETA) console.error(err);
      addToast({
        header: t('msg_new_paymentmethod_error_title'),
        body: t('msg_new_paymentmethod_errordelete_body'),
        variant: 'error'
      });
    })
  }
  
  return ( <>
    <Modal show={showModal} onShow={initModal} onHide={closeThisModal} size="lg" style={{ zIndex: zIndexVar }}>
        <Modal.Header>
            <button onClick={closeThisModal} title={t('general_close')} className="btn btn-sm text-secondary">
                <span className="material-icons-round">close</span>
            </button>
            <Modal.Title>{t('modal_paymentmethods_title')}</Modal.Title>
            <div className="d-flex gap-2 align-self-center">
                <div className="dropdown">
                    <button className='btn btn-sm dropdown-toggle' data-toggle="dropdown">
                        <span className="material-icons-round">settings</span>
                    </button>
                    <div className="dropdown-menu">
                        <div onClick={() => savePreference()} className="dropdown-item cursor-pointer">
                          {t('modal_paymentmethods_save_settings')}
                        </div>
                    </div>
                </div>
                {!isEdit && <button title={t('modal_paymentmethods_button_new')}  onClick={openNewCardModal}
                        className="btn btn-sm text-vitag">
                  <span className="material-icons-round">add_circle</span>
                </button>}
            </div>
        </Modal.Header>
        <Modal.Body className='px-5 py-4'>
        <div style={{maxHeight:'350px',overflow:'auto'}} className='table-responsive'>
                <table className="table table-sm">
                    <thead>
                        <tr>
                            <th className="text-center"  style={{ width: '66px', maxWidth: '66px'}}>
                              {t('modal_paymentmethods_table_brand')}
                            </th>
                            <th className="text-center mx-1" style={{ minWidth: '150px', width: '150px', maxWidth: '150px', wordWrap: 'break-word' }}>
                              {t('modal_paymentmethods_table_cardnumber')}
                            </th>
                            <th className="text-center mx-1" style={{ width: '98px', maxWidth: '98px', wordWrap: 'break-word' }}>
                              {t('modal_paymentmethods_table_expire')}
                            </th>
                            <th className="text-center mx-1" style={{ width: '150px', maxWidth: '150px', wordWrap: 'break-word' }}>
                              {t('modal_paymentmethods_table_cardname')}
                            </th>
                            <th className="text-center" style={{ width: '100px', maxWidth: '100px'}}>
                              {t('modal_paymentmethods_table_actions')}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                    {paymentMethods.length === 0 &&  
                    <tr>
                      <td class="text-center" colspan="5">
                        {t('modal_paymentmethods_table_noinfo')}
                      </td>
                    </tr>}
                    {paymentMethods.length > 0 && 
                    paymentMethods.map(evt =>
                    <tr key={evt.userPaymentMethodId} className='fs-lg text-center'>
                        <td><img src={getCardLogo(evt.brand)} alt={evt.brand} class="card-brand"/></td>
                        <td>**** **** **** {evt.number}</td>
                        <td>{(evt.monthExpired + '/' + evt.yearExpired)}</td>
                        <td>{evt.name}</td>
                        <td>
                          <button title={t('modal_paymentmethods_default')}  onClick={() => selectMethod(evt)}
                              className="btn btn-sm mr-2" style={{color: evt.defaultMethod === '1'? '#FFD700' : '#9c9c9c'}}>
                              <span className="material-icons-round">{evt.defaultMethod === '1' ? 'star': 'star_border' }</span>
                          </button>
                          <button title={t('modal_paymentmethods_delete')}  onClick={() => deletePM(evt)}
                              className="btn btn-sm text-vitag">
                              <span className="material-icons-round">delete</span>
                          </button>
                        </td>
                    </tr>)}
                    </tbody>
                </table> 
            </div>
        </Modal.Body>
    </Modal>
    <Elements stripe={stripePromise}>
      <ModalNewPaymentMethod 
      clientSecret={clientSecret} 
      showModal={showNewCard}
      setShowModal={setShowNewCard}
      paymentMethods={paymentMethods}
      setPaymentMethods={setPaymentMethods}
      />
    </Elements>
    </>);
}

export {ModalPaymentMethods};