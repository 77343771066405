const configToOpenLinks = {
    link: {
      decorators: {
        addTargetToExternalLinks: {
          mode: "automatic",
          callback: (url) => /^(https?:)?\/\//.test(url),
          attributes: {
            target: "_blank",
            rel: "noopener noreferrer",
          },
        },
      },
    },
};

export { configToOpenLinks };
