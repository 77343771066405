import axios from "axios";

function getAvailableUsers(searching) {
    return axios.get('vtg-groups/available-users', {params:{searching}});
}

function createGroup(group) {
    return axios.post('vtg-groups', group);
}

function cloneGroup(groupId, newName) {
    return axios.post(`vtg-groups/clone/${groupId}`, {newName});
}

function updateGroup(group) {
    return axios.put(`vtg-groups/${group.groupId}`, group);
}

function softDeleteGroup(groupId) {
    return axios.delete(`vtg-groups/${groupId}`);
}

function leaveGroup(groupId) {
    return axios.delete(`vtg-groups/leave/${groupId}`);
}

function getGroupInfo(groupId) {
    return axios.get(`vtg-groups/${groupId}`);
}

function listAllGroups(searching, start = 0, max = 50) {
    start = start * max;
    return axios.get('vtg-groups', {params:{searching, start, max}});
}

export { getAvailableUsers, createGroup, cloneGroup, updateGroup, softDeleteGroup, leaveGroup, getGroupInfo, listAllGroups };
