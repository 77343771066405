import { createPortal } from "react-dom"
import { CustomToast } from "./CustomToast";

export const CustomToastContainer = ({ toasts }) => {
    return createPortal(
        <div className="position-absolute" style={{right:'16px',bottom:'16px',zIndex:200000}}>
            { toasts.map(toast => <CustomToast key={toast.id} id={toast.id}>
                {toast.content}
            </CustomToast>) }
        </div> ,
        document.body
    );
}