import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconIndicator } from "../../general/IconIndicator";
import { convertSecondsToTime, convertMillisecondsToTime } from "../../utils/NabUtils";
import { config } from '../../config';

function Marker({
    loggedUser, userNickname, marker, shareType, collapseView = false, className,
    tableView = false, confirmDeleteMarker, startEditMarker, openShowMarker, 
    showActionsOnMarkers, children, itemRefs, repaintImage,
    moveToListener = (mrk) => {
        if(config.IS_BETA) console.log('Moving: ' + mrk)
    }
}) {

    const[firstTag, setFirstTag] = useState();
    const[otherCount, setOtherCount] = useState();
    const[otherDesc, setOtherDesc] = useState();
    const[backgroundImage, setBackgroundImage] = useState(null);
    const { t } = useTranslation();
    const [uploadingImage, setUploadingImage] = useState(false);

    useEffect(() => {
        if (marker.markerTags.length === 0) {
            if(config.IS_BETA) console.error('Invalid marker tags');
            return;
        }
        const first = marker.markerTags[0].vtgTag;
        setFirstTag(first);
        const count = marker.markerTags.length - 1;
        setOtherCount(count);
        const description = count > 0 ? marker.markerTags.map(t => t.vtgTag.name).join('\n') : '';
        setOtherDesc(description);
    }, []);

    useEffect(() => {
        const loadBackgroundImage = (url, attempts) => {
            if(attempts == undefined || attempts <= 0) {
                if(config.IS_BETA) console.error('Not available attempts');
                return;
            }
            const img = new Image();
            img.onload = () => {
                setBackgroundImage(url);
                setUploadingImage(false);
            }
            img.onerror = () => {
                setUploadingImage(true);
                if(config.IS_BETA) console.log('Attemp #' + attempts);
                setTimeout(() => {
                    loadBackgroundImage(url, attempts - 1);
                }, 3000);
            }
            img.src = url;
        };
        loadBackgroundImage(marker.thumbnail, 3);
    }, [marker]);

    if (tableView) {
        return (
            <tr className={className + " position-relative"}>
                <td>
                    { children }
                </td>
                <td style={{verticalAlign:'middle'}} onClick={() => moveToListener(marker)}>
                    <span className="text-muted cursor-pointer">
                        {(marker?.startMilliSecond > 0 ? convertMillisecondsToTime(marker?.startMilliSecond, false): convertSecondsToTime(marker?.startSecond))}
                    </span>
                </td>
                <td style={{verticalAlign:'middle'}} onClick={() => moveToListener(marker)}>
                    <p className="m-0 cursor-pointer fw-bold text-truncate small">
                        <b>{marker.title ? marker.title : firstTag?.name}</b>
                    </p>
                </td>
                <td style={{verticalAlign:'middle'}}>
                    { otherCount >= 0 &&
                    <div className="rounded rounded-pill border-soft d-inline-flex"
                        style={{height:'26px', backgroundColor:firstTag?.color+'66'}}>
                        <IconIndicator iconSize="sm" iconName={firstTag?.name} iconText={firstTag?.icon} iconColor={firstTag?.color}/>
                        { otherCount > 0 &&
                        <span title={otherDesc} style={{fontWeight:'600'}} className="my-auto text-center text-light pl-1 pr-2">
                            {otherCount}+
                        </span> }
                    </div> }
                </td>
            </tr>
        );
    }

    if (collapseView) {
        return (
            <div>
            {!uploadingImage && 
            <div 
                key={marker.videoMarkerId}
                ref={el => itemRefs.current[marker.videoMarkerId] = el}
                className={className + " shadow nab-hero position-relative extra-rounded"}
                onClick={() => moveToListener(marker)}
                style={{cursor:'pointer',backgroundSize:'cover',backgroundImage:`url(${backgroundImage})`}}>
                <div onClick={() => moveToListener(marker)}
                    className="d-flex justify-content-between p-2 w-100 rounded"
                    style={{zIndex:900, backgroundColor:'#000000AA'}}>
                    <p className="m-0 text-truncate text-light small">
                        <b>{marker.title ? marker.title : firstTag?.name}</b>
                        <br/>
                        {(marker?.startMilliSecond > 0 ? convertMillisecondsToTime(marker?.startMilliSecond, false): convertSecondsToTime(marker?.startSecond))} - {userNickname}
                    </p>
                    { otherCount >= 0 &&
                    <div className="rounded rounded-pill border-soft d-inline-flex"
                        style={{height:'38px', backgroundColor:firstTag?.color+'66'}}>
                        <IconIndicator iconName={firstTag?.name} iconText={firstTag?.icon} iconColor={firstTag?.color}/>
                        { otherCount > 0 &&
                        <span title={otherDesc} style={{fontWeight:'600'}} className="my-auto text-center text-light pl-2 pr-3">
                            {otherCount}+
                        </span> }
                    </div> }
                </div>
            </div>}
            </div>
        );
    }

    return (
        <div>
        {!uploadingImage && 
        <div 
            key={marker.videoMarkerId}
            ref={el => itemRefs.current[marker.videoMarkerId] = el}
            className={className + " shadow nab-hero position-relative extra-rounded"}
            onClick={() => moveToListener(marker)}
            style={{cursor:'pointer',height:'160px',backgroundSize:'contain',backgroundImage:`url(${backgroundImage})`}}>
            { /*videoProvider?.internalId === Typologies.VT_YOUTUBE &&
                <div title="Miniatura con problemas" className="w-100 position-absolute d-flex extra-rounded" style={{top:0, bottom:0, backgroundColor:'#4D4D4DBB'}}>
                    <img src="assets/imgs/not-image.png" className="w-25 mx-auto my-auto" style={{opacity:'0.77'}} alt="T.T"/>
                </div>*/ }
            <div className="position-absolute w-100 d-flex justify-content-between px-2 py-2" style={{top:0}}>
                { otherCount >= 0 &&
                <div className="rounded rounded-pill border-soft d-inline-flex"
                    style={{height:'38px', backgroundColor:firstTag?.color+'66'}}>
                    <IconIndicator iconName={firstTag?.name} iconText={firstTag?.icon} iconColor={firstTag?.color}/>
                    { otherCount > 0 &&
                    <span title={otherDesc} style={{fontWeight:'600'}} className="my-auto text-center text-light pl-2 pr-3">
                        {otherCount}+
                    </span> }
                </div> }
                { showActionsOnMarkers && <div className="d-inline-flex ml-auto gap-1">
                        <span className="material-icons-round border-soft rounded pt-1 text-center text-light bg-dark"
                            onClick={() => openShowMarker(marker)} 
                            title={t('player_sidebar_markeritem_btn_view_titleattr')} 
                            style={{height:'36px', width:'36px'}}>visibility</span>
                    { (shareType === 'owner' || loggedUser.userId === marker.userId) &&
                        <span className="material-icons-round border-soft rounded pt-1 text-center text-light bg-dark" 
                            onClick={() => startEditMarker(marker)} 
                            title={t('player_sidebar_markeritem_btn_edit_titleattr')} 
                            style={{height:'36px', width:'36px'}}>edit</span> }
                    { (shareType === 'owner' || loggedUser.userId === marker.userId) && 
                        <span className="material-icons-round border-soft rounded pt-1 text-center text-light bg-danger"
                            onClick={() => confirmDeleteMarker(marker)} 
                            title={t('player_sidebar_markeritem_btn_delete_titleattr')} 
                            style={{height:'36px', width:'36px'}}>delete</span> }
                </div>}
            </div>
            <div onClick={() => moveToListener(marker)}
                className="p-2 position-absolute w-100 extra-rounded marker-info"
                style={{zIndex:900, backgroundColor:'#000000AA', bottom:0}}>
                <p className="m-0 text-light small">
                    <b>{marker.title ? marker.title : firstTag?.name}</b>
                    <br/>
                    {(marker?.startMilliSecond > 0 ? convertMillisecondsToTime(marker?.startMilliSecond, false): convertSecondsToTime(marker?.startSecond))} - {userNickname}
                </p>
            </div>
        </div>}
        </div>
    );
}

export { Marker };

