import React, { useEffect, useState } from 'react';
import { GOOGLE_ICONS } from '../utils/GoogleIcons';

function IconIndicator({iconText, iconName, iconColor, iconSize, iconOnClick, className = '', style, asOutline = false}) {

    const [isValidIcon, setIsValidIcon] = useState(false);
    const [containerSize, setContainerSize] = useState('36px');
    const [fontIconSize, setFontIconSize] = useState('1.4em');
    const [fontSize, setFontSize] = useState('1.0em');

    useEffect(() => {
        switch(iconSize) {
            case 'lg': setContainerSize('56px'); setFontIconSize('2.2em'); setFontSize('1.6em'); break;
            case 'md': setContainerSize('36px'); setFontIconSize('1.4em'); setFontSize('1.0em'); break;
            case 'sm': setContainerSize('24px'); setFontIconSize('1.0em'); setFontSize('0.8em'); break;
        }
    }, [iconSize]);

    useEffect(() => {
        const icon = GOOGLE_ICONS.find(ic => ic === iconText);
        if (icon) {
            setIsValidIcon(true);
        } else {
            setIsValidIcon(false);
        }
    }, [iconText]);

    const safeOnClick = (evt) => {
        iconOnClick && iconOnClick(evt);
    }

    return (
        isValidIcon ?
            <div onClick={safeOnClick} title={iconName}
                className={className + ' d-flex icon-indicator rounded-circle ' + (iconOnClick ? 'cursor-pointer' : '')} 
                style={{
                    backgroundColor: asOutline ? 'transparent' : iconColor,
                    border: asOutline ? 'solid 2px ' + iconColor : 'none',minWidth:containerSize,
                    width:containerSize, height:containerSize, ...style
                }}>
                <span className='material-icons-round text-light text-center my-auto mx-auto'
                    style={{fontSize: fontIconSize, color: asOutline?iconColor:'white', cursor:'inherit'}}>
                    {iconText}
                </span>
            </div> :
            <div onClick={safeOnClick} title={iconName}
                className={className + ' icon-indicator rounded-circle d-flex text-center align-middle ' + (iconOnClick ? 'cursor-pointer' : '')}
                style={{
                    backgroundColor: asOutline ? 'transparent' : iconColor,
                    border: asOutline ? 'solid 2px ' + iconColor : 'none',minWidth:containerSize,
                    width:containerSize, height:containerSize, ...style
                }}>
                <span className='mx-auto my-auto text-light'
                    style={{fontSize, color: asOutline?iconColor:'white', cursor:'inherit'}}>
                    {iconText}
                </span>
            </div>
    );
}

function extractIcon(iconText, iconName) {
    const icon = GOOGLE_ICONS.find(ic => ic === iconText);
    if (icon) {
        return icon;
    } else {
        return iconName.split(' ')
            .map(w => w[0]).join('')
            .toUpperCase().substring(0, 2);
    }
}

export { IconIndicator, extractIcon };
