import React, { useContext, useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { listShareDetails } from '../services/VitagShareDetailsService';
import { listMarkerCounts } from '../services/VitagMarkersService';

function ModalDeleteVideo({
  modalTitle="modal_confirm_title", modalText="", modalSize="lg", acceptCallback, showModal, setShowModal,
  customClasses='', okText='modal_confirm_ok', okIcon='check', showOkButton = true,
  cancelText='modal_confirm_cancel', cancelIcon='close', cancelIconRound = true,
  cancelCallback, showCancelButton = true, okIconRound = true, okIconSize = '24px',
  cancelIconSize = '24px', closeFromKeyboard = false, 
  closeOnSuccess = true, translateModalTitle = false, translateModalText = false, 
  translateModalButtons = true, vtgVideo, isMultiple
} ) {

  const { t } = useTranslation();
  const [showSharedVideo, setShowSharedVideo] = useState(false);
  const [deletedShared, setDeletedShared] = useState(false);

  const closeThisModal = () => {
    setShowModal(false);
  }

  const completeAcceptCallback = () => {
    acceptCallback && acceptCallback(deletedShared);
    if (closeOnSuccess) {
        closeThisModal();
    }
  }

  const completeCancelCallback = () => {
      cancelCallback && cancelCallback();
      closeThisModal();
  }

  const handleShared = (evt) => {
    const value = evt.target.checked;
    setDeletedShared(value);
}

  useEffect(() => {
    if (vtgVideo) {
      Promise.all([
        listShareDetails(vtgVideo?.videoId, 'video'), listMarkerCounts(vtgVideo?.hashId)
      ])
      .then(responses => {
          const details = responses[0].data;
          const counts = responses[1].data;
          details.forEach(det => {
              const counter = counts.find(n => n.userId == det.userId);
              det.count = counter?.count ?? 0;
          });
          if (details && details.length > 0) {
            setShowSharedVideo(true);
          } else {
            setShowSharedVideo(false);
          }
      })
    }

    if (isMultiple) {
      setShowSharedVideo(true);
    }
}, [vtgVideo, isMultiple]);

useEffect(() => {
    setDeletedShared(false);
}, [showModal]);

  return (
    <Modal show={showModal} onHide={closeThisModal} size={modalSize}
        backdrop="static" keyboard={closeFromKeyboard}
        dialogClassName={"pt-5 " + customClasses}>
        <Modal.Header className={(! showCancelButton && " pl-5 ")}>
            { showCancelButton ?
            <button onClick={completeCancelCallback} className="btn btn-sm text-muted"
                title={ translateModalButtons ? t(cancelText) : cancelText }>
                { cancelIconRound ? 
                    <span className='material-icons-round' style={{fontSize: cancelIconSize}}>{cancelIcon}</span> :
                    <span className='material-symbols-outlined' style={{fontSize: cancelIconSize}}>{cancelIcon}</span>
                }
            </button> : <span className="btn-placeholder"></span> }
            <Modal.Title className="mx-auto">{ translateModalTitle ? t(modalTitle) : modalTitle }</Modal.Title>
            { showOkButton ?
            <button onClick={completeAcceptCallback} className="btn btn-sm text-vitag"
                title={ translateModalButtons ? t(okText) : okText }>
                { okIconRound ? 
                    <span className='material-icons-round' style={{fontSize:okIconSize}}>{okIcon}</span> :
                    <span className='material-symbols-outlined' style={{fontSize:okIconSize}}>{okIcon}</span>
                }
            </button> : <span className="btn-placeholder"></span> }
        </Modal.Header>
        <Modal.Body className="px-5">
            { !showSharedVideo && 
              <div>
                { modalText && <p className="my-2">{ translateModalText ? t(modalText) : modalText }</p> }
              </div>
            }
            {showSharedVideo && 
              <div > 
                <p className="my-2">{ isMultiple ? t('modal_videos_deletevideos_text_sharedlist') : t('modal_videos_deletevideo_text_sharedlist') }</p>
                <input id='shareWithTime' name='shareWithTime' type='checkbox'
                        className='form-check-input cursor-pointer mb-3'
                        onChange={handleShared} />
                <label htmlFor='shareWithTime' className="form-check-label cursor-pointer">
                        { isMultiple ? t('modal_videos_deletevideos_label_sharedlist') : t('modal_videos_deletevideo_label_sharedlist') }
                </label>                             
              </div>
            }
        </Modal.Body>
    </Modal>);
}

export { ModalDeleteVideo };
