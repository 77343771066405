import axios from 'axios';

function eventsByVideo(videoId, showAll) {
    return axios.get(`/vtg-events/by-video/${videoId}`, {params: {all:showAll, includeOwn:true}});
}

function eventAtVideo(videoId, action) {
    return axios.post(`/vtg-events/at-video/${videoId}/${action}`);
}

const AVAILABLE_ACTIONS = { 
    VIDEO:{
        OPEN:'open'
    }
};

export {
    eventsByVideo, eventAtVideo, AVAILABLE_ACTIONS
};
