import { useEffect, useState } from "react"
import axios from 'axios';

export default function GoogleSignin({loginCallback}) {
    const [gsiScriptLoaded, setGsiScriptLoaded] = useState(false)
    const [user, setUser] = useState(undefined)

    const initializeGsi = () => {
        console.log('Initializing GSI');
        setGsiScriptLoaded(true);
        window.google.accounts.id.initialize({
            client_id: "688308716803-hfhmnfgskogg6uc1k2p121k6vnioqc74.apps.googleusercontent.com",
            callback: (res) => {
                console.log('Login success', res);
                axios.post('/sign-up/with-google', {credential:res.credential})
                    .then(resp => {
                        console.log('Received user:', resp.data);
                        loginCallback(resp.data.leJwt);
                    })
                    .catch(err => {
                        console.error('Error validating user', err);
                    });
            },
        });
        window.google.accounts.id.renderButton(
            document.getElementById("buttonDiv"),
            { theme: "outline", size: "large" }
        )
    }

    useEffect(() => {
        const script = document.createElement("script")
        script.src = "https://accounts.google.com/gsi/client"
        script.onload = initializeGsi
        script.async = true
        script.id = "google-client-script"
        document.querySelector("body")?.appendChild(script)
        return () => {
            window.google?.accounts.id.cancel()
            document.getElementById("google-client-script")?.remove()
        }
    }, []);


    return <div id="buttonDiv"></div>

}

