import React, { useCallback, useState } from "react";
import { CustomToastContainer } from "./CustomToastContainer";

export const CustomToastContext = React.createContext(null);
let id = 0;

export const CustomToastProvider = ({ children }) => {
    const [toasts, setToasts] = useState([]);

    const addToast = useCallback(content => {
        setToasts(toasts => [
            ...toasts, { id: id++, content }
        ]);
    }, [setToasts]);

    const removeToast = useCallback(id => {
        setToasts(toasts => toasts.filter(t => t.id !== id));
    }, [setToasts]);

    return (
        <CustomToastContext.Provider value={{ addToast, removeToast }}>
          <CustomToastContainer toasts={toasts} />
          {children}
        </CustomToastContext.Provider>
    );
}
