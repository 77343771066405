import axios from 'axios';
import { compressImage } from '../utils/ImageUtils';
import { getLoggedUser } from './AuthorizarionService';
import { config } from '../config';

function listTypologiesByInternalId(parentInternalId) {
    const user = getLoggedUser();
    const cliId = user == null ? -26 : user.cliId;
    return axios.get('/typologies/', { params: { cliId, parent:parentInternalId } });
}

function getTypologyByInternalId(internalId) {
    const user = getLoggedUser();
    const cliId = user == null ? -26 : user.cliId;
    return axios.get('/typologies/find-by', { params: { cliId, internalId } });
}

function getPermissionsByUser() {
    const user = getLoggedUser();
    const userId = user == null ? -26 : user.userId;
    const cliId = user == null ? -26 : user.cliId;
    const ownId = user == null ? -26 : user.ownId;
    return axios.get('/permissions/user-permissions', { params: { cliId, ownId, userId } });
}

function listAllIcons() {
    return axios.get('/icons');
}

function createIcon(newIcon) {
    return axios.post('/icons', newIcon, { params: { personal_icon:true } });
}

function importUsers(users) {
    return axios.post('/users/import', { users });
}

function signUpUser(user, hash = '') {
    return axios.post('/sign-up', user, { params: { hash } });
}

function isEmailUsed(email) {
    return axios.get('/sign-up/email-used', { params: { email } });
}

function checkOrganizationCode(code) {
    return axios.get('/sign-up/check-code', { params: { code } });
}

function checkInvitationHash(hash) {
    return axios.get('/sign-up/check-invitation', { params: { hash } });
}

function verificateAccount(token) {
    return axios.get('/verificate/account', { params: { token } });
}

function startRecoveryPassword(email) {
    return axios.post('/recovery/reset-password', {}, { params: { email } });
}

function verificateRecoveryPassword(token) {
    return axios.get('/recovery/verificate', { params: { token } });
}

function changePasswordFromRecovery(userId, token, newPassword) {
    return axios.post('/recovery/change-password', { userId, token, newPassword });
}

function changeMyPassword(originalPassword, newPassword) {
    return axios.put('/users/change-my-password', { originalPassword, newPassword });
}

function getProfileInfo() {
    return axios.get('/users/my-profile');
}

function getProfileById(userId, includeStats = false) {
    return axios.get(`/users/profile/${userId}`, {params:{includeStats}});
}

function getProfileAccess() {
    return axios.get('/access/my');
}

function leaveAccess(contextId) {
    return axios.delete(`/access/leave/${contextId}`);
}

function changeProfileInfo(request) {
    return axios.put('/persons/my-profile', request);
}

function uploadImageToS3(imageBase64, options = {compressBeforeUpload: true, awaitUpload: false}) {
    if(config.IS_BETA) console.log('Uploading image to S3');
    if(! options.compressBeforeUpload) {
        return axios.post('/utils/v2/persist-image', {imageDataUri:imageBase64, awaitUpload:options.awaitUpload});
    }
    if(config.IS_BETA) console.log('Compress image before upload');
    let promiseCompress = compressImage(imageBase64);
    return promiseCompress.then(compressedImage => {
        return axios.post('/utils/v2/persist-image', {imageDataUri:compressedImage, awaitUpload:options.awaitUpload});
    });
}

function getAvailableLanguages() {
    return axios.get('/public/langs');
}

function listMyManualTokens() {
    return axios.get('/tokens/my-manual-tokens');
}

function createManualToken() {
    return axios.post('/tokens', {});
}

function getParameterByName(name) {
    const params = new URLSearchParams();
    params.append('name', name);
    return axios.get(`/parameters/find?${params.toString()}`);
}

export {
    changeMyPassword, getParameterByName, changePasswordFromRecovery, changeProfileInfo, checkInvitationHash, checkOrganizationCode, createIcon, createManualToken, getAvailableLanguages, getPermissionsByUser, getProfileAccess, getProfileById, getProfileInfo, getTypologyByInternalId, importUsers, isEmailUsed, leaveAccess, listAllIcons, listMyManualTokens, listTypologiesByInternalId, signUpUser, startRecoveryPassword, uploadImageToS3, verificateAccount, verificateRecoveryPassword
};

