import React, { useContext, useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { GeneralContext } from '../contexts/general-context';
import { ModalChangePassword } from '../modals/ModalChangePassword';
import { doLogout } from '../services/AuthorizarionService';
import { changeProfileInfo, uploadImageToS3 } from '../services/CoreService';
import { cancelStripeSubscription, createSubscription, downloadInvoice } from '../services/VitagSubscriptionsService';
import { defaultNoProfilePicture } from '../utils/ImageUtils';
import { Typologies } from '../utils/TypologiesEnum';
import { config } from '../config';
import { ModalPaymentMethods } from '../modals/ModalPaymentMethods';
import { ConfirmModal } from "../general/ConfirmModal";
import { useCustomToast } from '../utils/toasts/useCustomToast';
import Moment from 'moment';

function ProfileTabMainInfo() {

    const [editingProfile, setEditingProfile] = useState(false);
    const [showPaymentMethods, setShowPaymentMethods] = useState(false);
    const [showCancelSubscriptionModal, setShowCancelSubscriptionModal] = useState(false);
    const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);

    const [showStripeConnectModal, setShowStripeConnectModal] = useState(false);
    const [editedProfile, setEditedProfile] = useState();
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
    const { profile, loadProfileFromBackend, subs, isProUser, currentContext } = useContext(GeneralContext);
    const history = useHistory();
    const { t } = useTranslation();
    const { addToast } = useCustomToast();
    const [latestSubscription, setLatestSubscription] = useState('');
    useEffect(() => {
        setEditedProfile(profile);
    }, [profile]);

    useEffect(() => {
        if (subs && subs.length > 0) {
            const latest = subs.reduce((latestSub, currentSub) => {
                const latestStartDate = new Date(latestSub.startDate);
                const currentStartDate = new Date(currentSub.startDate);
                return currentStartDate > latestStartDate ? currentSub : latestSub;
            }, subs[0]);

            setLatestSubscription(latest);
        }
    }, [subs]);

    const saveProfileChanges = () => {
        const request = {
            firstName: editedProfile?.firstName,
            lastName: editedProfile?.lastName,
            avatarImage: editedProfile?.avatarImage
        };
        changeProfileInfo(request)
            .then(_ => {
                loadProfileFromBackend();
                setEditingProfile(false);
            })
            .catch(err => {
                if(config.IS_BETA) console.error('Error saving profile...', err)
        });
    }

    const logout = () => {
        doLogout();
        history.replace('/login');
    }

    const handleFileSelection = (event) => {
        const imageFile = event.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            setEditedProfile({...editedProfile, avatarImage: reader.result});
            uploadImageToS3(reader.result)
                .then(resp => {
                    const s3Url = resp.data.imageUrl;
                    setEditedProfile({...editedProfile, avatarImage: s3Url});
                })
                .catch(err => {
                    if(config.IS_BETA) console.error('Impossible save image...', err)
                });
        }
        reader.readAsDataURL(imageFile);
    }

    const putFallbackImage = () => {
        setEditedProfile({...editedProfile, avatarImage: defaultNoProfilePicture});
    }

    const handleStripeConnect = () => {
        
    }

    const handleDownloadInvoice = () => {
        downloadInvoice(latestSubscription?.stripeSubscriptionId)
            .then(url => { 
                if (url) {
                    window.open(url.data, '_blank');
                }
            })
            .catch(error => {
                if(config.IS_BETA) console.error("Error downloading invoice:", error);
            });
    }

    const getConnectColor = (status) => {
        switch(status) {
          case 'PENDING': return '#aab7b8';
          case 'REGISTERED': return '#2ecc71';
          case 'IN_PROGRESS': return '#f5b041';
        }
        
        return '#e74c3c';
    }

    const getConnectIcon = (status) => {
        switch(status) {
            case 'PENDING': return 'cancel';
            case 'REGISTERED': return 'check_circle';
            case 'IN_PROGRESS': return 'pending';
        }
      
        return 'error';
    }

    const showConnectOption = (status, option) => {
        if(option === 'ADD' && 
          (status === 'PENDING' || status === null || status === undefined)) {
            return true;
        }
        if(option === 'COMPLETE' && status === 'IN_PROGRESS') {
          return true;
        }
        if(option === 'REGISTERED' && status === 'REGISTERED') {
          return true;
        }
    
        return false;
    }

    const formatDate = (date) => {
        return Moment(date).format('YYYY-MM-DD');
    }

    const handleCancelSubscription = () => {
        if (latestSubscription) {

            if (latestSubscription.tpUserAccountType.internalId === Typologies.UAT_PROFESSIONAL 
                && latestSubscription.tpStatus.internalId === Typologies.ACTIVE) {

                createSubscription(false,'DEFAULT', currentContext?.organizationId)
                    .then(r => { 
                        addToast({
                            header: t('page_videos_toastsucces_cancelssubscription_title'),
                            body: t('page_videos_toastsucces_cancelssubscription_body'),
                            variant: 'success'
                        });
                        setShowCancelSubscriptionModal(false);
                        setTimeout(() => window.location.reload(), 2500);
                    });
                
            }
        }
    }

    return <>
        <div className="w-100 p-2 p-md-3">
            <div className="position-relative">
                <img className="position-relative profile-image text-center d-block align-middle mx-auto mb-4" 
                src={editedProfile?.avatarImage} onError={putFallbackImage}/>
                {isProUser && <span className="badge-main">PRO</span>}    
            </div>
            
            <p className="mb-1 text-center"><b>{editedProfile?.nickname}</b></p>
            { ! editingProfile &&
            <React.Fragment>
                <p className="text-center mb-1">{editedProfile?.fullName}</p>
                <p className="text-center small">{editedProfile?.email}</p>
                {latestSubscription && isProUser && 
                <p className="text-center">
                    {t('page_profile_section_personalinfo_text_subscription', 
                                {startDate:formatDate(latestSubscription.startDate),
                                 thruDate: formatDate(latestSubscription.endDate)
                                })}
                <a onClick={() => setShowSubscriptionModal(true)} className='subtitle-1 ml-3 fw-bold connect-link text-dark cursor-pointer'>{t('page_profile_section_personalinfo_text_showmore_subscriptioninfo')}</a>
                </p>}
            </React.Fragment> }

            { editingProfile &&
                <div className="my-3 mx-auto row">
                    <Form.Group controlId="profileFirstName" className='col-lg-4'>
                        <Row>
                            <Col xs={3} className='my-auto'>
                                <Form.Label className="text-right mb-0">
                                    { t('page_profile_section_personalinfo_label_name') }
                                </Form.Label>
                            </Col>
                            <Col>
                                <Form.Control type="text" value={editedProfile?.firstName}
                                    onChange={(evt) => setEditedProfile({...editedProfile, firstName:evt.target.value})}/>
                            </Col>
                        </Row>
                    </Form.Group>
                    <Form.Group controlId="profileLastName" className='col-lg-4'>
                        <Row>
                            <Col xs={3} className='my-auto'>
                                <Form.Label className="text-right mb-0">
                                    { t('page_profile_section_personalinfo_label_lastname') }
                                </Form.Label>
                            </Col>
                            <Col>
                                <Form.Control type="text" value={editedProfile?.lastName}
                                    onChange={(evt) => setEditedProfile({...editedProfile, lastName:evt.target.value})}/>
                            </Col>
                        </Row>
                    </Form.Group>
                    <Form.Group controlId="profileEmail" className='col-lg-4'>
                        <Row>
                            <Col xs={3} className='my-auto'>
                                <Form.Label className="text-right mb-0">
                                    { t('page_profile_section_personalinfo_label_email') }
                                </Form.Label>
                            </Col>
                            <Col>
                                <Form.Control type="email" value={editedProfile?.email} readOnly={true}
                                    onChange={(evt) => setEditedProfile({...editedProfile, email:evt.target.value})}/>
                            </Col>
                        </Row>
                    </Form.Group>
                    <div className="col-12">
                        <div className="row col-custom-file">
                            <div className="col-lg-4 mx-auto">
                                <input onChange={handleFileSelection} type="file" accept="image/*" className="mx-3 custom-file-input" id="profileImage"/>
                                <label className="mx-3 custom-file-label" style={{textAlign:'left'}} htmlFor="profileImage">
                                { t('page_profile_section_personalinfo_label_avatarpicture') }
                                </label>
                            </div>
                        </div>
                    </div>
                </div> }

            <div className="d-flex flex-column mx-auto" style={{maxWidth:'420px'}}>
            { ! editingProfile && <>
                <button className="btn btn-outline-dark mb-3" onClick={() => setEditingProfile(true)}>
                    { t('page_profile_section_personalinfo_button_editprofile') }
                </button>
                <button className="btn btn-outline-dark mb-3" onClick={() => setShowPaymentMethods(true)}>
                    { t('page_profile_section_personalinfo_button_paymentmethods')}
                </button>
                {false && <button className="btn btn-outline-dark mb-3" onClick={() => setShowStripeConnectModal(true)}>
                    {'STRIPE CONNECT'}
                </button>}
                <button className="btn btn-outline-dark mb-3" onClick={() => setShowChangePasswordModal(true)}>
                    { t('page_profile_section_personalinfo_button_changepassword') }
                </button>
                <button className="btn btn-outline-danger mb-3" onClick={logout}>
                    { t('page_profile_section_personalinfo_button_logout') }
                </button>
            </> }
            { editingProfile && <>
                <button className="btn btn-outline-dark mb-3" onClick={saveProfileChanges}
                    disabled={editedProfile?.firstName?.length === 0 || editedProfile?.lastName?.length === 0 ? 'disabled' : ''}>
                    { t('page_profile_section_personalinfo_button_saveprofile') }
                </button>
                <button className="btn btn-secondary mb-3" onClick={() => setEditingProfile(false)}
                    disabled={editedProfile?.firstName?.length === 0 || editedProfile?.lastName?.length === 0 ? 'disabled' : ''}>
                    { t('general_cancel') }
                </button>
            </> }
            </div>
        </div>
        <ModalChangePassword
            showModal={showChangePasswordModal}
            setShowModal={setShowChangePasswordModal} />
        <ModalPaymentMethods
            showModal={showPaymentMethods}
            setShowModal={setShowPaymentMethods}
        />
        <ConfirmModal
            modalTitle={t('modal_profile_cancelsubscription_title')}
            acceptCallback={handleCancelSubscription}
            showModal={showCancelSubscriptionModal}
            setShowModal={setShowCancelSubscriptionModal}
            showCancelButton={true}
            zIndexCustom={1070}>
                <p className='mt-3'>
                    {t('modal_profile_cancelsubscription_body')}
                </p>
        </ConfirmModal>
        <ConfirmModal
            modalTitle={t('page_profile_section_personalinfo_text_subscription_modal_title')}
            acceptCallback={handleDownloadInvoice}
            showModal={showSubscriptionModal}
            setShowModal={setShowSubscriptionModal}
            showCancelButton={true}
            showOkButton={latestSubscription.stripeSubscriptionId !== null && latestSubscription.stripeSubscriptionId !== undefined}
            modalSize={'lg'}
            okIcon={'download'}>
                <div class="my-4 m-lg-0 col-12 col-lg-12">
                    <table className="table table-sm">
                        <tbody>
                            <tr className='fs-lg text-center'>
                                <td className='text-left'>{t('page_profile_section_personalinfo_text_subscription_modal_currentplan')}</td>
                                <td className='text-left fw-bold'>{t('modal_updatepro_subscription_title')}</td>
                                <td className='text-right'>
                                    <a onClick={() => setShowCancelSubscriptionModal(true)} class="col text-right vitag-text col-lg-3 cursor-pointer">
                                        {t('page_profile_section_personalinfo_button_cancelsubscription')}
                                    </a>
                                </td>
                            </tr>
                            <tr className='fs-lg text-center'>
                                <td className='text-left'>{t('page_profile_section_personalinfo_text_subscription_modal_planstatus')}</td>
                                <td className='text-left fw-bold'>
                                    {t('page_profile_section_personalinfo_text_subscription_modal_planstatus_date', 
                                        {thruDate: formatDate(latestSubscription.endDate)})}
                                </td>
                            </tr>
                            <tr className='fs-lg text-center'>
                                <td className='text-left'>{t('page_profile_section_personalinfo_text_subscription_modal_renewal')}</td>
                                <td className='text-left fw-bold'>{t('page_profile_section_personalinfo_text_subscription_modal_renewal_auto')}</td>
                            </tr>
                        </tbody>
                    </table> 
                </div>
        </ConfirmModal>
        <ConfirmModal
            modalTitle={'Stripe connect'}
            acceptCallback={handleDownloadInvoice}
            showModal={showStripeConnectModal}
            setShowModal={setShowStripeConnectModal}
            showCancelButton={true}>
                <p className='mt-3'>
                Para recibir pagos en nuestra plataforma, es necesario que conectes tu cuenta a Stripe Connect. Esta integración te permitirá gestionar y recibir tus ingresos de manera segura y confiable, además de cumplir con los requisitos de procesamiento de pagos. Conectar tu cuenta es rápido y sencillo, y te permitirá recibir los pagos directamente en tu cuenta bancaria.
                </p>
                <div class="my-4 m-lg-0 col-12 col-lg-12">
                    <div class="d-flex">
                            <span class="material-icons-round" style={{color: getConnectColor(profile?.stripeConnectStatus)}}>
                                {getConnectIcon(profile?.stripeConnectStatus)}
                            </span>
                            {showConnectOption(profile?.stripeConnectStatus, 'REGISTERED') && 
                            <p class="subtitle-1 ml-2 fw-bold">
                                Stripe Connect completado
                            </p>}
                            {showConnectOption(profile?.stripeConnectStatus, 'ADD') && 
                            <a class="subtitle-1 ml-2 fw-bold connect-link text-dark">
                                Stripe Connect incompleto
                            </a>}
                            {showConnectOption(profile?.stripeConnectStatus, 'COMPLETE') && 
                            <a class="subtitle-1 ml-2 fw-bold connect-link text-dark">
                                Stripe Connect en progreso
                            </a>}
                    </div>
                </div>
        </ConfirmModal>
    </>
}

export { ProfileTabMainInfo }
