import React, { useState } from 'react';
import { useEffect } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { EmptyPanel } from '../general/EmptyPanel'
import { listShareDetails, stopShare } from '../services/VitagShareDetailsService';
import { listMarkerCounts } from '../services/VitagMarkersService';
import { config } from '../config';

function ModalSharedList({ successListener, showModal, setShowModal, vtgVideo, vtgTag, videoIds }) {

    const [modalTitle, setModalTitle] = useState('Compartir');
    const [shareDetails, setShareDetails] = useState([]);

    const { t } = useTranslation();

    useEffect(() => {

    }, [])

    const initModal = () => {
        if (vtgVideo) {
            if(config.IS_BETA) console.log('Sharing video', vtgVideo);
            setModalTitle(t('modal_sharecontrol_title_sharedlistvideo'));
        }
        if (vtgTag) {
            setModalTitle(t('modal_sharecontrol_title_sharedlisttag'));
        }
        searchShareDetails();
    };

    const searchShareDetails = () => {
        if (vtgTag) {
            listShareDetails(vtgTag.tagId, 'tag')
                .then(resp => setShareDetails(resp.data))
                .catch(_ => {
                    if(config.IS_BETA) console.error('Impossible getting share tag details')
                });
            return;
        }
        if (vtgVideo) {
            Promise.all([
                listShareDetails(vtgVideo.videoId, 'video'), listMarkerCounts(vtgVideo.hashId)
            ])
            .then(responses => {
                const details = responses[0].data;
                const counts = responses[1].data;
                details.forEach(det => {
                    const counter = counts.find(n => n.userId == det.userId);
                    det.count = counter?.count ?? 0;
                });
                setShareDetails(details);
            })
            .catch(_ => {
                if(config.IS_BETA) console.error('Impossible getting share video details')
            });

            return;
        }
        if(config.IS_BETA) console.error('Not available for multiple videos');
    }

    const stopSharing = (shareDetail, position) => {
        const copyOfItems = [...shareDetails];
        copyOfItems.splice(position, 1);
        setShareDetails(copyOfItems);
        stopShare(shareDetail.sharedElementId)
            .then(_ => {
                if(config.IS_BETA) console.log('Sharing stop successful')
            })
            .catch(_ => {
                if(config.IS_BETA) console.error('Impossible stop sharing')
            });
    }


    const closeModal = () => {
        setShowModal(false);
    }


    return (
        <>
        <Modal show={showModal} onShow={initModal} onHide={closeModal} size="xl">
            <Modal.Header>
                <button onClick={closeModal} className="btn btn-sm"
                    title={t('modal_sharecontrol_cancel')}>
                    <span className="material-icons-round">close</span>
                </button>
                <div className='d-flex flex-column mx-auto'>
                    <Modal.Title>{modalTitle}</Modal.Title>
                    <span className="text-muted text-center mx-auto">
                        {vtgVideo?.title || vtgTag?.name || t('modal_sharecontrol_subtitle_selectedvideos', {n:videoIds?.length})}
                    </span>
                </div>
            </Modal.Header>
            <Modal.Body className='px-4 pb-4'>
                <Row className='pb-3'>
                <Col xs={12} sm={12} >
                        <Row className='px-3'>
                            <div className='my-3 w-100 mx-3'>
                                <span className="d-inline text-muted">
                                    {t('modal_sharecontrol_section_sharingwith')}
                                </span>
                            </div>
                            <Col sm={12} className='px-3 pb-3'>
                                { shareDetails.length === 0 &&
                                    <EmptyPanel extraClass="mx-0" message={t('modal_sharecontrol_message_nosharing')}/>}
                                { shareDetails.length > 0 && <div className='row' style={{overflowY:'scroll'}}>
                                    { shareDetails.map((item, i) =>
                                    <div key={item.sharedElementId} className="col-12 col-sm-6 mb-2">
                                        <div className="d-flex justify-content-between align-items-center border rounded p-2">
                                            <div className="d-flex align-items-center pl-1">
                                                <div className="nab-circle-btn mx-0" style={{flexShrink:'0'}}>
                                                    <span className="material-icons-round">{item.isUser ? 'person' : 'groups'}</span>
                                                </div>
                                                <div className="mx-2">
                                                    <p className="m-0 font-weight-bold">
                                                        {item.userFullName || item.userNickname || item.groupName}
                                                    </p>
                                                    <p className="m-0 text-muted">
                                                        {item.onlyRead && t('general_sharevideo_readonly')} 
                                                        {item.individualEdit && t('general_sharevideo_individualedit')} 
                                                        {item.collaborativeEdit && t('general_sharevideo_collaborativeedit')} 
                                                        {item.count > 0 && <>
                                                            <span> +{item.count} <span className='material-icons-round small'>bookmarks</span></span>
                                                        </> }
                                                    </p>
                                                </div>
                                            </div>
                                            <button onClick={() => stopSharing(item, i)} title={t('modal_sharecontrol_button_stopsharing_title')}
                                                className="btn btn-sm btn-outline-danger border-0 pt-1 pb-0">
                                                <span className="material-icons-round">cancel</span>
                                            </button>
                                        </div>
                                    </div>) }
                                </div>}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                
                {/**/}
            </Modal.Body>
        </Modal>
        </>
    );
}

export { ModalSharedList };
