import Papa from 'papaparse';
import { convertSecondsToTime, convertMillisecondsToTime } from './NabUtils';

function downloadMarkersAsCSV(filename, markers) {
    const data = markers.map(mk => {
        const mkAsArray = [];
        mkAsArray.push(mk.startMilliSecond > 0 ? convertMillisecondsToTime(mk.startMilliSecond) : convertSecondsToTime(mk.startSecond));
        mkAsArray.push(convertMillisecondsToTime(mk.startOffset + mk.endOffset));
        mkAsArray.push(mk.title);
        mkAsArray.push(mk.description.replace(/\n/gi, ''));
        mkAsArray.push((mk.markerTags || [])
            .map(mt => mt.vtgTag.name)
            .join(','));
        return mkAsArray;
    });
    createAndDownloadCSV(filename, COLUMNS_TEXT_MARKERS_CSV, data);
}

function downloadFile(filename, csvText) {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvText));
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
}

function createAndDownloadCSV(filename, headers, content) {
    const csvText = Papa.unparse(
        { fields:headers, data:content },
        { quotes:true, delimiter:',' }
    );
    downloadFile(filename, csvText);
}

const COLUMNS_MARKERS_CSV = {
    TIME:0, TITLE:1, DESCRIPTION:2, TAGS:3
};

const COLUMNS_TEXT_MARKERS_CSV = [
    'Time', 'Duration', 'Title', 'Description', 'Tags'
];

export {
    COLUMNS_MARKERS_CSV, COLUMNS_TEXT_MARKERS_CSV,
    downloadMarkersAsCSV, createAndDownloadCSV
};
