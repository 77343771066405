import React, { useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { Row, Modal, Col, Tabs, Tab } from 'react-bootstrap';
import { IconIndicator } from '../general/IconIndicator';
import { useTranslation } from 'react-i18next';
import { configToOpenLinks } from '../utils/CKEditorConfig';
import { CommentsPanel } from '../general/CommentsPanel';

function ModalViewMarker({
        currentMarker, currentThumbnail, currentTimeAsText, showModal, setShowModal
    } ) {

    const [title, setTitle] = useState('');
    const { t } = useTranslation();

    const initModal = () => {
        if (currentMarker && currentMarker.title) {
            setTitle(currentMarker.title);
        } else {
            setTitle(t('modal_markers_showmarker_text_notitle'));
        }
    };

    const closeThisModal = () => setShowModal(false);

    return (
    <Modal show={showModal} onShow={initModal} onHide={closeThisModal} size="xl">
        <Modal.Header>
            <button onClick={closeThisModal}
                title={t('general_close')}
                className="btn btn-sm text-secondary">
                <span className="material-icons-round">close</span>
            </button>
            <div className='d-flex flex-column px-4'>
                <Modal.Title>{t('modal_markers_showmarker_title')}</Modal.Title>
                <span className="text-muted text-center mx-auto">
                    {title}
                </span>
            </div>
            <span className="btn-placeholder"></span>
        </Modal.Header>
        <Modal.Body className='px-5 pb-4'>
            <Row>
                <Col xs={12} sm={4} className="pt-3">
                    <img src={currentThumbnail} alt="#" className="d-block border rounded w-100 mx-auto" style={{maxHeight:'160px',objectFit:'contain',backgroundColor:'#ececec'}}></img>
                    <span className="bg-dark text-light font-weight-bold p-2 position-absolute" style={{top:135}}>{currentTimeAsText}</span>
                </Col>
                <Col xs={12} sm={8} className="pt-3">
                    <div className="w-100 mb-3">                        
                        <label className="text-muted w-100">
                            {t('modal_markers_showmarker_label_selectedtags')}
                        </label>
                        <div className="w-100 py-1 px-2" style={{minHeight:'50px'}}>
                            { currentMarker?.markerTags?.map(mt => {
                                const tag = mt.vtgTag;
                                return(
                                <div key={tag.tagId} className="border-0 rounded rounded-pill d-inline-flex pr-2 mr-2 my-1 align-middle"
                                    style={{backgroundColor:tag.color + '99'}}>
                                    <IconIndicator iconText={tag.icon} iconColor={tag.color}></IconIndicator>
                                    <span className="text-light ml-1 mr-2 my-auto">{tag.name}</span>
                                </div>)}
                            )}
                        </div>
                    </div>
                    <Tabs defaultActiveKey="description">
                        <Tab eventKey="description" title={t('modal_markers_showmarker_title_description')}>
                            <div className="w-100 px-3 border-left border-right border-bottom">
                                <CKEditor
                                    className="form-control"
                                    style={{minHeight:'160px'}}
                                    config={configToOpenLinks}
                                    editor={DecoupledEditor}
                                    onReady={ editor => {
                                        editor.isReadOnly = true;
                                        editor.ui.view.toolbar.element.style.display = 'none';
                                        editor.ui.view.editable.element.style.border = 'none';
                                        editor.ui.view.editable.element.style.padding = '0';
                                    }}
                                    data={currentMarker?.description || '<i>'+ t('modal_markers_showmarker_text_nodescription') +'</i>'} />
                            </div>
                        </Tab>
                        <Tab eventKey="comments" title={t('modal_markers_showmarker_title_comments')}>
                            <div className="border-bottom border-left border-right px-3">
                                <CommentsPanel videoMarkerId={currentMarker?.videoMarkerId}/>
                            </div>
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
        </Modal.Body>
    </Modal>);
}

export { ModalViewMarker };