import React, { useState } from 'react';
import { Row, Modal, Col, Form, InputGroup, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { changeMyPassword } from '../services/CoreService';
import { useCustomToast } from '../utils/toasts/useCustomToast';
import { config } from '../config';

function ModalChangePassword({ showModal, setShowModal }) {

    const { t } = useTranslation();
    const { addToast } = useCustomToast();
    const [showPass, setShowPass] = useState(false);
    const [validForm, setValidForm] = useState(false);
    const [working, setWorking] = useState(false);
    const [passwordRequest, setPasswordRequest] = useState(
        {originalPassword:'',newPassword:'',newPasswordConfirm:''}
    );

    const initModal = () => {
        setPasswordRequest({originalPassword:'',newPassword:'',newPasswordConfirm:''});
        setValidForm(false);
        setShowPass(false);
        setWorking(false);
    }
    const validateForm = (newPasswordRequest) => {
        setValidForm(
            newPasswordRequest.originalPassword.length > 0 &&
            newPasswordRequest.newPassword.length > 0 &&
            newPasswordRequest.newPasswordConfirm.length > 0 &&
            newPasswordRequest.newPassword === newPasswordRequest.newPasswordConfirm
        );
        setPasswordRequest(newPasswordRequest);
    }
    const tryToChangePassword = () => {
        setWorking(true);
        changeMyPassword(passwordRequest.originalPassword, passwordRequest.newPassword)
            .then(_ => {
                if(config.IS_BETA) console.log('Your password successful change');
                addToast({
                    header:t('toast_changepassword_success_title'),
                    body:t('toast_changepassword_success_body'),
                    variant:'success'
                });
                closeThisModal();
            })
            .catch(err => {
                if(config.IS_BETA) console.error('Error changing your password', err);
                addToast({
                    header:t('toast_changepassword_error_title'),
                    body:t('toast_changepassword_error_body'),
                    variant:'error'
                });
            })
            .finally(() => setWorking(false));
    }
    const handleOriginalPasswordChange = evt => validateForm({...passwordRequest, originalPassword:evt.target.value});
    const handleNewPasswordChange = evt => validateForm({...passwordRequest, newPassword:evt.target.value});
    const handleNewPasswordConfirmChange = evt => validateForm({...passwordRequest, newPasswordConfirm:evt.target.value});
    const triggerShowPass = () => setShowPass(! showPass);
    const closeThisModal = () => setShowModal(false);

    return (
    <Modal show={showModal} onShow={initModal} onHide={closeThisModal} size="lg">
        <Modal.Header>
            <button onClick={closeThisModal} title={t('general_close')} className="btn btn-sm text-secondary">
                <span className="material-icons-round">close</span>
            </button>
            <Modal.Title>{t('modal_changepassword_title')}</Modal.Title>
            <button onClick={tryToChangePassword} disabled={! validForm}
                title={t('modal_changepassword_btn_savenewpassword_titleattr')}
                className="btn btn-sm text-vitag">
                <span className="material-icons-round">vpn_key</span>
            </button>
        </Modal.Header>
        <Modal.Body className='px-5 py-4'>
            <Form noValidate>
                <input type="text" id="fake-user" className='d-none'/>
                <input type="password" id="fake-pass" className='d-none'/>
                <Form.Group as={Row}>
                    <Form.Label column xs={12} sm={5}>
                        {t('modal_changepassword_label_originalpassword')}
                    </Form.Label>
                    <InputGroup as={Col} xs={12} sm={7} hasValidation>
                        <Form.Control id='original-password' required readOnly={working}
                            placeholder={t('modal_changepassword_placeholder_originalpassword')}
                            isInvalid={passwordRequest.originalPassword.length === 0}
                            autoComplete='off' type={showPass ? 'text' : 'password'}
                            value={passwordRequest.originalPassword} onChange={handleOriginalPasswordChange}/>
                        <InputGroup.Append onClick={triggerShowPass}>
                            <InputGroup.Text>
                                <span className='material-icons-round'>visibility</span>
                            </InputGroup.Text>
                        </InputGroup.Append>
                        <Form.Control.Feedback type='invalid'>
                            {t('general_fieldrequired')}
                        </Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column xs={12} sm={5}>
                        {t('modal_changepassword_label_newpassword')}
                    </Form.Label>
                    <Col xs={12} sm={7}>
                        <Form.Control id='new-password' required readOnly={working}
                            placeholder={t('modal_changepassword_placeholder_newpassword')}
                            isInvalid={passwordRequest.newPassword.length === 0 || passwordRequest.newPassword !== passwordRequest.newPasswordConfirm}
                            isValid={passwordRequest.newPassword.length > 0 && passwordRequest.newPassword === passwordRequest.newPasswordConfirm}
                            autoComplete='new-password' type={showPass ? 'text' : 'password'}
                            value={passwordRequest.newPassword} onChange={handleNewPasswordChange}/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column xs={12} sm={5}>
                        {t('modal_changepassword_label_newpasswordconfirm')}
                    </Form.Label>
                    <Col xs={12} sm={7}>
                        <Form.Control id='confirm-password' required readOnly={working}
                            placeholder={t('modal_changepassword_placeholder_newpasswordconfirm')}
                            isInvalid={passwordRequest.newPasswordConfirm.length === 0 || passwordRequest.newPassword !== passwordRequest.newPasswordConfirm}
                            isValid={passwordRequest.newPassword.length > 0 && passwordRequest.newPassword === passwordRequest.newPasswordConfirm}
                            autoComplete='new-password' type={showPass ? 'text' : 'password'}
                            value={passwordRequest.newPasswordConfirm} onChange={handleNewPasswordConfirmChange}/>
                        <Form.Control.Feedback type='invalid'>
                            {t('modal_changepassword_feedback_passwordsnotmatch')}
                        </Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Alert variant='info' className='mt-4'>
                    {t('modal_changepassword_alert_passwordquality')}
                </Alert>                
            </Form>
        </Modal.Body>
    </Modal>);
}

export { ModalChangePassword };