import React, { useState } from 'react';
import { Dropdown, Modal, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IconIndicator } from '../general/IconIndicator';
import { queryVideoSettings, saveVideoSettings, startGestureAnalysis } from '../services/VitagVideosService';
import { useCustomToast } from '../utils/toasts/useCustomToast';

function ModalGesturesAnalyzer({showModal, setShowModal, availableTags, vtgVideo}) {

    const { t } = useTranslation();
    const [tagForThumbUp, setTagForThumbUp] = useState();
    const [tagForThumbDown, setTagForThumbDown] = useState();
    const [tagForOpenHand, setTagForOpenHand] = useState();
    const [tagForVictorySign, setTagForVictorySign] = useState();
    const [loading, setLoading] = useState(false);
    const [currentVideoSettings, setCurrentVideoSettings] = useState();
    const { addToast } = useCustomToast();

    const initModal = () => {
        setLoading(false);
        queryVideoSettings(vtgVideo.videoId)
            .then(resp => {
                const settings = resp.data || {videoId:vtgVideo.videoId};
                console.log('Current video settings:', settings);
                setCurrentVideoSettings(settings);
                setTagForThumbUp(searchTagInDepth(settings.tagIdForThumbUp));
                setTagForThumbDown(searchTagInDepth(settings.tagIdForThumbDown));
                setTagForOpenHand(searchTagInDepth(settings.tagIdForOpenHand));
                setTagForVictorySign(searchTagInDepth(settings.tagIdForVictorySign));
            })
            .catch(err => {
                console.error('Error getting video settings', err);
                setCurrentVideoSettings({videoId:vtgVideo.videoId});
            });
    }

    const searchTagInDepth = (tagId) => {
        if (! tagId) {
            return undefined;
        }
        for (const tag of availableTags) {
            if (tag.tagId == tagId) {
                return tag;
            }
            const tagInSubTags = (tag.subTags || []).find(st => st.tagId == tagId);
            if (tagInSubTags) {
                return tagInSubTags;
            }
        }
    }

    const closeThisModal = () => {
        setShowModal(false);
    }

    const startAnalysis = () => {
        currentVideoSettings.videoId = vtgVideo.videoId;
        currentVideoSettings.tagIdForThumbUp = tagForThumbUp?.tagId;
        currentVideoSettings.tagIdForThumbDown = tagForThumbDown?.tagId;
        currentVideoSettings.tagIdForOpenHand = tagForOpenHand?.tagId;
        currentVideoSettings.tagIdForVictorySign = tagForVictorySign?.tagId;
        setLoading(true);
        saveVideoSettings(currentVideoSettings)
            .then(_ => console.log('Saved settings'))
            .catch(err => console.error('Error saving settings', err));
        startGestureAnalysis(currentVideoSettings)
            .then(_ => {
                setLoading(false);
                closeThisModal();
                addToast({
                    variant:'success',
                    header:'Análisis con IA iniciado correctamente',
                    body:'Pronto recibirás una notificación con el resultado del análisis'
                });    
            })
            .catch(err => {
                console.error('Error starting gesture analysis', err);
                addToast({
                    variant:'error',
                    header:'Error al iniciar el análisis con IA',
                    body:'Puedes intentar nuevamente más tarde, si el error persiste contacta a tu administrador'
                });                    
            });
    }

    return (
    <Modal show={showModal} onShow={initModal} onHide={closeThisModal} size="lg">
        <Modal.Header>
            <button onClick={closeThisModal}
                title={t('general_close')}
                className="btn btn-sm text-secondary">
                <span className="material-icons-round">close</span>
            </button>
            <Modal.Title>{t('modal_gesture_analyzer_title')}</Modal.Title>
            <button onClick={startAnalysis}
                title={t('modal_gesture_analyzer_btn_start_analysis')}
                className="btn btn-sm text-vitag">
                <span className="material-icons-round">auto_awesome</span>
            </button>
        </Modal.Header>
        <Modal.Body className='px-5 pb-4 position-relative' style={{overflowY:'auto'}}>
            { loading &&
            <div className="position-absolute" style={{bottom:0,top:0,left:0,right:0,zIndex:9999,backgroundColor:'#0A0A0A66'}}>
                <div className="position-relative bg-white p-3 m-auto w-50 d-flex gap-3 align-items-center" style={{top:'36%'}}>
                    <Spinner animation='border' style={{color:'var(--vtg-color)'}}/>
                    <span>{t('general_loading_text')}</span>
                </div>
            </div> }
            <div className="container" style={loading ? {filter:'blur(2px)'} : {}}>
                <p>{t('modal_gesture_analyzer_instructions')}</p>
                <GestureTagAssignment availableTags={availableTags} gesture={{icon:'pan_tool'}}
                    selectedTag={tagForOpenHand} setSelectedTag={setTagForOpenHand}/>
                <GestureTagAssignment availableTags={availableTags} gesture={{useImageIcon:true, icon:'/assets/imgs/gestures/peace-sign.png'}}
                    selectedTag={tagForVictorySign} setSelectedTag={setTagForVictorySign}/>
                <GestureTagAssignment availableTags={availableTags} gesture={{icon:'thumb_up'}}
                    selectedTag={tagForThumbUp} setSelectedTag={setTagForThumbUp}/>
                <GestureTagAssignment availableTags={availableTags} gesture={{icon:'thumb_down'}}
                    selectedTag={tagForThumbDown} setSelectedTag={setTagForThumbDown}/>
            </div>
        </Modal.Body>
    </Modal>);
}

function GestureTagAssignment({availableTags, gesture, selectedTag, setSelectedTag}) {

    const { t } = useTranslation();
    const[filteredTags, setFilteredTags] = useState(availableTags);
    const[filterForTags, setFilterForTags] = useState("");
    const[editingSearch, setEditingSearch] = useState(false);

    const handleSearch = (evt) => {
        const text = evt.target.value.toLowerCase();
        if(text.trim() == '') {
            setFilteredTags(availableTags);
            return;
        }
        const tags = availableTags.map(tag => {
            if (tag.name.toLowerCase().includes(text)) {
                return tag;
            }
            const subTags = tag.subTags.filter(st => st.name.toLowerCase().includes(text));
            if((subTags || []).length == 0) {
                return null;
            }
            return {...tag, subTags};
        }).filter(tag => tag != null);
        setFilterForTags(text);
        setFilteredTags(tags);
    }

    const startSearch = () => {
        setFilteredTags(availableTags);
        setFilterForTags("");
        setEditingSearch(true);
    }

    const selectTag = (tag) => {
        setSelectedTag(tag);
        setEditingSearch(false);
    }

    return ( // A row to show gesture and tag dropdown to select
        <div className="d-flex align-items-center gap-5 mb-4">
            { ! gesture.useImageIcon && <span class="material-icons-round" style={{fontSize:'32px'}}>
                {gesture.icon}
            </span> }
            { gesture.useImageIcon && <img src={gesture.icon} style={{width:'32px',height:'32px'}}/> }
            <div className='flex-grow-1'>
                <Dropdown>
                    <Dropdown.Toggle className="btn btn-sm p-2 extra-rounded without-caret w-100 border d-flex gap-2 align-items-center"
                        style={{minWidth:'250px',minHeight:'50px'}} variant='transparent'>
                        { editingSearch &&
                            <input autoFocus={true} type="text"
                                className="form-control form-control-sm bg-transparent border-0" style={{color:'inherit'}}
                                placeholder={t('modal_gesture_analyzer_input_filtertags_placeholder')}
                                value={filterForTags} onChange={handleSearch}/>}
                        { (! editingSearch && ! selectedTag) && <span onClick={startSearch}>Selecciona una etiqueta</span>}
                        { (! editingSearch && selectedTag) && <IconIndicator onClick={startSearch} iconColor={selectedTag.color} iconName={selectedTag.name} iconText={selectedTag.icon} iconSize="sm"/> }
                        { (! editingSearch && selectedTag) && <span onClick={startSearch} className='cursor-pointer text-wrap'>{selectedTag.name}</span>}
                        <span className="ml-auto material-icons-round">expand_more</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu popperConfig={{strategy:'fixed'}} style={{overflowY:'auto',maxHeight:'250px'}}>
                        { (filteredTags || []).map(tag => <>
                            <Dropdown.Item key={tag.tagId} className="cursor-pointer d-flex gap-2 align-items-center"
                                onClick={() => selectTag(tag)}>
                                <IconIndicator iconColor={tag.color} iconName={tag.name} iconText={tag.icon} iconSize="sm"/> 
                                <span className='cursor-pointer'>{tag.name}</span>
                            </Dropdown.Item>
                            { (tag.subTags || []).map(st => 
                            <Dropdown.Item key={st.tagId} className="cursor-pointer d-flex gap-2 align-items-center"
                                onClick={() => selectTag(st)}>
                                <div className="p-1"></div>
                                <IconIndicator iconColor={st.color} iconName={st.name} iconText={st.icon} iconSize="sm"/> 
                                <span className='cursor-pointer'>{st.name}</span>
                            </Dropdown.Item>) }
                        </> )}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>);
}

export { ModalGesturesAnalyzer };
