import React, { useRef, useState } from 'react';
import { Col, Modal, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { EmptyPanel } from '../general/EmptyPanel';
import { InpuTagSeparator } from '../general/InputTagSeparator';
import { importUsers } from '../services/CoreService';
import { createGroup, updateGroup, getAvailableUsers, getGroupInfo } from '../services/VitagGroupsService';
import { config } from '../config';

function ModalManageGroup({ successListener, initialGroup, admPermissions, showModal, setShowModal }) {

    const [currentGroup, setCurrentGroup] = useState();
    const [members, setMembers] = useState([]);
    const [newMembers, setNewMembers] = useState([]);
    const [availableUsers, setAvailableUsers] = useState([]);
    const [searching, setSearching] = useState('');
    const [usersToImport, setUsersToImport] = useState([]);
    // const [importErrors, setImportErrors] = useState([]);
    const [showImportOptions, setShowImportOptions] = useState(false);
    const lastTimeoutId = useRef(-1);
    const { t } = useTranslation();

    const initModal = () => {
        if(config.IS_BETA) console.log('Init modal...');
        setNewMembers([]);
        setShowImportOptions(
            !! admPermissions.find(p => p.name === 'Import_Users')
        );
        if (initialGroup) {
            setCurrentGroup(initialGroup);
            Promise.all([getAvailableUsers(''), getGroupInfo(initialGroup.groupId)])
                .then(resp => {
                    const users = resp[0].data;
                    const grp = resp[1].data;
                    if(config.IS_BETA) console.log('Group details...', grp);
                    // calculatedAdded(users, grp.members);
                    setCurrentGroup(grp);
                    setMembers(grp.members);
                    handleSearch('');
                })
                .catch(err => {
                    if(config.IS_BETA) console.error('Error getting group info...', err)
                });
        } else {
            setCurrentGroup({name:'', description:'', groupId:undefined});
            setMembers([]);
            searchAvailableUsers('');
        }
    }

    const handleSearch = (newSearch) => {
        setSearching(newSearch);
        if (lastTimeoutId.current !== -1) {
            clearTimeout(lastTimeoutId.current);
        }
        lastTimeoutId.current = setTimeout(searchAvailableUsers, 600, newSearch);
    }

    const saveGroup = () => {
        const importUsersPromise = usersToImport.length === 0 ?
            new Promise((resolve, _) => resolve({data:[]})) :
            importUsers(usersToImport) ;
        importUsersPromise
            .then(users => {
                const leGroup = {...currentGroup};
                const importedUsers = users.data.map(u => ({userId: u.userId}));
                const selectedUsers = newMembers.filter(u => u.userId)
                    .map(u => ({userId: u.userId}));
                const currentUsers = members.map(u => ({userId: u.userId}));
                leGroup.groupUsers = [...currentUsers, ...selectedUsers, ...importedUsers];
                leGroup.newEmails = newMembers.filter(u => ! u.userId && u.valid)
                    .map(u => u.label);
                const toSaveGroup = leGroup.groupId ? updateGroup : createGroup;
                return toSaveGroup(leGroup);
            })
            .then(resp => {
                successListener && successListener(resp.data);
                closeThisModal();
            })
            .catch(err => {
                if(config.IS_BETA) console.error('Error creating group...', err)
            });
    }

    const searchAvailableUsers = (search) => {
        getAvailableUsers(search)
            .then(resp => {
                const users = resp.data.map(usr => ({
                    userId: usr.userId,
                    title: usr.fullName,
                    // subTitle: usr.nickname,
                    isUser: true
                }));
                setAvailableUsers(users);
            })
            .catch(err => {
                if(config.IS_BETA) console.error('Error getting available users...', err)
            });
    };

    const removeMember = (position) => {
        const newMembers = [...members];
        newMembers.splice(position, 1);
        // calculatedAdded(availableUsers, newMembers)
        setMembers(newMembers);
    }

    const fileReceivedToImport = (evt) => {
        const file = evt.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.readAsText(file, 'utf-8');
            reader.onload = res => {
                const contentCsv = res.target.result;
                parseFileContent(contentCsv);
            };
        }
    };

    const parseFileContent = (csv) => {
        const users = [];
        const errors = [];
        csv.split('\n').forEach((line, i) => {
            if(i === 0) {
                const headers = line.split(',');
                if (headers.length !== 4) {
                    errors.push(t('modal_groupmanagement_document_invalidheader'));
                }
                return;
            }
            const info = line.split(',');
            if (info.length !== 4) {
                errors.push(t('modal_groupmanagement_document_invalidline', {n:line}));
                return;
            }
            users.push({
                email: info[0],
                firstName: info[1],
                lastName: info[2],
                unsecurePassword: info[3],
            });
        });
        // setImportErrors(errors);
        setUsersToImport(users);
    };

    const downloadExample = () => {
        const csvContent = "data:text/csv;charset=utf-8,email,nombre,apellido,clave\nuser@mail.com,User,Demo,clave123";
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'ejemplo.csv');
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
    }

    const closeThisModal = () => setShowModal(false);

    return (
    <Modal show={showModal} onShow={initModal} onHide={closeThisModal} size="xl">
        <Modal.Header>
            <button onClick={closeThisModal} className="btn btn-sm align-self-center text-secondary" 
                title={ t('modal_groupmanagement_cancel') }>
                <span className="material-icons-round">close</span>
            </button>
            <Modal.Title>
                { t(currentGroup?.groupId != undefined ? 'modal_groupmanagement_title_edit_group' : 'modal_groupmanagement_title_new_group') }
            </Modal.Title>
            <button onClick={saveGroup} className='btn btn-sm vitag-text align-self-center' 
                title={ t('modal_groupmanagement_ok') }>
                <span className="material-icons-round">save</span>
            </button>
        </Modal.Header>
        <Modal.Body className='px-5'>
            <Row className="pb-3">
                <Col xs={12} sm={12} md={6}>
                    <Form.Group controlId="inputGroupName">
                        <Form.Label className='label'>
                            {t('modal_groupmanagement_label_groupname')}
                        </Form.Label>
                        <Form.Control
                            name="inputGroupName" type="text" placeholder={t('modal_groupmanagement_placeholder_groupname')}
                            value={currentGroup?.name} onChange={(evt) => setCurrentGroup({...currentGroup, name:evt.target.value})}/>
                        {/*<Form.Control.Feedback type="invalid">El nombre es requerido.</Form.Control.Feedback>*/}
                    </Form.Group>
                    <Form.Group controlId="inputGroupDesc">
                        <Form.Label>{t('modal_groupmanagement_label_groupdescription')}</Form.Label>
                        <Form.Control
                            name="inputGroupDesc" as="textarea" placeholder={t('modal_groupmanagement_placeholder_groupdescription')}
                            value={currentGroup?.description} onChange={(evt) => setCurrentGroup({...currentGroup, description:evt.target.value})}/>
                    </Form.Group>
                    <p className="label mb-2">
                        { t('modal_groupmanagement_label_searchusers') }
                    </p>
                    <InpuTagSeparator
                        classNameForInput='dropdown-toggle'
                        placeholder={ t('modal_groupmanagement_placeholder_searchusers') }
                        textInput={searching}
                        setTextInput={setSearching}
                        extraOnChange={handleSearch}
                        items={newMembers}
                        setItems={setNewMembers}
                        autoCompleteItems={availableUsers}/>
                    { showImportOptions && 
                    <div className="w-100">
                        <p className="label mt-3 mb-2">
                            { t('modal_groupmanagement_label_importusers') }
                        </p>
                        <div className={'dropdown' + (usersToImport.length === 0 ? ' mr-auto' : '')}>
                            <button className="btn btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-expanded="false">
                                { t('modal_groupmanagement_button_importusers') }
                            </button>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <label className="dropdown-item" htmlFor='importFile'>
                                    { t('modal_groupmanagement_button_selectfile') }
                                </label>
                                <div className="dropdown-divider"></div>
                                <button onClick={downloadExample} className="dropdown-item">
                                    { t('modal_groupmanagement_button_downloadexample') }
                                </button>
                            </div>
                        </div>
                        { usersToImport.length > 0 &&
                        <div className='mr-auto ml-3 d-flex flex-column'>
                            <span>
                                {usersToImport.length} {t('modal_groupmanagement_userstoimport')}
                            </span>
                            <small className='text-muted'>{t('modal_groupmanagement_userstoimport_info1')}<br/>{t('modal_groupmanagement_userstoimport_info2')}</small>
                        </div> }
                        <input onChange={fileReceivedToImport} type='file' className='d-none' id='importFile' name='importFile' accept='.csv'/>
                    </div> }
                </Col>
                <Col sm={12} md={6} className="border-left">
                    <p className="label mb-2">
                        { t('modal_groupmanagement_label_currentmembers') }
                    </p>
                    <div className="p-2 d-flex" style={{minHeight:'2.4em',flexWrap:'wrap',maxHeight:'320px',overflowY:'scroll'}}>
                        { members.length === 0 && <EmptyPanel message={t('modal_groupmanagement_withoutmembers')}/>}
                        { members.map((item, i) =>
                            <div key={item.userId} className="d-flex justify-content-between w-100 border mb-2 p-2">
                                <div className="d-flex align-items-center">
                                    <div className="nab-circle-btn mx-0" style={{flexShrink:'0'}}>
                                        <span className="material-icons-round">person</span>
                                    </div>
                                    <div className="mx-2">
                                        <span className="d-block small font-weight-bold">{item.fullName}</span>
                                        <span className="d-none small text-muted text-truncate">{item.nickname}</span>
                                    </div>
                                </div>
                                <button onClick={() => removeMember(i)}
                                    title={ t('modal_groupmanagement_button_removeuser_title') }
                                    className="btn btn-sm btn-outline-danger border-0 pb-0 px-2 pt-1">
                                    <span className="material-icons-round">cancel</span>
                                </button>
                            </div>
                        )}
                    </div>

                </Col>
            </Row>
        </Modal.Body>
    </Modal>)
}

export { ModalManageGroup };
