import React, { useEffect, useState, useContext } from "react";
import { Dropdown } from "react-bootstrap";
import { GeneralContext } from "../contexts/general-context";
import { NO_CONTEXT_PICTURE } from "../utils/ContextUtils";
import { getPreferenceAsNumber, PREFERENCES } from "../utils/preferences/user-preferences";
import classNames from 'classnames';
import {Typologies} from '../utils/TypologiesEnum';
import { config } from '../config';


function ContextSelector({
    darkTheme = false, className = '',
    changeContextListener = (ctx) => {
        if(config.IS_BETA) console.log('CTX changed')
    }
}) {

    const { contexts, currentContext, overrideCurrentContext } = useContext(GeneralContext);
    const [contextPicture, setContextPicture] = useState();

    useEffect(() => {
        const lastContextId = getPreferenceAsNumber(PREFERENCES.LAST_CONTEXT);
        const context = contexts.find(ctx => ctx.organizationId == lastContextId) || contexts[0];
        if(! currentContext) {
            overrideCurrentContext(context);
        }
    }, [contexts]);

    useEffect(() => {
        setContextPicture(currentContext?.organizationPicture)
    }, [currentContext]);

    const handleContextChange = (ctx) => {
        if (ctx.organizationId == currentContext?.organizationId) {
            if(config.IS_BETA) console.log('No context change detected');
            return;
        }
        overrideCurrentContext(ctx);
        changeContextListener(ctx);
    }

    const onContextPictureError = () => {
        setContextPicture(NO_CONTEXT_PICTURE);
    }

    return (
        <React.Fragment>
        <div className={'border-left d-flex d-md-none my-auto gap-2 px-3 ' + className}>
            <Dropdown>
                <Dropdown.Toggle className={"btn btn-sm border " + (darkTheme ? 'btn-outline-light bg-vitag' : 'btn-outline-secondary')}
                    title={currentContext?.name}>
                    <img src={contextPicture} onError={onContextPictureError} className='border'
                        style={{height:'36px',width:'36px',objectFit:'scale-down',backgroundColor:'white',borderRadius:'100px'}}/>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    { contexts.map(ct =>
                        <Dropdown.Item key={ct.organizationId}
                            onClick={() => handleContextChange(ct)}
                            className=
                                { classNames('cursor-pointer', {
                                    'disabled': ct?.organizationId === currentContext?.organizationId,
                                    'disabled vitag-text-expired': ct?.currentSub?.subscriptionStatus.internalId === Typologies.SS_EXPIRED
                                })
                                
                                }>
                            {ct.name}
                        </Dropdown.Item>
                    )}
                </Dropdown.Menu>
            </Dropdown>
        </div>
        <div className={'border-left d-none d-md-flex my-auto gap-2 px-3 ' + className}>
            <div className="my-auto">
                <img src={contextPicture} onError={onContextPictureError} className='border'
                    style={{height:'38px',width:'38px',objectFit:'scale-down',backgroundColor:'white',borderRadius:'100px'}}/>
            </div>
            <Dropdown>
                <Dropdown.Toggle variant={darkTheme ? 'outline-light' : 'outline-secondary'} className={"btn border"}
                    title={currentContext?.name}>
                    <div className="d-inline-flex flex-column" style={{maxWidth:'240px'}}>
                        <span className={'text-truncate '  + (darkTheme ? 'vitag-text-light' : 'vitag-text-dark')}>{currentContext?.name}</span>
                    </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="ctx-selector-menu">
                    { contexts.map(ct =>
                        <Dropdown.Item key={ct.organizationId}
                            onClick={() => handleContextChange(ct)}
                            className=
                                { classNames('cursor-pointer', {
                                    'disabled': ct?.organizationId === currentContext?.organizationId,
                                    'disabled vitag-text-expired': ct?.currentSub?.subscriptionStatus.internalId === Typologies.SS_EXPIRED
                                })
                                
                                }>
                            {ct.name}
                        </Dropdown.Item>
                    )}
                </Dropdown.Menu>
            </Dropdown>
        </div>
        </React.Fragment>
    )
}

export { ContextSelector };
