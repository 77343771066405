import React from 'react';
import { useTranslation } from 'react-i18next';

function LoadingPanel({message = "general_loading_text", translateMessage = true}) {

    const { t } = useTranslation();

    return (
        <div className="bg-light extra-rounded p-5 mx-3 text-center align-middle d-flex w-100" style={{minHeight:'360px'}}>
            <div className="spinner-border d-inline my-auto ml-auto vtg-spinner" role="status">
                <span className="sr-only">{ t('loadingPanel_title') }</span>
            </div>
            <span className="mx-2"></span>
            <span className="h5 d-inline my-auto mr-auto">
                { translateMessage ? t(message) : message }
            </span>
        </div>
    );
}

export { LoadingPanel }
