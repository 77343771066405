import React, { useEffect, useState } from 'react';
import { getTypologyByInternalId, uploadImageToS3 } from '../services/CoreService';
import { createVideo } from '../services/VitagVideosService';
import { Typologies } from '../utils/TypologiesEnum';
import { SimpleModal } from '../general/SimpleModal';
import { UploaderPreview } from './UploaderPreview';
import { UploaderInput } from './UploaderInput';
import { ProfileIndicator } from '../general/ProfileIndicator';
import { PERMISSIONS, useCheckPermission } from '../utils/permissions/PermissionsUtils';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { config } from '../config';

function NewVideo() {

    const [videoUrl, setVideoUrl] = useState('');
    const [videoDuration, setVideoDuration] = useState('');
    const [vtgVideo, setVtgVideo] = useState({ title:'', url:'', miniature:'', sharingUrl:'', timeInSeconds:-1, isTrainingMaterial:false });
    const [videoProvider, setVideoProvider] = useState();
    const [videoThumbnail, setVideoThumbnail] = useState('');
    const [videoReady, setVideoReady] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const allowIsTrainingMaterial = useCheckPermission(PERMISSIONS.MARK_AS_TRAINING_MATERIAL);
    const { t } = useTranslation();

    useEffect(() => {
        getTypologyByInternalId(Typologies.ACTIVE)
            .then(resp => setVtgVideo({...vtgVideo, status: resp.data}))
            .catch(err => {
                if(config.IS_BETA) console.error('Error getting typologies', err)
            });
    }, []);

    const handlingTitleChange = (event) => {
        const inputValue = event.target.value;
        setVtgVideo({...vtgVideo, title: inputValue});
    }

    const handlingTrainingMaterialChange = (event) => {
        const inputChecked = event.target.checked;
        setVtgVideo({...vtgVideo, isTrainingMaterial: inputChecked});
    }

    const saveVideo = () => {
        const thumbnailPromise = 
            videoThumbnail ?
            uploadImageToS3(videoThumbnail).then(resp => resp.data.imageUrl) :
            new Promise(response => response(vtgVideo.miniature));
        thumbnailPromise
            .then(thumbUrl => ({
                ...vtgVideo,
                url:videoUrl,
                miniature: thumbUrl
            }))
            .then(leVideo => createVideo(leVideo))
            .then(resp => {
                if(config.IS_BETA) console.log(resp);
                setVtgVideo({...vtgVideo, hashId:resp.data.hashId});
                setShowModal(true);
            })
            .catch(err => {
                if(config.IS_BETA) console.error(err)
            });
    }

    return (
        <React.Fragment>
            <div style={{zIndex:'9000', position:'absolute', top:'32px', right:'32px'}}>
                <ProfileIndicator useDarkTheme={false}/>
            </div>
            <div className="full-container bg-vitag">
                <div className="row w-100 justify-content-center mx-auto">
                    <div className="col-10 col-md-9 col-lg-6 col-xl-4 px-4 py-3 mt-lg-5 bg-white border extra-rounded" style={{marginTop:'6em'}}>
                        <div className="col-12 d-flex mb-3 border-bottom justify-content-start">
                            <img className="my-auto"  src="/assets/imgs/logo-color.svg" alt="ViTAG Logo" height="48px"/>
                            <div className='border-left mx-4 my-2' style={{height:'48px'}}></div>
                            <span className="my-auto">{t('newvideo_title')}</span>
                        </div>
                        <UploaderInput
                            vtgVideo={vtgVideo}
                            setVtgVideo={setVtgVideo}
                            videoUrl={videoUrl}
                            setVideoUrl={setVideoUrl}
                            setVideoReady={setVideoReady}
                            setVideoProvider={setVideoProvider}
                            setVideoDuration={setVideoDuration}/>
                        { videoReady && <>
                        <UploaderPreview
                            vtgVideo={vtgVideo}
                            setVtgVideo={setVtgVideo}
                            videoReady={videoReady}
                            setVideoReady={setVideoReady}
                            videoDuration={videoDuration}
                            setVideoDuration={setVideoDuration}
                            videoThumbnail={videoThumbnail}
                            setVideoThumbnail={setVideoThumbnail}
                            videoUrl={videoUrl}
                            videoProvider={videoProvider}/>
                        <div className="w-100 form-group mt-n2 mb-3">
                            <label className='w-100 text-center' htmlFor="inputVideoTitle">{t('newvideo_label_titleattr')}</label>
                            <input value={vtgVideo.title} onChange={handlingTitleChange} className="form-control w-100" id="inputVideoTitle" name="inputVideoTitle" type="text" placeholder={t('newvideo_placeholder_titleattr')} required/>
                        </div>
                        { allowIsTrainingMaterial &&
                        <div className="w-100 d-flex justify-content-center mb-3">
                             <div className="form-check">
                                <input value={vtgVideo.isTrainingMaterial} onChange={handlingTrainingMaterialChange}
                                    className="form-check-input" type="checkbox" id="materialTraining"/>
                                <label className="form-check-label cursor-pointer" htmlFor="materialTraining">
                                    {t('newvideo_label_istrainingmaterial')}
                                </label>
                            </div>
                        </div> }
                        <div className="border-top w-100 pb-3"></div>
                        <div className="w-100 d-flex justify-content-around">
                            <Link to={"/videos"} className=" btn btn-outline-secondary px-3">{t('newvideo_btn_cancel')}</Link>
                            <button onClick={saveVideo} disabled={! vtgVideo.title} className="bg-vitag btn btn-primary px-3">{t('newvideo_btn_addvideo')}</button>
                        </div>
                        </> }
                    </div>
                </div>
            </div>           
            <SimpleModal
                modalTitle={t('newvideo_modal_success_addedvideo')} okText={t('newvideo_modal_success_btn_addedvideo')}
                showModal={showModal} setShowModal={setShowModal}
                showCancelButton={false} showNextButton={true}
                target={`/video/${vtgVideo?.hashId}`}>
                <p className='mt-3'>{t('newvideo_modal_success_body_addedvideo', {title:vtgVideo?.title})}</p>
            </SimpleModal>
        </React.Fragment>
    );
}

export { NewVideo };