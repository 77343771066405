import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getAvailableLanguages } from '../../services/CoreService';
import { propagateLanguageChange } from './InjectTranslationConfig';
import { config } from '../../config';

function LanguageSelector({ buttonClass = 'btn-outline-secondary' }) {

    const [langs, setLangs] = useState([]);
    const { i18n } = useTranslation();

    useEffect(() => {
        getAvailableLanguages()
            .then(resp => setLangs(resp.data))
            .catch(err => {
                if(config.IS_BETA) console.error('Error getting languages', err)
            });
    }, []);

    const handleChangeLanguage = (lng) => {
        i18n.changeLanguage(lng.code).then(_ => {
            propagateLanguageChange(lng.code);
        });
    };

    return (
    <div className='d-flex gap-3'>
        { langs.map(lng =>
            <button key={lng.languageId} onClick={() => handleChangeLanguage(lng)}
                className={`btn ${buttonClass} px-3`}>
                {lng.name}
            </button>
        ) }
    </div>);
}

export { LanguageSelector };