import React, { useEffect, useState, useRef  } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FavItem } from '../general/FavItem';

function ModalReOrderFavs({ videoId, currentFavs, updateFavs, showModal, setShowModal }) {

  const[leTags, setLeTags] = useState([]);
  const[finalTags, setFinalTags] = useState([]);
  const[copyTags, setCopyTags] = useState([]);
  const[refresh, setRefresh] = useState();
  const[filter, setFilter] = useState('');
  const { t } = useTranslation();
  const[copyAllTags, setCopyAllTags] = useState([]);
  const finalTagsRef = useRef(finalTags);

  const initModal = () => {
    const allFavs = [];
    const newFavs = [];
    currentFavs.forEach(t => {
      allFavs.push({...t});
      if (t.selected) {
        newFavs.push({...t});
      }
      t.subTags.forEach(st => {
          if (st.selected) {
              newFavs.push({...st});
          }
      });
    });

    let allPositionsAreOne = newFavs.every(item => item.position === 1);

    if (!allPositionsAreOne) {
        allPositionsAreOne = newFavs.every(item => item.position === 100);
    }

    if (allPositionsAreOne) {
        newFavs.forEach((item, index) => {
          item.position = index + 1;
        });
    }
    newFavs.sort((a, b) => a.position - b.position);

    setFinalTags(newFavs);
    setLeTags(newFavs);
    setCopyTags(JSON.parse(JSON.stringify(newFavs))); 
    setCopyAllTags(JSON.parse(JSON.stringify(allFavs)));
  }

  useEffect(() => {
      if (refresh) {
          const newFinalTags = [...finalTags];
          newFinalTags[refresh[0]] = refresh[1];
          setFinalTags(newFinalTags);
      }
  }, [refresh]);

  const closeThisModal = () => {
    setFinalTags([...copyTags]);
    setLeTags([...copyTags]);
    setShowModal(false);
  }

  const saveThisModal = () => {
    setCopyTags(JSON.parse(JSON.stringify(finalTags))); // Actualizar copyTags con la versión final
    const updatedFavs = JSON.parse(JSON.stringify(currentFavs)); // Copia profunda de currentFavs

    updatedFavs.forEach(t => {
      if (t.selected) {
        finalTags.forEach(ft => {
          if (t.tagId === ft.tagId) {
            t.position = ft.position;
          }
        });
      }
      t.subTags.forEach(st => {
        if (st.selected) {
          finalTags.forEach(ft => {
            if (st.tagId === ft.tagId) {
              st.position = ft.position;
            }
          });
        }
      });
    });
    updateFavs(updatedFavs);
    setShowModal(false);
  };

  const matchTag = (tag) => {
      const searchFilter = filter.toLocaleLowerCase();
      if(tag.name.toLowerCase().includes(searchFilter)) {
          return true;
      }
      for(let st of tag.subTags) {
          if(st.name.toLowerCase().includes(searchFilter)) {
              return true
          }
      }
      return false;
  }

  const moveUp = (position) => {
    if (position === 0) {
        return;
    }
      moveTo(finalTags[position], position, position - 1);
  }

  const moveDown = (position) => {
      if (position === finalTags.length - 1) {
          return;
      }
      moveTo(finalTags[position], position, position + 1);
  }

  const moveTo = (item, fromIdx, toIdx) => {
      const newSubTags = [...finalTags];
      const [movedItem] = newSubTags.splice(fromIdx, 1);
      newSubTags.splice(toIdx, 0, movedItem);

      newSubTags.forEach((item, index) => {
          item.position = index + 1;
      });

      setFinalTags(newSubTags);
      setLeTags(newSubTags);
  }

  return ( <>
    <Modal show={showModal} onShow={initModal} onHide={closeThisModal} size='md' className='second-modal mt-5' style={{ zIndex: 1070 }}>
        <Modal.Header>
            <button onClick={closeThisModal}
                title={t('general_cancel')}
                className="btn btn-sm text-muted">
                <span className='material-icons-round'>close</span>
            </button>
            <Modal.Title>{t('modal_orderfavs_title')}</Modal.Title>
            <button onClick={saveThisModal}
                title={t('modal_managefavs_btn_savefavs_titleattr')}
                className="btn btn-sm text-vitag">
                <span className='material-icons-round'>save</span>
            </button>
        </Modal.Header>
        <Modal.Body className='px-5'>
            <div className="py-2 d-flex align-items-center">
                <p className='m-0 w-100 w-md-50'>{t('modal_orderfavs_text')}</p>
            </div>
            <div className="accordion" id="accordionFavs"
                style={{height:'360px',maxHeight:'360px', overflow:'scroll'}}>
                { leTags?.filter(t => matchTag(t))?.map((t, i) =>
                    <FavItem
                        key={t.tagId}
                        currentTag={t}
                        position={i}
                        parentId='accordionFavs'
                        filter={filter}
                        setRefresh={setRefresh}
                        moveUp={() => moveUp(i)}
                        moveDown={() => moveDown(i)}
                        isReOrder={true}/> )}
            </div>
        </Modal.Body>
    </Modal>
    </>);
}

export { ModalReOrderFavs }