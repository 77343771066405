import React, { useEffect, useState } from 'react';
import urlParser from 'nab-js-video-url-parser';
import moment from 'moment';
import { getTwitchVideoInfo, getVimeoVideoInfo, getYoutubeVideoInfo } from '../services/VideoInformationService';
import { listTypologiesByInternalId } from '../services/CoreService';
import { Typologies } from '../utils/TypologiesEnum';
import { useTranslation } from 'react-i18next';
import { VideoProviderIcon } from '../general/VideoProviderIcon';
import { config } from '../config';

function UploaderInput({
    vtgVideo, setVtgVideo, videoUrl, setVideoUrl, externUrl,
    setVideoProvider, setVideoReady, setVideoDuration, setWorkFile,
    labelInCenter = true
}) {

    const [initialData, setInitialData] = useState();
    const [availableProviders, setAvailableProviders] = useState([]);
    const [fileName, setFileName] = useState();
    const [workWithFile, setWorkWithFile] = useState(false);
    const {t} = useTranslation();

    const handleWorkWithFile = (bool) => {
        setWorkFile(bool);
        setWorkWithFile(bool)
    };

    // const videoFileExts = ['.ogg', '.mp4', '.webm'];

    useEffect(() => {
        listTypologiesByInternalId(Typologies.VIDEO_PROVIDER)
            .then(resp => {
                const newInitialData = {};
                resp.data.forEach(typ => {
                    switch(typ.internalId) {
                        case Typologies.VT_LOCAL_VIDEO:
                            newInitialData.localVideoProvider = typ;
                            break;
                        case Typologies.VT_YOUTUBE:
                            newInitialData.youtubeProvider = typ;
                            break;
                        case Typologies.VT_VIMEO:
                            newInitialData.vimeoProvider = typ;
                            break;
                        case Typologies.VT_TWITCH:
                            newInitialData.twitchProvider = typ;
                            break;
                        case Typologies.VT_EXTERN_VIDEO:
                            newInitialData.externVideoProvider = typ;
                            break;
                        case Typologies.VT_LIVESESSION:
                            newInitialData.liveSessionProvider = typ;
                            break;
                    }
                });
                setAvailableProviders(resp.data.reverse());
                setInitialData(newInitialData);
                if (externUrl) {
                    parseVideoURL(externUrl, newInitialData);
                }
            })
            .catch(err => {
                if(config.IS_BETA) console.error('Error getting typologies', err)
                });
    }, []);

    const parseVideoFile = (event) => {
        const localFile = event.target.files[0];
        const fileType = event.target.files[0].type;
        const url = URL.createObjectURL(localFile);
        setVideoProvider(initialData.localVideoProvider);
        setVideoUrl(url);
        setVideoReady(true);
        setVtgVideo({...vtgVideo, title:localFile.name, fileType});
        setFileName(localFile.name);
    }

    const handleVideoUrlChange = (event) => {
        const url = event.target.value;
        parseVideoURL(url);
    }

    const parseVideoURL = (url, init) => {
        if(! init) {
            init = initialData;
        }
        setVideoUrl(url);
        setVideoReady(false);
        const info = urlParser.parse(url);
        if(config.IS_BETA) console.log('Video information...', info);
        if (! info) {
            if(config.IS_BETA) console.error('Error parsing video URL');
            setVideoProvider(init.externVideoProvider);
            if (vtgVideo.title === '' || vtgVideo.title === undefined || vtgVideo.title === null) {
                const newTitle = extractTitleFromUrl(url);
                setVtgVideo({
                    ...vtgVideo,
                    title: newTitle
                });
              }
            setVideoReady(true);
            return;
        }
        if (info.mediaType != 'video') {
            if(config.IS_BETA) console.error('Error on media-type: ' + info.mediaType);
            return;
        }
        if (info.provider.toLowerCase() === 'youtube') {
            setVideoProvider(init.youtubeProvider);
            getYoutubeVideoInfo(info.id)
                .then(infoFromAPI => {
                    const duration = moment.duration(infoFromAPI.items[0].contentDetails.duration);                    
                    setVideoDuration(duration.humanize());
                    setVtgVideo({
                        ...vtgVideo,
                        url: videoUrl,
                        title: infoFromAPI.items[0].snippet.title,
                        miniature: infoFromAPI.items[0].snippet.thumbnails.high.url,
                        timeInSeconds: duration.asSeconds(),
                        timeInMilliSeconds: duration.asMilliseconds(),
                        videoProvider: init.youtubeProvider,
                        onlineId: info.id
                    });
                    setVideoReady(true);
                })
                .catch(_ => {
                    if(config.IS_BETA) console.error('Impossible load YT info')
                });
            return;
        }
        if (info.provider.toLowerCase() === 'vimeo') {
            setVideoProvider(init.vimeoProvider);
            getVimeoVideoInfo(info.id)
                .then(resp => {
                    if(config.IS_BETA) console.log(resp);
                    const duration = moment.duration(resp.duration, 'seconds');
                    setVideoDuration(duration.humanize());
                    setVtgVideo({
                        ...vtgVideo,
                        url: videoUrl,
                        title: resp.name,
                        miniature: resp.pictures.sizes[resp.pictures.sizes.length - 1].link,
                        timeInSeconds: resp.duration,
                        timeInMilliSeconds: resp.duration * 1000,
                        videoProvider: init.vimeoProvider,
                        onlineId: info.id
                    });
                    setVideoReady(true);
                })
                .catch(err => {
                    if(config.IS_BETA) console.error('Error getting Vimeo info', err)
                });
            return;
        }
        if (info.provider.toLowerCase() === 'twitch') {
            setVideoProvider(init.twitchProvider);
            getTwitchVideoInfo(info.id.replace('v', ''))
                .then(resp => {
                    if(config.IS_BETA) console.log('Twitch API response...', resp);
                    const stream = resp.data.data[0];
                    if(config.IS_BETA) console.log(stream);
                    const duration = moment.duration(
                        stream.duration.replace('h', ':').replace('m', ':').replace('s', '')
                    );
                    setVideoDuration(duration.humanize());
                    setVtgVideo({
                        ...vtgVideo,
                        url: videoUrl,
                        title: stream.title,
                        miniature: stream.thumbnail_url.replace("%{width}","720").replace("%{height}", "480"),
                        timeInSeconds: duration.asSeconds(),
                        timeInMilliSeconds: duration.asMilliseconds(),
                        videoProvider: init.twitchProvider,
                        onlineId: stream.id
                    });
                    setVideoReady(true);
                })
                .catch(err => {
                    if(config.IS_BETA) console.error('Error getting Twitch info', err)
                });
            return;
        }
    };

    const extractTitleFromUrl = (url) => {
        const lastSegment = url.split('/').pop();
        const title = lastSegment.replace(/[-_]/g, ' ').replace(/%20/g, ' ');
        return title;
      };

    return (
        <>
            <label className="w-100">
                {t('modal_videomanagement_label_videosource')}
            </label>
            <div className="d-flex w-100 mb-2">
                <button onClick={() => handleWorkWithFile(false)} type='button'
                    className={"w-50 btn " + (!workWithFile ? 'bg-vitag text-white': 'bg-lightgray')}
                    style={{borderTopRightRadius:0,borderBottomRightRadius:0}}>
                    {t('modal_videomanagement_btn_videourl')}
                </button>
                <button onClick={() => handleWorkWithFile(true)} type='button'
                    className={"w-50 btn " + (workWithFile ? 'bg-vitag text-white': 'bg-lightgray')}
                    style={{borderTopLeftRadius:0,borderBottomLeftRadius:0}}>
                    {t('modal_videomanagement_btn_videofile')}
                </button>
            </div>

            { ! workWithFile &&
            <div className="w-100 form-group m-0">
                <label className={"d-none w-100 " + (labelInCenter ? 'text-center': '')} htmlFor="inputVideoURL">
                    {t('modal_videomanagement_label_url')}
                </label>
                <div className="input-group">
                    <input value={videoUrl} onChange={handleVideoUrlChange}
                        placeholder={t('modal_videomanagement_placeholder_url')}
                        className="form-control" id="inputVideoURL" name="inputVideoURL" type="text"/>
                    <div className="input-group-append">
                        <div className="input-group-text">
                            <span className="material-icons-round">link</span>
                        </div>
                    </div>
                </div>
            </div> }

            { workWithFile &&
            <div className="w-100 form-group m-0">
                <label className={"d-none w-100 " + (labelInCenter ? 'text-center': '')} htmlFor="inputVideoFile">
                    {t('modal_videomanagement_label_localfile')}
                </label>
                <div className="input-group cursor-pointer">
                    <input onChange={parseVideoFile} type="file"
                        accept="video/webm, video/mp4, video/x-ms-wmv, video/quicktime"
                        className="d-none" id="inputVideoFile" name="inputVideoFile"/>
                    <label className="form-control rounded" htmlFor="inputVideoFile"
                        style={{overflowX:'hidden', textOverflow:'ellipsis'}}>
                        { fileName || <span className="text-muted">
                            {t('modal_videomanagement_placeholder_localfile')}
                        </span> }
                    </label>
                    <div className="d-none input-group-append">
                        <label className="input-group-text" htmlFor="inputVideoFile"
                            title={t('modal_videomanagement_selectfile_titleattr')}>
                            <span className="material-icons-round">attach_file</span>
                        </label>
                    </div>
                </div>
            </div> }

            <div className="d-flex gap-2 align-items-center justify-content-end mt-2">
                <span className="small text-muted">
                    {t('modal_videomanagement_availableproviders_text')}
                </span>
                { availableProviders.map(vp => <VideoProviderIcon key={vp.typologyId} videoProvider={vp}/> ) }
            </div>
        </>
    );
}

export { UploaderInput };