import React, { useState } from 'react';
import { Row, Col, Form, Modal } from 'react-bootstrap';
import { GOOGLE_ICONS } from '../utils/GoogleIcons';
import { createIcon } from '../services/CoreService';
import { Trans, useTranslation } from 'react-i18next';
import { config } from '../config';

function ModalNewIcon({ showModal, setShowModal, giveNewIcon }) {

    const [icon, setIcon] = useState({icon:'', name:'', keywords:''});
    const [invalidIcon, setInvalidIcon] = useState(false);
    const [validIcon, setValidIcon] = useState(false);
    const [iconErrors, setIconErrors] = useState({icon:false, name:false, keywords:false});
    const { t } = useTranslation();

    const initModal = () => {
        setIcon({icon:'', name:'', keywords:''});
        setInvalidIcon(false);
        setValidIcon(false);
        setIconErrors({icon:false, name:false, keywords:false});
    }

    const handleInput = (evt, propName) => {
        const value = evt.target.value;
        const newIcon = {...icon};
        newIcon[propName] = value;
        setIcon(newIcon);
    };

    const handleIconText = (evt) => {
        const newIcon = {...icon, icon: evt.target.value};
        setIcon(newIcon);
        const iconKey = newIcon.icon.toLowerCase();
        const iconValue = GOOGLE_ICONS.find(ic => ic === iconKey);
        if (! iconValue) {
            setInvalidIcon(true);
            setValidIcon(false);
        } else {
            setInvalidIcon(false);
            setValidIcon(true);
        }
    }

    const validateForm = () => {
        const errors = {...iconErrors};
        let hasErrors = false;
        if (! icon.name.trim()) {
            errors.name = true;
            hasErrors = true;
        } else {
            errors.name = false;
        }
        if (! icon.icon.trim()) {
            errors.icon = true;
            hasErrors = true;
        } else {
            errors.icon = false;
        }
        const iconKey = icon.icon.toLowerCase();
        const iconValue = GOOGLE_ICONS.find(ic => ic === iconKey);
        if (! iconValue) {
            setInvalidIcon(true);
            setValidIcon(false);
            hasErrors = true;
        } else {
            setInvalidIcon(false);
            setValidIcon(true);
        }
        setIconErrors(errors);
        return hasErrors;
    };

    const saveIcon = () => {
        const hasErrors = validateForm();
        if (hasErrors) {
            if(config.IS_BETA) console.error('Icon with errors');
            return;
        }
        createIcon(icon)
            .then(ic => {
                giveNewIcon(ic.data);
                closeThisModal();
            })
            .catch(err => {
                if(config.IS_BETA) console.error('Error saving icon', err)
                });
    }

    const closeThisModal = () => {
        setShowModal(false);
    }

    return (
        <Modal show={showModal} onShow={initModal} onHide={closeThisModal} size='md' className='mt-4' style={{ zIndex: 1070 }}>
            <Modal.Header>
                <button onClick={closeThisModal}
                    title={t('general_cancel')}
                    className="btn btn-sm text-secondary">
                    <span className="material-icons-round">close</span>
                </button>
                <Modal.Title>{t('modal_newicon_title')}</Modal.Title>
                <button onClick={saveIcon}
                    title={t('modal_newicon_btn_saveicon_titleattr')}
                    className="btn btn-sm text-vitag">
                    <span className="material-icons-round">save</span>
                </button>
            </Modal.Header>
            <Modal.Body>
                <Row className='px-3'>
                    <Col sm={12}>
                        <Form.Group className="input-container" controlId="inputIconName">
                            <Form.Label>
                                {t('modal_newicon_label_iconname')}
                            </Form.Label>
                            <Form.Control value={icon.name} onChange={(e) => handleInput(e, 'name')}
                                placeholder={t('modal_newicon_placeholder_iconname')}
                                name="inputIconName" className={"text-muted " + (iconErrors.name ? 'is-invalid':'')} type="text"/>
                            <Form.Control.Feedback type="invalid">
                                {t('modal_newicon_error_iconname_required')}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col sm={9}>
                        <Form.Group className="input-container" controlId="inputIcon">
                            <Form.Label>
                                <Trans i18nKey='modal_newicon_label_icontext'>
                                    Icon text (see <a href='https://fonts.google.com/icons?icon.style=Rounded&icon.set=Material+Icons' target='_blank' rel="noreferrer">available icons here</a>)
                                </Trans>
                            </Form.Label>
                            <Form.Control value={icon.icon} onChange={handleIconText}
                                name="inputIcon" className={"text-muted " + (iconErrors.icon || invalidIcon ? 'is-invalid':'')}
                                type="text" placeholder={t('modal_newicon_placeholder_icontext')}/>
                            { iconErrors.icon &&
                                <Form.Control.Feedback type="invalid">
                                    {t('modal_newicon_error_icontext_required')}
                                </Form.Control.Feedback> }
                            { invalidIcon &&
                                <Form.Control.Feedback type="invalid">
                                    {t('modal_newicon_error_icontext_invalid')}
                                </Form.Control.Feedback> }
                        </Form.Group>
                    </Col>
                    <Col sm={3} className='d-flex flex-column'>
                        { validIcon && <>
                            <span className='text-center text-muted'>{t('modal_newicon_label_preview')}</span>
                            <span className='material-icons-round pt-3 mx-auto'>{icon.icon}</span>
                        </>}
                    </Col>
                    <Col sm={12}>
                        <Form.Group className="input-container" controlId="inputIconKeyWords">
                            <Form.Label>{t('modal_newicon_label_keywords')}</Form.Label>
                            <Form.Control value={icon.keywords} onChange={(e) => handleInput(e, 'keywords')}
                                name="inputIconKeyWords" className="text-muted"
                                type="text" placeholder={t('modal_newicon_placeholder_keywords')}/>
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
}

export { ModalNewIcon };