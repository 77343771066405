import axios from 'axios';

function createSetupIntent(userId) {
  return axios.post(`/checkout/create-setup-intent?userId=${userId}`);
}

function listPaymentMethods(userId) {
  return axios.get(`/payment-methods/user/${userId}`);
}

function createPaymentMethod(userId, paymentMethod) {
  const payload = {userId: userId, paymentMethod: paymentMethod}
  return axios.post("/payment-methods", payload);
}

function deletePaymentMethod(paymentMethodId) {
  return axios.delete(`/payment-methods/${paymentMethodId}`);
}

function updatePreference(paymentMethodId, userId) {
  return axios.post(`/payment-methods/prefer/${paymentMethodId}`, {}, {
    params: { userId: userId }
  });
}

export {
  createSetupIntent, listPaymentMethods, deletePaymentMethod, createPaymentMethod, updatePreference
};