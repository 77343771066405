import { useEffect, useState } from "react";
import pageGettingStarted from './documentation-page1-getting-started.md';
import pageSecurity from './documentation-page2-security.md';
import pageFirstRequest from './documentation-page3-first-request.md';
import pageListVideos from './documentation-page4-list-videos.md';
import ReactMarkdown from "react-markdown";
import { TokenManagement } from "./TokenManagement";
import { TestAPIPanel } from "./TestAPIPanel";
import { config } from "../config";

function Documentation() {

    const [currentPageContent, setCurrentPageContent] = useState('');
    const [next, setNext] = useState();
    const [prev, setPrev] = useState();
    const [currentPage, setCurrentPage] = useState(0);

    const loadPage = (mdFileReference) => {
        fetch(mdFileReference)
            .then(md => md.text())
            .then(mdText => setCurrentPageContent(mdText.replace("HOST_CORE", config.CORE_DOMAIN)));
        setNext({label:'Seguridad',page:1});
    }

    const showPage = (page) => {
        setCurrentPage(page);
        switch(page) {
            case 0: return openGettingStarted();
            case 1: return openSecurity();
            case 2: return openFirstRequest();
            case 3: return openListVideos();
        }
    }

    const openGettingStarted = () => {
        loadPage(pageGettingStarted);
        setPrev(undefined);
        setNext({label:'Seguridad',page:1});
    }

    const openSecurity = () => {
        loadPage(pageSecurity);
        setPrev({label:'Empezando',page:0});
        setNext({label:'Tu primera petición',page:2});
    }

    const openFirstRequest = () => {
        loadPage(pageFirstRequest);
        setPrev({label:'Seguridad',page:1});
        setNext({label:'Listar videos',page:3});
    }

    const openListVideos = () => {
        loadPage(pageListVideos);
        setPrev({label:'Tu primera petición',page:2});
        setNext(undefined);
    }

    useEffect(() => {
        openGettingStarted();
        setCurrentPage(0);
    }, []);

    return <>
    <div className="row">
        <div className="col">
            <ReactMarkdown children={currentPageContent}/>
        </div>
        { currentPage == 1 && <div className="col-12 col-sm-7"><TokenManagement/></div> }
        { currentPage == 2 && <div className="col-12 col-sm-7">
            <TestAPIPanel
                urlComplement="/hello"
                contentType='text/plain'/>
        </div> }
        { currentPage == 3 && <div className="col-12 col-sm-7">
            <TestAPIPanel
                panelTitle="Obtener videos"
                urlComplement="/vtg-videos/list"
                initialParams={[
                    {key:'title',label:'Título',value:'',optional:true}
                ]}/>
        </div> }
        <div className="col-12 mt-3 d-flex justify-content-between">
            { prev != undefined ? <button onClick={() => showPage(prev.page)} className="btn d-flex gap-2 align-items-center">
                <span className="cursor-pointer material-icons-round">arrow_back_ios</span>
                <span className="cursor-pointer">{ prev.label }</span>
            </button> : <span> </span> }
            { next != undefined ? <button onClick={() => showPage(next.page)} className="btn d-flex gap-2 align-items-center">
                <span className="cursor-pointer">{ next.label }</span>
                <span className="cursor-pointer material-icons-round">arrow_forward_ios</span>
            </button> : <span> </span> }
        </div>
    </div>
    </>
}

export { Documentation };