import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import moment from 'moment';
import 'moment/locale/es';
import { config } from '../../config';

const LANGS_FOR_MOMENTJS = {
    'en_US':'en',
    'es_ES':'es'
};

function InjectTranslationConfig () {

    const { i18n } = useTranslation();

    useEffect(() => {
        if(config.IS_BETA) console.log('This effect (config translations) is called once');
        if(config.IS_BETA) console.log('Current language: ' + i18n.language);
        propagateLanguageChange(i18n.language);
    }, []);

    return null;
}

function propagateLanguageChange (newLangCode) {
    moment.locale(LANGS_FOR_MOMENTJS[newLangCode]);
}

export { InjectTranslationConfig, propagateLanguageChange };
