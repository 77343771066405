import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IconIndicator } from '../general/IconIndicator';
import { IconPill } from '../general/IconPill';
import { PREFERENCES, getPreferenceAsNumber, savePreference } from '../utils/preferences/user-preferences';
import { Typologies } from '../utils/TypologiesEnum';

function FavoritesBar({
    quickTag, setQuickTag, favoriteVtgTags,
    listenerOpenNewMarker, listenerNewQuickMarker,
    toggleLockMarkerSummary, lockMarkerSummary, 
    listenerNewTag, listenerOpenFavs, fromLive = false, children
}) {
    const FAV_BAR_PRESENTATION = {
        HORIZONTAL:0,
        VERTICAL:1,
        FULLSCREEN:2
    };

    const { t } = useTranslation();
    const [favBarPresentation, setFavBarPresentation] = useState(
        getPreferenceAsNumber(PREFERENCES.PLAYER_FAVBAR_PRESENTATION)
    );
    const [vertHeight, setVertHeight] = useState(
        getPreferenceAsNumber(PREFERENCES.VERT_FAV_BAR_HEIGHT)
    );
    const [horzWidth, setHorzWidth] = useState(
        getPreferenceAsNumber(PREFERENCES.HORZ_FAV_BAR_WIDTH)
    );
    const toggleBar = () => {
        if (favBarPresentation == FAV_BAR_PRESENTATION.HORIZONTAL) {
            saveFavBarPresentation(FAV_BAR_PRESENTATION.VERTICAL);
            return;
        }
        if (favBarPresentation == FAV_BAR_PRESENTATION.VERTICAL) {
            saveFavBarPresentation(FAV_BAR_PRESENTATION.FULLSCREEN);
            return;
        }
        if (favBarPresentation == FAV_BAR_PRESENTATION.FULLSCREEN) {
            saveFavBarPresentation(FAV_BAR_PRESENTATION.HORIZONTAL);
            return;
        }
        //Default value...
        saveFavBarPresentation(FAV_BAR_PRESENTATION.HORIZONTAL);
    }
    const toggleQuickTag = () => setQuickTag(! quickTag);
    const saveFavBarPresentation = (newPresentation) => {
        savePreference(PREFERENCES.PLAYER_FAVBAR_PRESENTATION, newPresentation);
        setFavBarPresentation(newPresentation);
    }
    let posFavbar1 = 0, posFavbar2 = 0, posFavbar3 = 0, posFavbar4 = 0;
    let rtime, timeout = false;

    useEffect(() => {
        const resizeObs = new ResizeObserver(entries => {
            rtime = new Date();
            if (timeout == false) {
                timeout = true;
                setTimeout(resizeEnd, 800, entries);
            }
        });
        const favbarElement = document.getElementById('horizontal-fav-bar') || document.getElementById('vertical-fav-bar');
        if (favbarElement) {
            resizeObs.observe(favbarElement);
        }    
    }, []);

    const resizeEnd = (entries) => {
        if (new Date() - rtime < 800) {
            setTimeout(resizeEnd, 800, entries);
            return;
        }
        timeout = false;
        if (entries[0].target.id == 'horizontal-fav-bar'
            && entries[0].target.offsetWidth > 0) {
            savePreference(PREFERENCES.HORZ_FAV_BAR_WIDTH, entries[0].target.offsetWidth);
        }
        if (entries[0].target.id == 'vertical-fav-bar'
            && entries[0].target.offsetHeight > 0) {
            savePreference(PREFERENCES.VERT_FAV_BAR_HEIGHT, entries[0].target.offsetHeight);
        }
    }

    const startFavbarMove = evt => {
        evt = evt || window.event;
        evt.preventDefault();
        posFavbar3 = evt.clientX;
        posFavbar4 = evt.clientY;
        document.onmouseup = endFavbarMove;
        document.onmousemove = moveFavbar;
    }

    const moveFavbar = evt => {
        evt = evt || window.event;
        evt.preventDefault();
        posFavbar1 = posFavbar3 - evt.clientX;
        posFavbar2 = posFavbar4 - evt.clientY;
        posFavbar3 = evt.clientX;
        posFavbar4 = evt.clientY;
        const elmnt = document.getElementById('vertical-fav-bar')
            || document.getElementById('horizontal-fav-bar')
            || document.getElementById('fullscreen-fav-bar');
        if (elmnt) {
            elmnt.style.top = (elmnt.offsetTop - posFavbar2) + "px";
            elmnt.style.left = (elmnt.offsetLeft - posFavbar1) + "px";
            elmnt.style.right = 'auto';
        }
    }

    const endFavbarMove = () => {
        document.onmouseup = null;
        document.onmousemove = null;
    }

    const safeToggleLockMarkerSummary = () => {
        if (lockMarkerSummary === Typologies.LMSO_ACTIVE) {
            toggleLockMarkerSummary && toggleLockMarkerSummary(Typologies.LMSO_INACTIVE);
        } else if (lockMarkerSummary === Typologies.LMSO_INACTIVE) {
            toggleLockMarkerSummary && toggleLockMarkerSummary(Typologies.LMSO_TIMER);
        } else if (lockMarkerSummary === Typologies.LMSO_TIMER) {
            toggleLockMarkerSummary && toggleLockMarkerSummary(Typologies.LMSO_ACTIVE);
        }
        
    }

    if (favBarPresentation == FAV_BAR_PRESENTATION.FULLSCREEN) {
        return (
            <div id='fullscreen-fav-bar'
                className="position-absolute favbar-fullscreen extra-rounded px-3 py-2 d-flex flex-column gap-1 align-items-center"
                style={{top:'96px',bottom:'86px',zIndex:8000,backgroundColor:'rgba(0,0,0,0.5)'}}>
                
                <div className="d-flex justify-content-start border-bottom border-dark py-1 w-100 gap-3 mb-3" style={{flexWrap:'wrap'}} >
                    <div onMouseDown={startFavbarMove} title={t('player_favbar_btn_move_titleattr')}
                        className="text-light mt-1" style={{cursor:'move'}}>
                        <span className="material-symbols-outlined">open_with</span>
                    </div>
                    <button onClick={toggleBar} title={t('player_favbar_btn_togglelayout_titleattr')}
                        className="btn btn-sm px-1 line-0 btn-white-text">
                        <span className="material-symbols-outlined">screen_rotation_alt</span>
                    </button>
                    <button onClick={listenerOpenFavs} title={t('player_favbar_btn_managetags_titleattr')}
                        className="btn btn-sm px-1 line-0 btn-white-text">
                        <span className="material-symbols-outlined">sell</span>
                    </button>
                    { ! fromLive && <>
                        <button onClick={safeToggleLockMarkerSummary} title={t('player_favbar_btn_togglemarkersummary_titleattr')}
                            className="btn btn-sm px-1 line-0 btn-white-text">
                            <span className="material-symbols-outlined">tooltip</span>
                            { lockMarkerSummary === Typologies.LMSO_INACTIVE && <span className="material-symbols-outlined position-absolute small" style={{top:8,left:0,right:0}}>
                                visibility_off
                            </span> }
                            { lockMarkerSummary === Typologies.LMSO_TIMER && <span className="material-symbols-outlined position-absolute small" style={{top:8,left:0,right:0}}>
                                timer
                            </span> }
                        </button>
                    </> }
                    { ! fromLive && favoriteVtgTags?.length > 0 &&
                        <button onClick={toggleQuickTag} title={t('player_favbar_btn_togglequickmarker_titleattr')}
                            className="btn btn-sm px-1 line-0 btn-white-text">
                            <span className="material-symbols-outlined">{quickTag ? 'flash_on' : 'flash_off'}</span>
                        </button>
                    }
                    { ! fromLive && <>
                        <div className="ml-auto d-flex gap-2">
                            <div style={{height:'32px',borderLeft:'solid 2px #a22a26'}}></div>
                            <button onClick={listenerOpenNewMarker} title={t('player_favbar_btn_addmarker_titleattr')}
                                className="btn btn-sm px-1 line-0 btn-white-text">
                                <span className="material-symbols-outlined">bookmark_add</span>
                            </button>
                        </div>
                    </> }
                </div>

                <div className="d-flex flex-wrap gap-3 mb-2">
                    { favoriteVtgTags?.length === 0 && <div className="favorite-tag my-2 bg-light text-muted px-4">{t('player_favbar_label_no_favorites')}</div> }
                    { favoriteVtgTags?.map(tag =>
                        <IconPill key={tag.tagId}
                            onPillClick={evt => listenerNewQuickMarker && listenerNewQuickMarker(evt, tag)}
                            iconName={tag.name} iconText={tag.icon} iconColor={tag.color} pillText={tag.name}/>)}
                </div>

            </div>);
    }

    if (favBarPresentation == FAV_BAR_PRESENTATION.HORIZONTAL) {
        return (
            <div id='horizontal-fav-bar' className="resizable-with-corner fav-button-container px-3 py-2 d-flex gap-1 align-items-center" 
                style={{zIndex:1050,width:horzWidth+'px',minWidth:'380px',overflowX:'auto',overflowY:'hidden',resize:'horizontal'}}>
                <div onMouseDown={startFavbarMove} title={t('player_favbar_btn_move_titleattr')}
                    className="text-light mt-1" style={{cursor:'move'}}>
                    <span className="material-symbols-outlined">open_with</span>
                </div>
                <button onClick={toggleBar} title={t('player_favbar_btn_togglelayout_titleattr')}
                    className="btn btn-sm px-1 line-0 btn-white-text">
                    <span className="material-symbols-outlined">screen_rotation_alt</span>
                </button>
                <button onClick={listenerOpenFavs} title={t('player_favbar_btn_managetags_titleattr')}
                    className="btn btn-sm px-1 line-0 btn-white-text">
                    <span className="material-symbols-outlined">sell</span>
                </button>
                {! fromLive && <>
                    <button onClick={safeToggleLockMarkerSummary} title={t('player_favbar_btn_togglemarkersummary_titleattr')}
                        className="btn btn-sm px-1 line-0 btn-white-text d-block">
                        <span className={"material-symbols-outlined " + (lockMarkerSummary !== Typologies.LMSO_INACTIVE && 'fav-disable')}>tooltip</span>
                        { lockMarkerSummary === Typologies.LMSO_INACTIVE && <span className="material-symbols-outlined position-absolute small" style={{top:8,left:0,right:0}}>
                            visibility_off
                        </span> }
                        { lockMarkerSummary === Typologies.LMSO_TIMER && <span className="material-symbols-outlined position-absolute small" style={{top:8,left:0,right:0}}>
                            timer
                        </span> }
                    </button>
                </>}

                { ! fromLive && favoriteVtgTags?.length > 0 &&
                <button onClick={toggleQuickTag} style={{zIndex:0}} title={t('player_favbar_btn_togglequickmarker_titleattr')}
                    className="btn btn-sm px-1 line-0 btn-white-text">
                    <span className="material-symbols-outlined">{quickTag ? 'flash_on' : 'flash_off'}</span>
                </button> }

                <div style={{height:'100%',borderLeft:'solid 2px #a22a26'}}></div>

                { children }

                { children && <div className='mx-2' style={{height:'100%',borderLeft:'solid 2px #a22a26'}}></div> }

                { ! fromLive &&
                    <button onClick={listenerOpenNewMarker} title={t('player_favbar_btn_addmarker_titleattr')}
                        className="btn btn-sm px-1 line-0 btn-white-text" style={{zIndex:0}}>
                        <span className="material-symbols-outlined">bookmark_add</span>
                    </button>
                }

                { favoriteVtgTags?.length === 0 &&
                <div className="favorite-tag bg-light text-muted px-4">{t('player_favbar_label_no_favorites')}</div> }

                <div className="d-flex ml-2" style={{overflowX:'auto',gap:'8px',zIndex:0}}>
                { favoriteVtgTags?.map(tag =>
                    <IconIndicator key={tag.tagId}
                        iconName={tag.name} iconText={tag.icon} iconColor={tag.color}
                        iconOnClick={evt => listenerNewQuickMarker && listenerNewQuickMarker(evt, tag)}
                        style={{border:'solid 1px #FFFFFF'}}/> )}
                </div>
            </div>);
    }
    // vertical-fav-bar
    return (
        <div id='vertical-fav-bar' className="resizable-with-corner fav-extended p-3 d-flex flex-column align-items-center"
            style={{zIndex:1050,height:vertHeight+'px',minHeight:'120px',minWidth:'240px',resize:'vertical',overflow:'auto'}} >
            { children }
            <div className="d-flex justify-content-between border-bottom border-dark pb-1 w-100 gap-1">
                <div onMouseDown={startFavbarMove} title={t('player_favbar_btn_move_titleattr')}
                    className="btn btn-sm px-1 line-0 btn-white-text" style={{cursor:'move'}}>
                    <span className="material-symbols-outlined">open_with</span>
                </div>
                <button onClick={toggleBar} title={t('player_favbar_btn_togglelayout_titleattr')}
                    className="btn btn-sm px-1 line-0 btn-white-text">
                    <span className="material-symbols-outlined">screen_rotation_alt</span>
                </button>
                <button onClick={listenerOpenFavs} title={t('player_favbar_btn_managetags_titleattr')}
                    className="btn btn-sm px-1 line-0 btn-white-text">
                    <span className="material-symbols-outlined">sell</span>
                </button>
                { ! fromLive && <>
                    <button onClick={safeToggleLockMarkerSummary} title={t('player_favbar_btn_togglemarkersummary_titleattr')}
                        className="btn btn-sm px-1 line-0 btn-white-text">
                        <span className="material-symbols-outlined">tooltip</span>
                        { lockMarkerSummary === Typologies.LMSO_INACTIVE && <span className="material-symbols-outlined position-absolute small" style={{top:8,left:0,right:0}}>
                            visibility_off
                        </span> }
                        { lockMarkerSummary === Typologies.LMSO_TIMER && <span className="material-symbols-outlined position-absolute small" style={{top:8,left:0,right:0}}>
                            timer
                        </span> }
                    </button>
                </> }
                { ! fromLive && favoriteVtgTags?.length > 0 &&
                    <button onClick={toggleQuickTag} title={t('player_favbar_btn_togglequickmarker_titleattr')}
                        className="btn btn-sm px-1 line-0 btn-white-text">
                        <span className="material-symbols-outlined">{quickTag ? 'flash_on' : 'flash_off'}</span>
                    </button>
                }
                { ! fromLive && <>
                    <div style={{height:'100%',borderLeft:'solid 2px #a22a26'}}></div>
                    <button onClick={listenerOpenNewMarker} title={t('player_favbar_btn_addmarker_titleattr')}
                        className="btn btn-sm px-1 line-0 btn-white-text">
                        <span className="material-symbols-outlined">bookmark_add</span>
                    </button>
                </> }
            </div>
            <div className='w-100 d-flex flex-column' style={{gap:'8px',overflowY:'scroll',zIndex:0}}>
                { favoriteVtgTags?.length === 0 && <div className="favorite-tag my-2 bg-light text-muted px-4">{t('player_favbar_label_no_favorites')}</div> }
                { favoriteVtgTags?.map(tag =>
                    <IconPill key={tag.tagId}
                        onPillClick={evt => listenerNewQuickMarker && listenerNewQuickMarker(evt, tag)}
                        iconName={tag.name} iconText={tag.icon} iconColor={tag.color} pillText={tag.name}/>)}
            </div>
        </div>);
}

export { FavoritesBar };