import React from "react";
import { useTranslation } from 'react-i18next';
import { faTwitch, faVimeo, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faFileVideo, faVideo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typologies } from "../utils/TypologiesEnum";
import { faDotCircle } from "@fortawesome/free-regular-svg-icons";

function VideoProviderIcon({videoProvider}) {
    const { t } = useTranslation();
    return (
        <>
            { videoProvider?.internalId === Typologies.VT_TWITCH &&
                <FontAwesomeIcon title={t(videoProvider.description)}
                    icon={faTwitch} style={{color:'#a970ff'}}/> }
            { videoProvider?.internalId === Typologies.VT_VIMEO &&
                <FontAwesomeIcon title={t(videoProvider.description)}
                    icon={faVimeo} style={{color:'#00adef'}}/> }
            { videoProvider?.internalId === Typologies.VT_YOUTUBE &&
                <FontAwesomeIcon title={t(videoProvider.description)}
                    icon={faYoutube} style={{color:'#cc0000'}}/> }
            { videoProvider?.internalId === Typologies.VT_EXTERN_VIDEO &&
                <FontAwesomeIcon title={t(videoProvider.description)}
                    icon={faVideo}/> }
            { videoProvider?.internalId === Typologies.VT_LOCAL_VIDEO &&
                <FontAwesomeIcon title={t(videoProvider.description)}
                    icon={faFileVideo}/> }
            { videoProvider?.internalId === Typologies.VT_LIVESESSION &&
                <FontAwesomeIcon title={t(videoProvider.description)}
                    icon={faDotCircle} style={{color:'#FF0000'}}/> }
        </>
    );
}

export { VideoProviderIcon }