import React, { useState } from "react";
import packageJson from "../package.alias.json";
import { ContextSelector } from "../home/ContextSelector";
import { ProfileIndicator } from "./ProfileIndicator";
import { Link } from "react-router-dom";

function AppNavbar({showContextSelector = true, showProfile = true}) {

    const [count, setCount] = useState(0);

    const handleVersionClick = () => {
        if (count > 3) {
            alert(packageJson.version);
        }
        setCount(count + 1);
    }

    return (
        <div className="d-flex position-fixed w-100 bg-vitag justify-content-between px-3 py-2" style={{zIndex:99,top:0}}>
            <div className="d-flex">
                <div className="my-auto d-flex d-md-none">
                    <Link to='/'>
                        <img onClick={handleVersionClick} src="/assets/imgs/isotipo-negative.svg" alt="ViTAG Logo" height="56px" className="d-flex mr-2"/>
                    </Link>
                </div>
                <div className="my-auto d-none d-md-flex">
                    <Link to='/'>
                        <img onClick={handleVersionClick} src="/assets/imgs/logo-white.svg" alt="ViTAG Logo" height="48px" className="d-flex px-3 py-1"/>
                    </Link>
                </div>
                { showContextSelector && <ContextSelector darkTheme={true}/> }
            </div>
            { showProfile && <div className="d-flex">
                <ProfileIndicator useDarkTheme={false}/>
            </div> }
        </div>
    );
}

export { AppNavbar };
