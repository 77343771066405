import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconIndicator } from '../../general/IconIndicator';

function TagSelector({ availableTags, setAvailableTags, filterTags, setFilterTags }) {

    const { t } = useTranslation();

    const handleTagClick = (currentTag, position) => {
        currentTag.selected = ! currentTag.selected;
        const newTags = [...availableTags];
        newTags[position] = currentTag;
        setAvailableTags(newTags);
        setFilterTags(availableTags.filter(tag => tag.selected).map(tag => tag.tagId));
    };

    const cleanFilter = () => {
        const newTags = [...availableTags];
        newTags.forEach(tag => tag.selected = false);
        setAvailableTags(newTags);
        setFilterTags([]);
    }

    return (
        <React.Fragment>
        { availableTags.length > 0 ?
            <div className='d-flex justify-content-start border-bottom gap-1 px-2 py-2 w-100' style={{flexDirection:'row', overflowX:'auto'}}>
                { availableTags.filter(at => at.selected).length > 0 &&
                    <button
                        onClick={cleanFilter} disabled={filterTags?.length === 0} style={{lineHeight:1}}
                        title={t('player_sidebar_btn_cleanfilter_titleattr')}
                        className='btn btn-sm mr-1 btn-outline-secondary'>
                            <span className="material-icons-round" style={{fontSize:'20px'}}>filter_alt_off</span>
                    </button>}
                { availableTags.map((tag, i) => 
                    <IconIndicator key={tag.tagId}
                        iconOnClick={() => handleTagClick(tag, i)}
                        style={{flexShrink:'0'}}
                        iconName={tag?.name} iconText={tag?.icon}
                        iconColor={tag?.color + (tag?.selected ? '' : '66')}/>)}
            </div> :
            <span className="py-3 text-muted border-bottom d-block w-100 text-center small">
                {t('player_sidebar_message_notagstofilter')}
            </span>}
        </React.Fragment>
    );
}

export { TagSelector };
