import React, { useEffect, useState } from 'react';
import { eventsByVideo } from "../services/VitagEventsService";
import { useCustomToast } from '../utils/toasts/useCustomToast';
import { presentDate } from '../utils/NabUtils';
import { ConfirmModal } from '../general/ConfirmModal';
import { useTranslation } from 'react-i18next';
import { config } from '../config';

function ModalShowEvents({vtgVideo, showModal, setShowModal}) {

    const [showingAllInfo, setShowingAllInfo] = useState(false);
    const [eventsOnThisVideo, setEventsOnThisVideo] = useState([]);
    const { addToast } = useCustomToast();
    const { t } = useTranslation();

    useEffect(() => {
        if (showModal) {
            loadMoreInfo(false, () => setShowingAllInfo(false));
        }
    }, [showModal]);
    

    const loadAllInfo = () => {
        loadMoreInfo(true, () => setShowingAllInfo(true));
    }

    const loadMoreInfo = (showAll, extraCallback = () => {
        if(config.IS_BETA) console.log('more info...')
    }) => {
        eventsByVideo(vtgVideo.videoId, showAll)
            .then(resp => {
                setEventsOnThisVideo(resp.data);
                extraCallback();
            })
            .catch(_ => addToast(t('toast_videoevents_error_title')));
    }


    return <>
        <ConfirmModal
            modalTitle={t('modal_videoevents_title')}
            modalText={t('modal_videoevents_text', {videoTitle: vtgVideo?.title})}
            showOkButton={false}
            showModal={showModal}
            setShowModal={setShowModal}>
            <table className="table table-sm table-hover">
                <thead>
                    <tr>
                        <th>{t('events_header_user')}</th>
                        <th>{t('events_header_date')}</th>
                        <th>{t('events_header_type')}</th>
                    </tr>
                </thead>
                <tbody>
                    {eventsOnThisVideo.map(evt =>
                    <tr key={evt.eventId}>
                        <td>{evt.user}</td>
                        <td title={evt.entryDate}>{presentDate(evt.entryDate)}</td>
                        <td>{t(evt.eventType.description)}</td>
                    </tr>)}
                    { ! showingAllInfo && <tr>
                        <td colSpan={3} align="center">
                            <button onClick={loadAllInfo} className="btn btn-sm">
                                {t('events_show_all')}
                            </button>
                        </td>
                    </tr>}
                </tbody>
            </table>
        </ConfirmModal> 
    </>
}

export { ModalShowEvents };