import React from "react";
import { useTranslation } from "react-i18next";

function HelpButton({size = 'sm'}) {

    const { t } = useTranslation();

    return (
        <a href="https://vitag.es/soporte" target="_blank" className={"btn p-0 my-auto btn-" + size}
            title={t('tab_help_title')}>
            <span className="material-icons-round text-vitag">help</span>
        </a>
    );
}

export { HelpButton }