import React from 'react';
import { HelpButton } from './HelpButton';

function VitagTitleBar({ titleAtBar }) {
    return (
        <div className="w-100 d-flex mb-4 pb-1 border-bottom justify-content-between">
            <div className="d-flex">
                <a href="https://vitag.es" target="_blank" className="my-auto">
                    <img className="my-auto" src="/assets/imgs/logo-color.svg"
                        alt="ViTAG Logo" height="36px"/>
                </a>
                <div className='border-left mx-4 my-2' style={{height:'36px'}}></div>
                <span className="my-auto">{titleAtBar}</span>
            </div>
            <HelpButton/>
        </div>
    );
}

export { VitagTitleBar };
