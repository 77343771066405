import moment from 'moment';
import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import { Alert, Col, Modal, Row, Tab, Tabs, Toast } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { EmptyPanel } from '../general/EmptyPanel'
import { InpuTagSeparator } from '../general/InputTagSeparator';
import { getAvailableUsers, listAllGroups } from '../services/VitagGroupsService';
import { listShareDetails, stopShare } from '../services/VitagShareDetailsService';
import { shareTag } from '../services/VitagTagsService';
import { shareVideo, shareVideoByLink, shareVideos } from '../services/VitagVideosService';
import { COLLABORATIVE_EDIT, INDIVIDUAL_EDIT, ONLY_READ } from '../utils/ShareContentUtils';
import { listMarkerCounts } from '../services/VitagMarkersService';
import QRCode from 'react-qr-code';
import html2canvas from 'html2canvas';
import { config } from '../config';

function ModalShare({ saveVideoListener, successListener, showModal, setShowModal, vtgVideo, vtgTag, videoIds }) {

    const [availableItems, setAvailableItems] = useState([]);
    const [inputItems, setInputItems] = useState([]);
    const [modalTitle, setModalTitle] = useState('Compartir');
    const [searching, setSearching] = useState('');
    const [showDateModal, setShowDateModal] = useState(false);
    const [nowDate, setNowDate] = useState('');
    const [fromDate, setFromDate] = useState();
    const [thruDate, setThruDate] = useState();
    const [currentTimeZone, setCurrentTimeZone] = useState('');
    const [availableShareTypes, setAvailableShareTypes] = useState([]);
    const [shareDetails, setShareDetails] = useState([]);
    const [shareWithLimitTime, setShareWithLimitTime] = useState(false);
    const [showCopied, setShowCopied] = useState(false);
    const [shareByLink, setShareByLink] = useState(false);
    const [shareTypeByLink, setShareTypeByLink] = useState('');
    const [hostname, setHostname] = useState('');
    const [shareLink, setShareLink] = useState('');
    const [sharedType, setSharedType] = useState(
        { onlyRead: true, individualEdit: false, collaborativeEdit: false }
    );
    const { t } = useTranslation();
    const lastTimeoutId = useRef(-1);

    useEffect(() => {
        setHostname(window.location.href.split('/#/')[0]);
    }, [])

    const initModal = () => {
        const d = moment().format('YYYY-MM-DDTHH:mm');
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        setCurrentTimeZone(timezone);
        setSearching('');
        setInputItems([]);
        setShareWithLimitTime(false);
        setFromDate(undefined);
        setThruDate(undefined);
        setInputItems([]);
        setNowDate(d);
        if (vtgVideo) {
            if(config.IS_BETA) console.log('Sharing video', vtgVideo);
            setModalTitle(t('modal_sharecontrol_title_sharevideo'));
            setShareLink(hostname + '/#/share-video/' + vtgVideo.hashId);
            setShareByLink(vtgVideo.shareByLink);
            setShareTypeByLink(vtgVideo.shareTypeByLink);
            setAvailableShareTypes([
                {value: 1, txtValue: ONLY_READ, text: t('general_sharevideo_readonly')},
                {value: 2, txtValue: INDIVIDUAL_EDIT, text: t('general_sharevideo_individualedit')},
                {value: 3, txtValue: COLLABORATIVE_EDIT, text: t('general_sharevideo_collaborativeedit')}
            ]);
        }
        if (vtgTag) {
            setModalTitle(t('modal_sharecontrol_title_sharetag'));
            setAvailableShareTypes([
                {value: 1, text: t('general_sharetag_readonly')},
                {value: 2, text: t('general_sharetag_edit')}
            ]);
        }
        if (videoIds) {
            setModalTitle(t('modal_sharecontrol_title_sharevideos', {n:videoIds.length}));
            setAvailableShareTypes([
                {value: 1, text: t('general_sharevideo_readonly')},
                {value: 2, text: t('general_sharevideo_individualedit')},
                {value: 3, text: t('general_sharevideo_collaborativeedit')}
            ]);
        }
        searchAvailableUsersAndGroups('');
        searchShareDetails();
    };

    const handleSearch = (newSearch) => {
        setSearching(newSearch);
        if (lastTimeoutId.current !== -1) {
            clearTimeout(lastTimeoutId.current);
        }
        lastTimeoutId.current = setTimeout(searchAvailableUsersAndGroups, 600, newSearch);
    }

    const handleShareType = (evt) => {
        const value = evt.target.value;
        setSharedType({
            onlyRead: value === '1',
            individualEdit: value === '2',
            collaborativeEdit: value === '3',
        });
    }

    const handleShareWithLimitTime = (evt) => {
        const value = evt.target.checked;
        setShareWithLimitTime(value);
        if (value) {
            setShowDateModal(true);
            const fd = moment().format('YYYY-MM-DDTHH:mm');
            setFromDate(fd);
            const td = moment().add(7, 'days').format('YYYY-MM-DDTHH:mm');
            setThruDate(td);
        } else {
            setFromDate(undefined);
            setThruDate(undefined);
        }
    }

    const handleShareByLink = (evt) => {
        const value = evt.target.checked;
        setShareByLink(value);
        saveShareByLink(value, shareTypeByLink);
    }

    const handleShareTypeByLink = (evt) => {
        const value = evt.target.value;
        setShareTypeByLink(value);
        saveShareByLink(shareByLink, value);
    }

    const handleFromDate = (evt) => {
        const value = evt.target.value;
        setFromDate(value);
    }
    
    const handleThruDate = (evt) => {
        const value = evt.target.value;
        setThruDate(value);
    }
    
    const searchAvailableUsersAndGroups = (search) => {
        Promise.all([getAvailableUsers(search), listAllGroups(search)])
            .then(resp => {
                const items = [];
                (resp[0].data || []).forEach(usr => { // Users
                    const i = {
                        userId: usr.userId,
                        title: usr.fullName,
                        // subTitle: usr.nickname,
                        isUser: true
                    }
                    items.push(i);
                });
                (resp[1].data || []).forEach(grp => { // Groups
                    const i = { groupId: grp.groupId, title: grp.name, subTitle: grp.description, isUser: false }
                    items.push(i);
                });
                setAvailableItems(items);
            })
            .catch(err => {
                if(config.IS_BETA) console.error('Error getting available users or groups...', err)
            });
    }

    const searchShareDetails = () => {
        if (vtgTag) {
            listShareDetails(vtgTag.tagId, 'tag')
                .then(resp => setShareDetails(resp.data))
                .catch(_ => {
                    if(config.IS_BETA) console.error('Impossible getting share tag details')
                });
            return;
        }
        if (vtgVideo) {
            Promise.all([
                listShareDetails(vtgVideo.videoId, 'video'), listMarkerCounts(vtgVideo.hashId)
            ])
            .then(responses => {
                const details = responses[0].data;
                const counts = responses[1].data;
                details.forEach(det => {
                    const counter = counts.find(n => n.userId == det.userId);
                    det.count = counter?.count ?? 0;
                });
                setShareDetails(details);
            })
            .catch(_ => {
                if(config.IS_BETA) console.error('Impossible getting share video details')
            });

            return;
        }
        if(config.IS_BETA) console.error('Not available for multiple videos');
    }

    const stopSharing = (shareDetail, position) => {
        const copyOfItems = [...shareDetails];
        copyOfItems.splice(position, 1);
        setShareDetails(copyOfItems);
        stopShare(shareDetail.sharedElementId)
            .then(_ => {
                if(config.IS_BETA) console.log('Sharing stop successful')
            })
            .catch(_ => {
                if(config.IS_BETA) console.error('Impossible stop sharing')
            });
    }

    const shareElement = () => {
        if (vtgVideo) {
            shareTheVideo();
        }
        if (vtgTag) {
            shareTheTag();
        }
        if (videoIds) {
            shareSelectedVideos();
        }
    }

    const shareSelectedVideos = () => {
        const request = {
            shareMessage: t('general_sharevideo_sharingvideo'),
            users: inputItems.filter(it => it.userId)
                .map(it => ({userId:it.userId, userEmail:it.label})),
            groups: inputItems.filter(it => it.groupId).map(it => it.groupId),
            emails: inputItems.filter(it => ! it.groupId && ! it.userId).map(it => it.label),
            resources: videoIds,
            tz: currentTimeZone, fromDate, thruDate,
            ...sharedType
        };
        shareVideos(request)
            .then(resp => {
                successListener && successListener(resp);
                closeModal();
            })
            .catch(err => {
                if(config.IS_BETA) console.error('Impossible share items...', err)
            });
    }

    const shareTheVideo = () => {
        const request = {
            shareMessage: t('general_sharevideo_sharingvideo'),
            users: inputItems.filter(it => it.userId)
                .map(it => ({userId:it.userId, userEmail:it.label})),
            groups: inputItems.filter(it => it.groupId).map(it => it.groupId),
            emails: inputItems.filter(it => ! it.groupId && ! it.userId).map(it => it.label),
            tz: currentTimeZone, fromDate, thruDate, ...sharedType
        };
        shareVideo(vtgVideo.hashId, request)
            .then(resp => {
                successListener && successListener(resp);
                closeModal();
            })
            .catch(err => {
                if(config.IS_BETA) console.error('Impossible share item...', err)
            });
        saveShareByLink(shareByLink, shareTypeByLink);
    }

    const saveShareByLink = (share, stValue) => {
        const currentShareType = availableShareTypes.find(st => st.txtValue == stValue);
        const st = currentShareType?.txtValue || availableShareTypes[0].txtValue;
        const requestByLink = { share, shareType: st };
        shareVideoByLink(vtgVideo.hashId, requestByLink)
            .then(_ => {
                if(config.IS_BETA) console.log(`Sharing by link: ${shareByLink} - ${st}`);
                saveVideoListener && saveVideoListener(share);
            })
            .catch(err => {
                if(config.IS_BETA) console.error('Error sharing by link', err)
            });
    }

    const shareTheTag = () => {
        const request = {
            shareMessage: t('general_sharevideo_sharingtag'),
            users: inputItems.filter(it => it.userId)
                .map(it => ({userId:it.userId, userEmail:it.label})),
            groups: inputItems.filter(it => it.groupId).map(it => it.groupId),
            emails: inputItems.filter(it => ! it.groupId && ! it.userId).map(it => it.label),
            tz: currentTimeZone, fromDate, thruDate, ...sharedType
        };
        shareTag(vtgTag.tagId, request)
            .then(resp => {
                successListener && successListener(resp);
                closeModal();
            })
            .catch(err => {
                if(config.IS_BETA) console.error('Impossible share item...', err)
            });
    }

    const copyShareLink = () => {
        navigator.clipboard.writeText(shareLink);
        setShowCopied(true);
    }

    const closeModal = () => {
        setShowModal(false);
    }

    const closeDateModal = () => {
        setShowDateModal(false);
    }

    const cancelDateModal = () => {
        setShareWithLimitTime(false);
        setFromDate(undefined);
        setThruDate(undefined);
        setShowDateModal(false);
    }

    const handleDownloadQR = () => {
        const element = document.getElementById('share-link-qr');
        const scale = 3;

        html2canvas(element, {scale}).then(canvas => {
            const data = canvas.toDataURL('image/png');
            const link = document.createElement('a');
            link.href = data;
            link.download = vtgVideo?.title + '-qr.png';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
      };

    return (
        <>
        <Modal show={showModal} onShow={initModal} onHide={closeModal} size="xl">
            <Modal.Header>
                <button onClick={closeModal} className="btn btn-sm"
                    title={t('modal_sharecontrol_cancel')}>
                    <span className="material-icons-round">close</span>
                </button>
                <div className='d-flex flex-column px-4'>
                    <Modal.Title>{modalTitle}</Modal.Title>
                    <span className="text-muted text-center mx-auto">
                        {vtgVideo?.title || vtgTag?.name || t('modal_sharecontrol_subtitle_selectedvideos', {n:videoIds?.length})}
                    </span>
                </div>
                <button onClick={shareElement} className="btn btn-sm text-vitag"
                    title={t('modal_sharecontrol_share')}>
                    <span className="material-icons-round">share</span>    
                </button>
            </Modal.Header>
            <Modal.Body className='px-4 pb-4 no-scroll'>
                <Row className='pb-3'>
                    { vtgVideo ? (
                    <>
                        <Col xs={12} sm={12} md={6} className='border-right'>
                            <Row className='px-3'>
                                <Col xs={12} className='d-flex align-items-center gap-2 my-2'>
                                    <span className="text-muted">{t('modal_sharecontrol_main_section_specific_users')}</span>
                                    <span className="border-bottom flex-grow-1"></span>
                                </Col>
                                <Col xs={12} className='mb-3'>
                                    <InpuTagSeparator
                                        classNameForInput='dropdown-toggle'
                                        placeholder={t('modal_sharecontrol_placeholder_usersearch')}
                                        textInput={searching}
                                        setTextInput={setSearching}
                                        extraOnChange={handleSearch}
                                        items={inputItems}
                                        setItems={setInputItems}
                                        autoCompleteItems={availableItems}/>
                                </Col>
                                <Col xs={12}>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <label className="input-group-text" htmlFor="inputShareOption">
                                                {t('modal_sharecontrol_label_accesstype')}
                                            </label>
                                        </div>
                                        <select className="custom-select" id="inputShareOption" onChange={handleShareType}>
                                            { availableShareTypes.map(st => <option key={st.value} value={st.value}>{st.text}</option>) }
                                        </select>
                                    </div>
                                </Col>
                                <Col xs={12}>
                                    <div className='my-3 form-check'>
                                        <input id='shareWithTime' name='shareWithTime' type='checkbox'
                                            className='form-check-input cursor-pointer mb-3'
                                            checked={shareWithLimitTime} onChange={handleShareWithLimitTime} />
                                        <label htmlFor='shareWithTime' className="form-check-label cursor-pointer">
                                            { t('modal_sharecontrol_label_sharewithlimittime') }
                                        </label>
                                        { fromDate && <span className='d-block text-muted'>
                                            { t('modal_sharecontrol_label_timefrom', {date: fromDate}) }
                                        </span> }
                                        { thruDate && <span className='d-block text-muted'>
                                            { t('modal_sharecontrol_label_timethru', {date: thruDate}) }
                                        </span> }
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} sm={12} md={6}>
                                <Row className='px-3'>
                                    <Col xs={12} className='d-flex align-items-center gap-2 my-2'>
                                        <span className="text-muted">{t('modal_sharecontrol_main_section_share_link')}</span>
                                        <span className="border-bottom flex-grow-1"></span>
                                    </Col>
                                    <Col xs={12} className='d-flex justify-content-between gap-4 mb-3'>
                                        <div className="d-flex flex-column flex-grow-1 gap-4">
                                            <div className='form-check mt-2'>
                                                <input id='shareByLink' name='shareByLink' type='checkbox'
                                                    className='form-check-input cursor-pointer'
                                                    checked={shareByLink} onChange={handleShareByLink} />
                                                <label htmlFor='shareByLink' className="form-check-label cursor-pointer">
                                                    {t('modal_sharecontrol_label_enablelinkaccess')}
                                                </label>
                                            </div>
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <label className="input-group-text" htmlFor="inputShareLinkOption">
                                                        {t('modal_sharecontrol_label_accesstype')}
                                                    </label>
                                                </div>
                                                <select disabled={! shareByLink} value={shareTypeByLink} onChange={handleShareTypeByLink}
                                                    className="custom-select" id="inputShareLinkOption">
                                                    { availableShareTypes.map(st => <option key={st.value} value={st.txtValue}>{st.text}</option>) }
                                                </select>
                                            </div>
                                            { shareByLink &&
                                            <div className='w-100'>
                                                <div className="input-group">
                                                    <input type="url" value={shareLink} className="form-control disabled" disabled readOnly/>
                                                    <div onClick={copyShareLink} className="input-group-append cursor-pointer"
                                                        title={t('modal_sharecontrol_button_copylink_title')}>
                                                        <div className="input-group-text">
                                                            <span className="material-icons-round">copy</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <Toast style={{position:'absolute',top:'82px',right:'0px'}} show={showCopied} onClose={() => setShowCopied(false)} delay={800} autohide>
                                                    <Toast.Header closeButton={false}>
                                                        <strong className="me-auto w-100">
                                                            {t('modal_sharecontrol_toast_copied')}
                                                        </strong>
                                                    </Toast.Header>
                                                </Toast>
                                            </div> }
                                        </div>
                                    </Col>
                                    {shareByLink &&
                                        <Col xs={12}>
                                            <div className="bg-white d-flex flex-column align-items-center gap-2 p-2">
                                                <div id='share-link-qr' className="position-relative p-1">
                                                    <QRCode
                                                        size={200}
                                                        style={{height:"auto",maxWidth:"200px",width:"200px"}}
                                                        value={shareLink}
                                                        viewBox={`0 0 200 200`}/>
                                                    <img alt='' src='assets/imgs/isotipo-with-border.svg' className='position-absolute mx-auto my-auto'
                                                        style={{width:'64px',height:'64px',top:0,bottom:0,left:0,right:0}}/>
                                                </div>
                                                <button onClick={handleDownloadQR} className="btn btn-sm d-flex align-items-center gap-2">
                                                    <span className='cursor-pointer'>{t('modal_sharecontrol_btn_download')}</span>
                                                    <span className="material-icons-round">download</span>
                                                </button>
                                            </div>
                                        </Col>
                                    }
                                </Row>
                        </Col>
                    </>
                    ) : 
                    (
                        <Col xs={12} sm={12}>
                            <Row className='px-3'>
                                <Col xs={12} className='d-flex align-items-center gap-2 my-2'>
                                    <span className="text-muted">{t('modal_sharecontrol_main_section_specific_users')}</span>
                                    <span className="border-bottom flex-grow-1"></span>
                                </Col>
                                <Col xs={12} md={6} className='mb-3'>
                                    <InpuTagSeparator
                                        classNameForInput='dropdown-toggle'
                                        placeholder={t('modal_sharecontrol_placeholder_usersearch')}
                                        textInput={searching}
                                        setTextInput={setSearching}
                                        extraOnChange={handleSearch}
                                        items={inputItems}
                                        setItems={setInputItems}
                                        autoCompleteItems={availableItems}/>
                                </Col>
                                <Col xs={12} md={6}>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <label className="input-group-text" htmlFor="inputShareOption">
                                                {t('modal_sharecontrol_label_accesstype')}
                                            </label>
                                        </div>
                                        <select className="custom-select" id="inputShareOption" onChange={handleShareType}>
                                            { availableShareTypes.map(st => <option key={st.value} value={st.value}>{st.text}</option>) }
                                        </select>
                                    </div>
                                </Col>
                                <Col xs={12}>
                                    <div className='my-3 form-check'>
                                        <input id='shareWithTime' name='shareWithTime' type='checkbox'
                                            className='form-check-input cursor-pointer mb-3'
                                            checked={shareWithLimitTime} onChange={handleShareWithLimitTime} />
                                        <label htmlFor='shareWithTime' className="form-check-label cursor-pointer">
                                            { t('modal_sharecontrol_label_sharewithlimittime') }
                                        </label>
                                        { fromDate && <span className='d-block text-muted'>
                                            { t('modal_sharecontrol_label_timefrom', {date: fromDate}) }
                                        </span> }
                                        { thruDate && <span className='d-block text-muted'>
                                            { t('modal_sharecontrol_label_timethru', {date: thruDate}) }
                                        </span> }
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    )}
                </Row>
                
                {/**/}
            </Modal.Body>
        </Modal>
        <Modal show={showDateModal} onHide={cancelDateModal} size="md" className='mt-5' style={{ zIndex: 1070 }}>
            <Modal.Header>
                <button onClick={cancelDateModal}
                    title={t('modal_sharecontrol_submodal_button_cancel_title')}
                    className="btn btn-sm text-muted">
                    <span className="material-icons-round">close</span>
                </button>
                <Modal.Title>
                    {t('modal_sharecontrol_submodal_title_definetimelimit')}
                </Modal.Title>
                <button onClick={closeDateModal} disabled={! fromDate || ! thruDate}
                    title={t('modal_sharecontrol_submodal_button_ok_title')}
                    className="btn btn-sm text-vitag">
                    <span className="material-icons-round">check</span>
                </button>
            </Modal.Header>
            <Modal.Body>
                <Col sm={12}>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" style={{width:'80px'}}>
                                {t('modal_sharecontrol_submodal_label_from')}
                            </span>
                        </div>
                        <input value={fromDate} onChange={handleFromDate} min={nowDate}
                            type="datetime-local" className="form-control" disabled={! shareWithLimitTime}/>
                    </div>
                </Col>
                <Col sm={12}>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" style={{width:'80px'}}>
                                {t('modal_sharecontrol_submodal_label_thru')}
                            </span>
                        </div>
                        <input value={thruDate} onChange={handleThruDate} min={fromDate}
                            type="datetime-local" className="form-control" disabled={! shareWithLimitTime}/>
                    </div>
                </Col>
            </Modal.Body>
        </Modal>
        </>
    );
}

export { ModalShare };
