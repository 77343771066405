import moment from "moment";
import { convertMillisecondsToTime, convertSecondsToTime } from "../../utils/NabUtils";

function generateReport(markers, t) {
    var printWindow = window.open("", "", "height=400,width=800");
    printWindow.document.write("<html>");
    printWindow.document.write("<head>");
    printWindow.document.write('<link rel="preconnect" href="https://fonts.googleapis.com">');
    printWindow.document.write('<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>');
    printWindow.document.write('<link href="https://fonts.googleapis.com/css2?family=Open+Sans&display=swap" rel="stylesheet"></link>');
    printWindow.document.write('<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">');
    printWindow.document.write("<style>*{font-family:'Open Sans',sans-serif;}p{margin:0}@media print{.marker{page-break-inside:avoid}}</style>");
    printWindow.document.write("<title>"+ t('markerreport_indicatortitle') +"</title>");
    printWindow.document.write("</head>");
    printWindow.document.write("<body>");
    printWindow.document.write("<div class='d-flex justify-content-between p-3'>");
    printWindow.document.write("<div class='d-flex flex-column'>");
    printWindow.document.write("<h1>"+ t('markerreport_title') +"</h1>");
    printWindow.document.write("<p>" + moment().format('YYYY-MM-DD HH:mm:ss') + "</p>");
    printWindow.document.write("</div>");
    printWindow.document.write("<img style='width:150px;object-fit:contain' src='/assets/imgs/logo-color.svg'/>");
    printWindow.document.write("</div>");
    printWindow.document.write("<div class='d-flex flex-column px-3'>");
    markers.forEach(mk => {
        printWindow.document.write("<div class='d-flex gap-2 py-3 border-bottom marker'>");
        printWindow.document.write("<img style='width:128px;object-fit:contain;align-self:flex-start;' src='" + mk.thumbnail + "'/>");
        printWindow.document.write("<div style='display:flex;flex-direction:column;grid-gap:0.5em'>");
        printWindow.document.write("<p><b>" + (mk.startMilliSecond > 0 ? convertMillisecondsToTime(mk.startMilliSecond) : convertSecondsToTime(mk.startSecond)) + '</b> - ' + (convertMillisecondsToTime(mk.startOffset + mk.endOffset)) + "</p>");
        printWindow.document.write("<p><b>" + (mk.title || t('markerreport_untitled')) + "</b></p>");
        printWindow.document.write("<p>" + (mk.description || t('markerreport_nodescription')) + "</p>");
        printWindow.document.write("</div>");
        printWindow.document.write("</div>");
    });
    printWindow.document.write("</div>");
    printWindow.document.write("</body>");
    printWindow.document.write("</html>");
    printWindow.document.close();
    printWindow.print();
}

export { generateReport };