import React, { useState } from 'react';
import Select, { createFilter } from "react-select";
import { Col, Modal, Form, Row } from 'react-bootstrap';
import { getRandomColor } from '../utils/NabUtils';
import { createTag, updateTag } from '../services/VitagTagsService';
import { listAllIcons } from '../services/CoreService';
import { ModalNewIcon } from './ModalNewIcon';
import { IconOption, IconSingleValue } from '../utils/IconsForSelect';
import { TagRow } from './helper/TagRow';
import { extractIcon, IconIndicator } from '../general/IconIndicator';
import { PERMISSIONS, useCheckPermission } from '../utils/permissions/PermissionsUtils';
import { useTranslation } from 'react-i18next';
import { config } from '../config';

function ModalButtonPad({videoId, currentButtonPad, showModal, setShowModal, savedTagListener}) {

    const defaultTagInfo = {name:'', icon:'', color:getRandomColor(), isTrainingMaterial:false};
    const [parentTag, setParentTag] = useState(defaultTagInfo);
    const [subTags, setSubTags] = useState([]);
    const [addToFavs, setAddToFavs] = useState(false);
    const [allIcons, setAllIcons] = useState([]);
    const [formErrors, setFormErrors] = useState({subTags:[]});
    const [showNewIcon, setShowNewIcon] = useState(false);
    const { t } = useTranslation();
    const allowIsTrainingMaterial = useCheckPermission(PERMISSIONS.MARK_AS_TRAINING_MATERIAL);
    let rowIdGenerator = Math.random() * 100;

    const initModal = () => {
        if (currentButtonPad) {
            const icon = extractIcon(currentButtonPad.icon, currentButtonPad.name);
            setParentTag({...currentButtonPad, icon});
            const initFormErrors = [];
            const subt = currentButtonPad.subTags || [];
            subt.forEach(
                tag => {
                    initFormErrors.push({tagName:false, tagIcon:false});
                    tag.rowId = rowIdGenerator++;
                }
            );
            subt.sort((t1, t2) => t1.sortPosition - t2.sortPosition);
            setFormErrors({...formErrors, subTags: initFormErrors});
            setSubTags(currentButtonPad.subTags);
        } else {
            setParentTag(defaultTagInfo);
            setFormErrors({subTags:[]})
            setSubTags([]);
        }
        listAllIcons()
            .then(resp => mapIcons(resp.data, true))
            .catch(err => {
                if(config.IS_BETA) console.error('Error getting icons', err)
            });
        if(config.IS_BETA) console.log('Add to favs? ' + (videoId != undefined));
        setAddToFavs(videoId != undefined);
    }

    const mapIcons = (newListIcons, reset = false) => {
        const mappedIcons = newListIcons.map(ic => ({
            value:ic.icon, label:ic.name, icon:ic.icon, keywords:ic.keywords
        }));
        const newIcons = reset ? mappedIcons : [...mappedIcons, ...allIcons];
        setAllIcons(newIcons);
    }

    const newIcon = () => {
        setShowNewIcon(true);
    }

    const handleParentTagInputs = (evt, propName) => {
        const value = evt.target.value;
        const newParentTag = {...parentTag};
        if(propName === 'name') {
            const icon = extractIcon(newParentTag.icon, value);
            newParentTag.icon = icon;
        }
        newParentTag[propName] = value;
        setParentTag(newParentTag);
    }

    const handleParentTagIcon = (evt) => {
        const newParentTag = {...parentTag};
        newParentTag.icon = evt?.icon;
        setParentTag(newParentTag)
    }

    const handleTrainingMaterial = (evt) => {
        const newParentTag = {...parentTag};
        newParentTag.isTrainingMaterial = evt.target.checked;
        setParentTag(newParentTag);
    }

    const validateForm = () => {
        const errors = {...formErrors};
        let hasErrors = false;
        if (!parentTag.name.trim()) {
            errors.parentTagName = true;
            hasErrors = true;
        }
        // if (! parentTag.icon) {
        //     errors.parentTagIcon = true;
        //     hasErrors = true;
        // }
        //subTags.forEach((st, i) => {
        //    console.log(st);
        //    if(! st.name || ! st.name.trim()) {
        //        hasErrors = true;
        //        errors.subTags[i].tagName = true;
        //    }
        //     if(! st.icon || ! st.icon.trim()) {
        //         hasErrors = true;
        //         errors.subTags[i].tagIcon = true;
        //     }
        //});
        setFormErrors(errors);
        return hasErrors;
    }

    const saveLeTag = () => {
        const formHasErrors = validateForm();
        if (formHasErrors) {
            if(config.IS_BETA) console.error('Form with errors!!!');
            return;
        }
        const toSave = {...parentTag, subTags};
        if(config.IS_BETA) console.log(toSave);
        if (! toSave.icon) {
            toSave.icon = toSave.name.split(' ').map(w => w[0]).join('')
                .toUpperCase().substring(0, 2);
        }
        toSave.subTags.forEach((st, i) => {
            st.sortPosition = i;
            if (! st?.icon) {
                st.icon = st.name.split(' ').map(w => w[0]).join('')
                    .toUpperCase().substring(0, 2);
            }
        });
        if (currentButtonPad) {
            updateTag(currentButtonPad.tagId, toSave)
                .then(resp => {
                    const newTag = resp.data;
                    savedTagListener && savedTagListener(newTag);
                    closeThisModal();
                })
                .catch(err => {
                    if(config.IS_BETA) console.error('Error updating tag', err)
                });
            return;
        }
        createTag(toSave, addToFavs, videoId).then(
            resp => {
                const leTag = resp.data;
                savedTagListener && savedTagListener(leTag);
                closeThisModal();
            }
        ).catch(
            err => {
                if(config.IS_BETA) console.error('Error creating new TAG', err)
            }
        );
    }

    const addNewSubTag = () => {
        const newFormErrors = {...formErrors};
        newFormErrors.subTags.push({tagName:false, tagIcon:false});
        setFormErrors(newFormErrors);
        const nt = {color:getRandomColor(), icon:'', name:'', rowId: rowIdGenerator++};
        setSubTags([...subTags, nt]);
    }

    const moveUp = (position) => {
        if (position === 0) {
            return;
        }
        moveTo(subTags[position], position, position - 1);
    }

    const moveDown = (position) => {
        if (position === subTags.length - 1) {
            return;
        }
        moveTo(subTags[position], position, position + 1);
    }

    const moveTo = (item, fromIdx, toIdx) => {
        const newSubTags = [...subTags];
        newSubTags.splice(fromIdx, 1);
        newSubTags.splice(toIdx, 0, item);
        setSubTags(newSubTags);
    }

    const removeSubTag = (rowId) => {
        const newSubTags = subTags.filter(t => t.rowId !== rowId);
        setSubTags(newSubTags);
        // const newFormErrors = {...formErrors};
        // newFormErrors.subTags.splice(position, 1);
        // setFormErrors(newFormErrors);
    }

    const setSubTag = (st, position) => {
        const newSubTags = [...subTags];
        newSubTags[position] = st;
        setSubTags(newSubTags);
        // const newFormErrors = {...formErrors};
        // newFormErrors.subTags.splice(position, 1);
        // setFormErrors(newFormErrors);
    }

    const giveNewIcon = (icon) => {
        if(config.IS_BETA) console.log('New icon received...', icon);
        const newIcons = [icon];
        mapIcons(newIcons);
    }

    const handleCheckbox = (evt) => {
        const val = evt.target.checked;
        setAddToFavs(val);
    }

    const closeThisModal = () => {
        setShowModal(false);
    }

    return (<>
    <Modal show={showModal} onShow={initModal} onHide={closeThisModal} size="xl">
        <Modal.Header className="d-flex justify-content-between">
            <button onClick={closeThisModal}
                title={t('modal_tagmanagement_cancel')}
                className="btn btn-sm align-self-center text-secondary">
                <span className="material-icons-round">close</span>
            </button>
            <Modal.Title>
                { t(currentButtonPad ? 'modal_tagmanagement_title_edittag' : 'modal_tagmanagement_title_newtag') }
            </Modal.Title>
            <div className="d-flex gap-2 align-self-center">
                <div className="dropdown">
                    <button className='btn btn-sm dropdown-toggle' data-toggle="dropdown">
                        <span className="material-icons-round">settings</span>
                    </button>
                    <div className="dropdown-menu">
                        { allowIsTrainingMaterial && 
                        <div className="dropdown-item cursor-pointer" onClick={evt => evt.stopPropagation()}>
                            <div className="form-check">
                                <input checked={parentTag?.isTrainingMaterial} onChange={handleTrainingMaterial}
                                    className="form-check-input" type="checkbox" id="materialTraining"/>
                                <label className="form-check-label cursor-pointer" htmlFor="materialTraining">
                                    { t('modal_tagmanagement_option_istrainingmaterial') }
                                </label>
                            </div>
                        </div> }
                        { videoId &&
                        <div className="dropdown-item cursor-pointer" onClick={evt => evt.stopPropagation()}>
                            <div className="form-check my-auto">
                                <input checked={addToFavs} onChange={handleCheckbox} className="form-check-input" type="checkbox" id="addToFavorite"/>
                                <label className="form-check-label cursor-pointer" htmlFor="addToFavorite">
                                    { t('modal_tagmanagement_option_addtofavs') }
                                </label>
                            </div>
                        </div> }
                        <div onClick={newIcon} className="dropdown-item cursor-pointer">
                            { t('modal_tagmanagement_option_addnewicon') }
                        </div>
                    </div>
                </div>
                <button onClick={saveLeTag}
                    title={t('modal_tagmanagement_ok')}
                    className='btn btn-sm vitag-text align-self-center'>
                    <span className="material-icons-round">save</span>
                </button>
            </div>
        </Modal.Header>
        <Modal.Body className='px-5'>
            <Row className="mb-3">
                <Col sm={12}>
                    <Form.Group className="input-container" controlId="inputTagName">
                        <Form.Label className='label-required'>{ t('modal_tagmanagement_label_tagname') }</Form.Label>
                        <Form.Control value={parentTag.name} onChange={(e) => handleParentTagInputs(e, 'name')}
                            name="inputTagName" className={"text-muted " + (parentTag.name ? '':'is-invalid')}
                            type="text" placeholder={t('modal_tagmanagement_placeholder_tagname')}/>
                        {<Form.Control.Feedback type="invalid">{t('modal_tagmanagement_feedback_requiredname')}</Form.Control.Feedback>}
                    </Form.Group>
                </Col>
                <Col sm={2}>
                    <Form.Group className="mt-0 input-container" controlId="inputTagColor">
                        <Form.Label>{ t('modal_tagmanagement_label_tagcolor') }</Form.Label>
                        <Form.Control value={parentTag.color} onChange={(e) => handleParentTagInputs(e, 'color')}
                            style={{height:'38px', width:'50px'}}
                            name="inputTagColor" className="form-control-color w-100" type="color"/>
                    </Form.Group>
                </Col>
                <Col sm={6}>
                    <label htmlFor="inputTagIcon">
                        { t('modal_tagmanagement_label_tagicon') }
                    </label>
                    <Select id="inputTagIcon"
                        value={allIcons.find(opt => opt.value === parentTag.icon)}
                        options={allIcons}
                        onChange={handleParentTagIcon}
                        placeholder={t('modal_tagmanagement_placeholder_selecticon')}
                        isClearable={true}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                        menuPortalTarget={document.body} 
                        components={{Option: IconOption, SingleValue: IconSingleValue}}
                        filterOption={createFilter({stringify: opt => `${opt.label} ${opt.data.keywords}`})}/>
                    { formErrors.parentTagIcon && <span className="text-danger small">{t('modal_tagmanagement_feedback_requiredicon')}</span>}
                </Col>
                <Col sm={4} className="mt-0 d-flex">
                    <div className="w-100 text-center">
                        <p className="m-0">
                            { t('modal_tagmanagement_label_tagpreview') }
                        </p>
                        <IconIndicator className='mx-auto my-2' iconText={parentTag.icon || parentTag.name} iconColor={parentTag.color}/>
                        <p className="text-muted m-0">{parentTag.name}</p>
                    </div>
                </Col>
            </Row>
            <Row className="mt-1 mb-3 justify-content-betwen">
                <Col className="mt-0">
                    <button onClick={addNewSubTag} className="btn btn-secondary">
                        { t('modal_tagmanagement_button_addsubtag') }
                    </button>
                </Col>
            </Row>
            { subTags.length === 0 &&
            <Row>
                <Col sm={12}>
                    <h5 className="my-4 text-center text-muted w-100">
                        {t('modal_tagmanagement_message_withouttags')}
                    </h5>
                </Col>
            </Row> }
            { subTags.length > 0 && 
            <div style={{maxHeight:'220px',overflow:'scroll'}}>
                <table className="table table-sm">
                    <thead>
                        <tr>
                            <th colSpan="2"></th>
                            <th className="text-center">
                                { t('modal_tagmanagement_subtag_th_tagname') }
                            </th>
                            <th className="text-center">
                                { t('modal_tagmanagement_subtag_th_tagcolor') }
                            </th>
                            <th className="text-center">
                                { t('modal_tagmanagement_subtag_th_tagicon') }
                            </th>
                            <th className="text-center">
                                { t('modal_tagmanagement_subtag_th_tagpreview') }
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                    { subTags.map((t, i) =>
                        <TagRow key={t.rowId}
                            originalTag={t}
                            setOriginalTag={evt => setSubTag(evt, i)}
                            moveUp={() => moveUp(i)}
                            moveDown={() => moveDown(i)}
                            availableIcons={allIcons}
                            removeSubTag={removeSubTag}/>
                    )}
                    </tbody>
                </table> 
            </div> }
        </Modal.Body>
    </Modal>
    <ModalNewIcon
        showModal={showNewIcon}
        setShowModal={setShowNewIcon}
        giveNewIcon={giveNewIcon} /> 
    </>);
}

export { ModalButtonPad }
