import React, { useState } from 'react';
import { Row, Col, Modal, Alert } from 'react-bootstrap';
import Papa from 'papaparse';
import { Trans, useTranslation } from 'react-i18next';
import { importMarkersForVideo } from '../services/VitagMarkersService';
import { useCustomToast } from '../utils/toasts/useCustomToast';
import { config } from '../config';

function ModalImportMarkersForVideo({
    showModal, setShowModal, vtgVideo,
    successImportListener = () => {
        if(config.IS_BETA) console.log('Import markers successful')
    }
} ) {

    const [toImport, setToImport] = useState([]);
    const [fileName, setFileName] = useState('');
    const [importSummary, setImportSummary] = useState();
    const { addToast } = useCustomToast();
    const { t } = useTranslation();

    const COLUMNS = {
        TIME:0, TITLE:1, DESCRIPTION:2, TAGS:3
    };

    const initModal = () => {
        setImportSummary(null);
        setFileName('');
        setToImport([]);
    }

    const closeThisModal = () => {
        setShowModal(false);
    }

    const startImportMarkers = () => {
        importMarkersForVideo(vtgVideo.hashId, toImport)
            .then(_ => {
                closeThisModal();
                successImportListener();
                addToast({
                    body: t('modal_importmarkersforvideo_toast_success_text'),
                    variant: 'success'
                })
            })
            .catch(err => {
                if(config.IS_BETA) console.error('Error', err)
            });
    }


    const startFileSelection = () => {
        document.getElementById('import-markers-input').click();
    }

    const handleFileSelection = (event) => {
        const file = event.target.files[0];
        setFileName(file.name);
        Papa.parse(file, { complete:handleParseResult });
    }

    const handleParseResult = (result) => {
        const newSummary = {
            errors: result.errors.length,
            rows: result.data.length - 1
        };
        setImportSummary(newSummary);
        // const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const rows = result.data.slice(1) //Ignoring first row
            .filter(rowData => rowData.length > 1) // Ignoring blank rows
            .map(row => {
                if(config.IS_BETA) console.log(row);
                const newMarker = {};
                newMarker.videoTime = row[COLUMNS.TIME];
                if (newMarker.videoTime.startsWith('-')) {
                    newMarker.videoTime = '00:00:00:000';
                }
                if (/^\d\d:\d\d$/.test(newMarker.videoTime)) {
                    newMarker.videoTime = '00:' + newMarker.videoTime + ':000';
                }
                if (/^\d\d:\d\d:\d\d\d$/.test(newMarker.videoTime)) {
                    newMarker.videoTime = '00:' + newMarker.videoTime;
                }
                if (! /^\d\d:\d\d:\d\d:\d\d\d$/.test(newMarker.videoTime)) {
                    newSummary.errors += 1;
                    return null;
                }
                newMarker.title = row[COLUMNS.TITLE];
                newMarker.description = row[COLUMNS.DESCRIPTION];
                newMarker.tags = row.slice(COLUMNS.TAGS);
                return newMarker;
            })
            .filter(marker => marker != null);
        setToImport(rows);
        if(config.IS_BETA) console.log(result.meta);
    }

    return(<>
        <Modal show={showModal} onShow={initModal} onHide={closeThisModal} size='lg'>
            <Modal.Header>
                <div className="d-flex justify-content-between w-100">
                    <button onClick={closeThisModal} className="btn btn-sm text-secondary"
                        title={t('general_cancel')}>
                        <span className="material-icons-round">close</span>
                    </button>
                    <Modal.Title>{t('modal_importmarkersforvideo_title')}</Modal.Title>
                    <button onClick={startImportMarkers} className="btn btn-sm text-vitag"
                        title={t('modal_importmarkersforvideo_upload_titleattr')}>
                        <span className="material-icons-round">upload</span>
                    </button>
                </div>
            </Modal.Header>
            <Modal.Body className='px-5 pb-4 pt-3'>
                <Row>
                    <Col sm={12}>
                        <p>
                            <Trans i18nKey="modal_importmarkersforvideo_text" values={{videoTitle:vtgVideo?.title}}>
                                Realiza la carga de múltiples marcadores en el video <b>videoTitle</b> por medio de un archivo CSV con un formato especial.
                                Puedes descargar una plantilla de ejemplo en <a className='vitag-text' download='plantilla-marcadores.csv' href='/assets/markers.csv'>este enlace</a>.
                            </Trans>
                        </p>
                        <div className="input-group mb-3">
                            <input onClick={startFileSelection} value={fileName}
                                type="text" readOnly className="form-control"
                                placeholder={t('modal_importmarkersforvideo_placeholder_selectfile')}/>
                            <div className="input-group-append">
                                <button onClick={startFileSelection} className="btn btn-outline-secondary">
                                    {t('modal_importmarkersforvideo_label_selectfile')}
                                </button>
                            </div>
                        </div>
                        { importSummary?.errors > 0 &&
                        <Alert variant='danger'>
                            {t('modal_importmarkersforvideo_alert', {errors:importSummary?.errors})}
                        </Alert>}
                    </Col>
                    <Col sm={12}>
                        { toImport.length > 0 &&
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>{t('modal_importmarkersforvideo_time_th')}</th>
                                    <th>{t('modal_importmarkersforvideo_title_th')}</th>
                                    <th>{t('modal_importmarkersforvideo_description_th')}</th>
                                    <th>{t('modal_importmarkersforvideo_tags_th')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                { toImport.map((res, idx) =>
                                <tr key={idx}>
                                    <td style={{maxWidth:'100px'}}>
                                        {res.videoTime}
                                    </td>
                                    <td title={res.title} className='text-truncate' style={{maxWidth:'120px'}}>
                                        {res.title}
                                    </td>
                                    <td title={res.description} className='text-truncate' style={{maxWidth:'180px'}}>
                                        {res.description}
                                    </td>
                                    <td>
                                       { res.tags.join(", ") }
                                    </td>
                                </tr>)}
                            </tbody>
                        </table>}
                    </Col>
                </Row>
                <input id='import-markers-input' onChange={handleFileSelection} type='file' className='d-none' accept='.csv'/>
            </Modal.Body>
        </Modal>
    </>)
}

export { ModalImportMarkersForVideo };