import React from "react";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function GroupItem({ leGroup, loggedUserId, viewAsGrid, startGroupEditing, confirmCloneGroup, confirmDeleteGroup, confirmLeaveGroup }) {

    const { t } = useTranslation();

    return (
        <Col xs={12} sm={6} md={viewAsGrid ? 4 : 6} lg={viewAsGrid ? 3 : 6}>
            <div className="d-flex w-100 p-2 mb-4 rounded border">
                <div className="dropdown">
                    <div className="nab-circle-btn" data-toggle="dropdown">
                        <span className="material-icons-round">
                            more_vert
                        </span>
                    </div>
                    { loggedUserId === leGroup.userId ?
                    <div className="dropdown-menu dropdown-menu-left">
                        <button onClick={() => startGroupEditing(leGroup)} className="dropdown-item">
                            { t('page_groups_groupitem_option_edit') }
                        </button>
                        <button onClick={() => confirmCloneGroup(leGroup)} className="dropdown-item">
                            { t('page_groups_groupitem_option_clone') }
                        </button>
                        <button onClick={() => confirmDeleteGroup(leGroup)} className="dropdown-item">
                            { t('page_groups_groupitem_option_delete') }
                        </button>
                    </div>
                    :
                    <div className="dropdown-menu dropdown-menu-left">
                        <button onClick={() => confirmLeaveGroup(leGroup)} className="dropdown-item">
                            { t('page_groups_groupitem_option_leavegroup') }
                        </button>
                    </div> }
                </div>
                <div className="ml-3 flex-grow-1" style={{minWidth:0}}>
                    <p className="mb-0 font-weight-bold">{leGroup.name}</p>
                    <p className="mb-0 w-100 text-truncate text-muted" title={leGroup.description}>
                        {leGroup.description || t('page_groups_groupitem_withoutdescription')}
                    </p>
                    <p className="mb-0 small text-muted">
                        {leGroup.memberCount} {t('page_groups_groupitem_members')}
                    </p>
                </div>
            </div>
        </Col>);
}

export { GroupItem };
