import React, { useEffect, useState  } from 'react';
import classNames from 'classnames';
import { getPreferenceAsNumber, PREFERENCES, savePreference } from "../preferences/user-preferences";
import { useTranslation } from "react-i18next";
import { useCustomToast } from '../toasts/useCustomToast';

function OpacitySelector () {

  const { t } = useTranslation();

  const [currentOpacity, setCurrentOpacity] = useState(getPreferenceAsNumber(PREFERENCES.MODAL_OPACITY));
  const [opacityAsPercent, setOpacityAsPercent] = useState(25);
  const [invalidOpacity, setInvalidOpacity] = useState(false);
  const { addToast } = useCustomToast();

  useEffect(() => {
    const convertedOpacity  = currentOpacity * 100;
    setOpacityAsPercent(convertedOpacity);
  }, []);

  const handleInputChange  = (evt) => {
    let newValue = parseFloat(evt.target.value); 

    if (isNaN(newValue)) {
      newValue = 10;
    }

    if (newValue > 75) {
      newValue = 75;
    }

    setOpacityAsPercent(newValue);
    setCurrentOpacity(newValue / 100.0)
  }

  const handleSave = () => {
    savePreference(PREFERENCES.MODAL_OPACITY, currentOpacity);

    addToast({
        header: t('page_profile_section_config_changeopacity_toast_title'),
        body: t('page_profile_section_config_changeopacity_toast_body'),
        variant: "success"
    });
  }

  const validateMark = () => {
    if (invalidOpacity) return true;
  }

  return (
    <div className='d-flex gap-0'>
      <div className="d-flex row m-0 w-100">
        <div className="d-flex col-12 col-lg-2 flex-column align-items-center px-lg-0 mt-lg-0">
            <div className="input-group">
                <input type="number" className={classNames('form-control', { 'invalid': invalidOpacity })} style={{fontSize:'0.95rem', textAlign:'center'}}
                        min={10} max={75} step={1}
                        onChange={(e) => handleInputChange(e)}
                        value={opacityAsPercent} 
                        id="opacityInput" placeholder='50' />
                <div className="input-group-append">
                    <button className='input-group-text justify-content-center' style={{width: 'calc(1.5em + .75rem + 2px)', fontSize:'0.95rem', padding:'.375rem .4rem'}}>%</button>
                </div>
            </div>
        </div>

        <div className="d-flex col-12 col-lg-auto flex-column align-items-center mt-3 mt-lg-0">
          <button onClick={() => handleSave()} disabled={validateMark()}
              className="btn btn-outline-dark col-12">
              {t('page_profile_section_save_changeopacity')}
          </button>
        </div>  
      </div>
      
    </div>
  );
}

export { OpacitySelector };