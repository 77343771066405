import React, { useEffect, useRef, useState } from 'react';
import { TabControl } from './TabControl';
import { GoogleAd } from '../utils/ads/GoogleAd';
import { PERSONAL_CONTEXT } from '../utils/ContextUtils';
import { AppNavbar } from '../general/AppNavbar';
import { useContext } from 'react';
import { GeneralContext } from '../contexts/general-context';
import { useTranslation } from 'react-i18next';
import { config } from '../config';

function HomeBase({
    resetListener = () => {
        if(config.IS_BETA) console.log('Reseting...')
    },
    searchListener = (search) => {
        if(config.IS_BETA) console.log(`Looking for '${search}'...`)
    },
    searchPlaceholder = 'home_general_search_placeholder',
    extraButtons = [
        {btnText: 'Crear', btnClass: 'btn btn-primary', btnClick: () => {
            if(config.IS_BETA) console.log('Creating...')
        }}
    ], 
    showExtraButtons = true,
    showSearchBar = true,
    pageSize, currentPage,
    forceRefresh, currentTabName, children}) {

    const [search, setSearch] = useState('');
    const lastTimeoutId = useRef(-1);
    const { currentContext } = useContext(GeneralContext);
    const { t } = useTranslation();

    useEffect(() => searchListener(), [forceRefresh]);

    useEffect(() => searchListener(search), [pageSize, currentPage]);

    useEffect(() => {
        const lastContextId = Number(localStorage.getItem('last-context'));
        if (currentContext?.organizationId !== lastContextId) {
            resetListener();
            searchListener(search);
        }
    }, [currentContext]);

    const handleSearch = (evt) => {
        const newSearch = evt.target.value;
        setSearch(newSearch);
        if (lastTimeoutId.current !== -1) {
            clearTimeout(lastTimeoutId.current);
        }
        lastTimeoutId.current = setTimeout(searchListener, 600, newSearch);
    }

    return (
        <React.Fragment>
            <TabControl activeTab={currentTabName}>
                <AppNavbar/>
            </TabControl>
            <div className="container-fluid px-md-5">
                { (showSearchBar || showExtraButtons) && <div className="row">
                    { showSearchBar &&
                    <div className="col-12 col-sm-12 col-md-6 col-lg-5 mb-2">
                        <input value={search} onChange={handleSearch} placeholder={ t(searchPlaceholder) }
                            className="form-control mr-3" type="text" id="searchInput" name="searchInput" required/>
                    </div> }
                    { showExtraButtons &&
                    <div className="col-12 col-sm-12 col-md-6 col-lg-7 mb-2 gap-3 justify-content-end align-items-center d-flex">
                        { extraButtons.map(btn => 
                        <button key={btn.btnText || btn.btnIcon} onClick={btn.btnClick} className={"btn btn-sm w-auto " + btn.btnClass}>
                            {btn.btnText}
                            {btn.btnIcon &&
                            <span className='material-icons-round my-auto cursor-pointer p-0'>
                                {btn.btnIcon}
                            </span>}
                        </button>) }
                    </div> }
                </div> }
            </div>
            <div className="container-fluid px-md-5 pb-5">
                {children}
                { /* currentContext?.organizationId == PERSONAL_CONTEXT && <div id='fake-margin-aux' style={{height:'110px'}}></div> */ }
            </div>
            { /* currentContext?.organizationId == PERSONAL_CONTEXT && <GoogleAd/> */ }
        </React.Fragment>
    );
}

export { HomeBase };
