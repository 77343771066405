import React, { useEffect } from 'react';
import { createFilter } from 'react-select';
import Select from 'react-select';
import { IconOption, IconSingleValue } from '../../utils/IconsForSelect';
import { extractIcon, IconIndicator } from '../../general/IconIndicator';
import { t } from 'i18next';

function TagRow({ originalTag, setOriginalTag, availableIcons, removeSubTag, moveUp, moveDown }) {

    useEffect(() => {
        const icon = extractIcon(originalTag.icon, originalTag.name);
        setOriginalTag({ ...originalTag, icon });
    }, []);

    const handleIcon = (evt) => {
        const newTag = {...originalTag, icon: evt?.icon};
        setOriginalTag(newTag);
    }

    const handleName = (evt) => {
        const name = evt.target.value;
        const icon = extractIcon(originalTag.icon, name);
        const newTag = {...originalTag, name, icon};
        setOriginalTag(newTag);
    }

    const handleColor = (evt) => {
        const newTag = {...originalTag, color: evt.target.value};
        setOriginalTag(newTag);
    }

    return (
        <tr>
            <td className="align-middle">
                <div className='d-flex'>
                    <button onClick={moveUp} className='btn btn-sm m-0 p-0'>
                        <span className='material-icons-round'>expand_less</span>
                    </button>
                    <button onClick={moveDown} className='btn btn-sm m-0 p-0'>
                        <span className='material-icons-round'>expand_more</span>
                    </button>
                </div>
            </td>
            <td className="align-middle">
                <button onClick={() => removeSubTag(originalTag.rowId)}
                    title={t('modal_tagmanagement_subtag_button_removesubtag_title')}
                    className="btn btn-sm btn-danger my-auto" style={{lineHeight:'0.5'}}>
                    <span className="material-icons-round text-white">delete</span>
                </button>
            </td>
            <td className="align-middle">
                <input name="inputSubTagName" value={originalTag.name} onChange={handleName}
                    placeholder={t('modal_tagmanagement_subtag_placeholder_tagname')}
                    className="form-control text-muted" type="text"/>
            </td>
            <td className="align-middle">
                <input name="inputSubTagColor" value={originalTag.color} onChange={handleColor}
                    style={{height:'38px'}} className="form-control-color w-100" type="color"/>
            </td>
            <td className="align-middle" style={{minWidth:'240px'}}>
                <Select id="inputSubTagIcon"
                    value={availableIcons.find(opt => opt.value === originalTag.icon)}
                    options={availableIcons}
                    onChange={handleIcon}
                    placeholder={t('modal_tagmanagement_subtag_placeholder_selecticon')}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                    menuPortalTarget={document.body}
                    isClearable={true}
                    components={{Option: IconOption, SingleValue: IconSingleValue}}
                    filterOption={createFilter({stringify: opt => `${opt.label} ${opt.data.keywords}`})}/>
            </td>
            <td style={{width: '160px'}}>
                <IconIndicator className='mx-auto' iconText={originalTag.icon} iconColor={originalTag.color}/>
            </td>
        </tr> );
}

export { TagRow };
