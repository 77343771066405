import React , { useState, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { AppNavbar } from "../general/AppNavbar";
import { canAutoShareVideo, saveAutoShareVideo } from "../services/VitagVideosService";
import { ContextSelector } from "../home/ContextSelector";
import { useContext } from "react";
import { GeneralContext } from "../contexts/general-context";
import { Trans, useTranslation } from "react-i18next";
import { getProfileById } from "../services/CoreService";
import { VideoProviderIcon } from "../general/VideoProviderIcon";
import moment from "moment";
import { FloatingProfileIndicator } from "../general/FloatingProfileIndicator";
import { config } from "../config";
import Cookies from "js-cookie";

function AutoShare() {

    let { hashId } = useParams('hashId');
    const [loading, setLoading] = useState(true);
    const [forbidden, setForbidden] = useState(false);
    const [vtgVideo, setVtgVideo] = useState();
    const [ownerUserProfile, setOwnerUserProfile] = useState();
    const [videoDuration, setVideoDuration] = useState('');
    const history = useHistory();
    const location = useLocation();
    const { t } = useTranslation();
    const { contexts, currentContext, overrideCurrentContext } = useContext(GeneralContext);

    useEffect(() => {
        document.title = config.TITLE_BASE + t('title_auto_share');
    }, []);

    useEffect(() => {
        loadVideoInfo();
    }, [hashId, currentContext]);

    const loadVideoInfo = () => {
        setLoading(true);
        setTimeout(() => {
            canAutoShareVideo(hashId)
                .then(resp => {
                    Cookies.remove('vitag-next');
                    setVtgVideo(resp.data);
                    const duration = moment.duration(resp.data.timeInSeconds, 'seconds');
                    setVideoDuration(duration.humanize());
                    loadUserInfo(resp.data.userId, true);
                })
                .catch(err => {
                    Cookies.set('vitag-next', '/share-video/' + hashId);
                    if(config.IS_BETA) console.error('Forbidden auto-share...', err.response.data);
                    const contextHint = /invalid_context,([0-9]+)/;
                    const contextHintResult = contextHint.exec(err?.response?.data || '');
                    if (contextHintResult == null){
                        setForbidden(true);
                        setLoading(false);
                        return;
                    }
                    const requiredContextId = Number(contextHintResult[1]);
                    const requiredContext = contexts.find(ctx => ctx.organizationId == requiredContextId);
                    if (! requiredContext) {
                        setForbidden(true);
                        setLoading(false);
                        return;
                    }
                    //force to override context
                    overrideCurrentContext(requiredContext);
                });
        }, 900);
    }

    const loadUserInfo = (userId) => {
        getProfileById(userId, true)
            .then(resp => {
                setOwnerUserProfile(resp.data);
                setForbidden(false);
                setLoading(false);
            })
            .catch(_ => {
                if(config.IS_BETA) console.error('Error getting user info for video-share');
            });
    }

    const saveAutoShare = () => {
        saveAutoShareVideo(hashId)
            .then(_ => {
                if(config.IS_BETA) console.log('Share saved...');
                history.replace('/videos');
            })
            .catch(_ => setForbidden(true));
    }

    const cancelShare = () => {
        history.replace('/videos');
    }

    return (
        <React.Fragment>
            <AppNavbar />
            <div className="position-fixed" style={{top:0,bottom:0,left:0,right:0,zIndex:1051,backgroundColor:'#00000088'}} >
                <Modal.Dialog>
                    <Modal.Header>
                        <button onClick={cancelShare}
                            title={t('general_cancel')}
                            className="btn btn-sm text-muted">
                            <span className="material-icons-round">close</span>
                        </button>
                        <h4 className="m-0">{t('modal_autoshare_title')}</h4>
                        { ! loading && ! forbidden &&
                        <button onClick={saveAutoShare}
                            title={t('modal_autoshare_btn_acceptvideo_titleattr')}
                            className="btn btn-sm text-vitag">
                            <span className="material-icons-round">check</span>
                        </button> }
                        { (loading || forbidden) &&
                        <div className="btn-placeholder"></div> }
                    </Modal.Header>
                    <Modal.Body className="px-4">
                        { loading &&
                        <div className="d-flex mb-3">
                            <div className="my-auto mr-2 spinner-grow spinner-grow-sm" role="status">
                                <span className="sr-only">{t('modal_autoshare_label_loading')}</span>
                            </div>
                            <p className="m-0">{t('general_loading_text')}</p>
                        </div> }
                        { ! loading &&
                        <div className="d-flex flex-column pb-2">
                            { forbidden && <>
                                <p>
                                    <Trans i18nKey='modal_autoshare_errorgettingvideo'>
                                        Lo sentimos, parece que no puedes acceder a este video, 
                                        prueba cambiar de contexto y si el inconveniente continúa 
                                        puedes escribirnos a <a href='mailto:info@vitag.es'>info@vitag.es</a>
                                    </Trans>
                                </p>
                                <div className="d-flex">
                                    <p className="text-muted mr-3 my-auto text-nowrap">
                                        {t('modal_autoshare_label_changecontext')}
                                    </p>
                                    <ContextSelector />
                                </div>
                            </> }
                            { ! forbidden && <>
                                <span className="mb-3">
                                    <FloatingProfileIndicator profile={ownerUserProfile}/> &nbsp;
                                    {t('modal_autoshare_text_usersharevideowithyou')}
                                </span>
                                <p>
                                    <Trans i18nKey='modal_autoshare_acceptsharevideo' values={{videotitle:vtgVideo.title}}>
                                        ¿Deseas agregar el video <b>'videotitle'</b> a tu lista de videos?
                                    </Trans>
                                </p>
                                <div className="d-flex position-relative w-75 mb-3 mx-auto">
                                    <img src={vtgVideo.miniature} alt={vtgVideo.title} className="w-100"/>
                                    <span style={{zIndex:'9999', bottom:'16px', left:'16px', fontWeight:'bold'}}
                                        className="py-1 px-2 position-absolute rounded bg-light text-dark">
                                        <VideoProviderIcon videoProvider={vtgVideo.videoProvider}/> {videoDuration}
                                    </span>
                                </div>
                                <button onClick={saveAutoShare} className="btn bg-vitag text-white w-fit mx-auto">
                                    {t('modal_autoshare_btn_acceptvideo_titleattr')}
                                </button>
                            </> }
                        </div> }
                    </Modal.Body>
                </Modal.Dialog>
            </div>
        </React.Fragment>
    );
}

export { AutoShare };
