import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { createVideo } from '../services/VitagVideosService';
import { PERMISSIONS, useCheckPermission } from '../utils/permissions/PermissionsUtils';
import { ConfirmModal } from '../general/ConfirmModal';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ListsSelector } from '../general/ListsSelector';
import { assingListsForVideo } from '../services/VitagListsService';
import { useEffect } from 'react';
import { getTypologyByInternalId } from '../services/CoreService';
import { Typologies } from '../utils/TypologiesEnum';
import { config } from '../config';

function ModalNewLiveSession({showModal, setShowModal}) {

    const initialBase = { title:'', url:'', referenceUrl:'', miniature:'', sharingUrl:'', timeInSeconds:0, isTrainingMaterial:false, onlineId: '' };
    const [initialVtgVideo, setInitialVtgVideo] = useState();
    const [videoReferenceUrl, setVideoReferenceUrl] = useState('');
    const [videoReferenceProvider, setVideoReferenceProvider] = useState();
    const [vtgVideo, setVtgVideo] = useState(initialBase);
    const [selectedListIds, setSelectedListIds] = useState([]);
    const [showUploadedSuccess, setShowUploadedSuccess] = useState(false);
    const allowIsTrainingMaterial = useCheckPermission(PERMISSIONS.MARK_AS_TRAINING_MATERIAL);
    const history = useHistory();
    const { t } = useTranslation();

    useEffect(() => {
        Promise.all([
            getTypologyByInternalId(Typologies.ACTIVE),
            getTypologyByInternalId(Typologies.VT_LIVESESSION)
        ])
        .then(resp => {
            setInitialVtgVideo(
                { ...initialBase, status:resp[0].data, videoProvider:resp[1].data }
            );
        })
        .catch(err => {
            if(config.IS_BETA) console.error('Error getting live-session provider', err)
        });
    }, [])

    const handleTitleChange = (event) => {
        const inputValue = event.target.value;
        setVtgVideo({...vtgVideo, title: inputValue});
    }

    const handleReferenceChange = (event) => {
        const inputValue = event.target.value;
        setVtgVideo({...vtgVideo, referenceUrl: inputValue});
    }

    const handleTrainingMaterialChange = (event) => {
        const inputChecked = event.target.checked;
        setVtgVideo({...vtgVideo, isTrainingMaterial: inputChecked});
    }

    const saveVideo = () => {
        const leVideo = {
            ...vtgVideo, fromLiveSession: true,
            miniature: 'https://vitag-storage.s3.eu-west-1.amazonaws.com/assets/custom/no-thumbnail.png'
        };
        createVideo(leVideo)
            .then(resp => {
                setVtgVideo({...vtgVideo, hashId:resp.data.hashId});
                setShowModal(false);
                setShowUploadedSuccess(true);
                if (selectedListIds.length > 0) {
                    return assingListsForVideo(resp.data.videoId, selectedListIds);
                }
                return new Promise(response => response('Nothing'));
            })
            .catch(err => {
                if(config.IS_BETA) console.error(err)
            });
    }

    const initModal = () => {
        setVtgVideo(initialVtgVideo);
        setVideoReferenceProvider(undefined);
        setVideoReferenceUrl('');
        setShowUploadedSuccess(false);
    }

    const closeModal = () => {
        setShowModal(false);
    }

    return (
        <>
        <Modal show={showModal} onShow={initModal} onHide={closeModal} size="xl">
            <Modal.Header>
                <button onClick={closeModal} 
                    title={t('modal_newlivesession_button_cancel')}
                    className="btn btn-sm">
                    <span className="material-icons-round">close</span>
                </button>
                <Modal.Title>
                    {t('modal_newlivesession_title')}
                </Modal.Title>
                <button onClick={saveVideo} 
                    title={t('modal_newlivesession_button_save')}
                    className="btn btn-sm text-vitag">
                    <span className="material-icons-round">save</span>
                </button>
            </Modal.Header>
            <Modal.Body className='px-5 pb-5'>
                <form className="form row" autoComplete='off'>
                    <div className="col-12 col-lg-6">

                        <div className="w-100 form-group mt-4">
                            <label className='w-100' htmlFor="inputVideoTitle">
                                {t('modal_newlivesession_label_title')}
                            </label>
                            <input value={vtgVideo.title} onChange={handleTitleChange}
                                className="form-control w-100" id="inputVideoTitle" name="inputVideoTitle" type="text"
                                placeholder={t('modal_newlivesession_placeholder_title')} required/>
                        </div>

                        <div className="w-100 form-group mt-4">
                            <label className='w-100' htmlFor="inputVideoReference">
                                {t('modal_newlivesession_label_reference')}
                            </label>
                            <input value={vtgVideo.referenceUrl} onChange={handleReferenceChange}
                                className="form-control w-100" id="inputVideoReference" name="inputVideoReference" type="text"
                                placeholder={t('modal_newlivesession_placeholder_reference')} required/>
                        </div>

                        { allowIsTrainingMaterial &&
                        <div className="w-100 d-flex justify-content-end mb-3">
                                <div className="form-check">
                                <input value={vtgVideo.isTrainingMaterial} onChange={handleTrainingMaterialChange}
                                    className="form-check-input" type="checkbox" id="materialTraining"/>
                                <label className="form-check-label cursor-pointer" htmlFor="materialTraining">
                                    {t('modal_newlivesession_label_istrainingmaterial')}
                                </label>
                            </div>
                        </div> }
                    </div>
                    <div className="col-12 col-lg-6">
                        <label className="mt-3 text-wrap">
                            {t('modal_newlivesession_label_selectlistsforvideo')}
                        </label>
                        <ListsSelector
                            selectedListIds={selectedListIds}
                            setSelectedListIds={setSelectedListIds}
                            refreshList={showModal}
                            showMini={true} />
                    </div>
                </form>
            </Modal.Body>
        </Modal>
        <ConfirmModal
            showModal={showUploadedSuccess} setShowModal={setShowUploadedSuccess} showCancelButton={false}
            modalTitle={t('modal_newlivesession_submodal_success_title')}
            modalText={t('modal_newlivesession_submodal_success_text')}
            acceptCallback={() => history.push(`live/${vtgVideo.hashId}`)} />
        </>
    );
}

export { ModalNewLiveSession };