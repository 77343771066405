import { useState } from "react";
import { useEffect } from "react";
import { Toast } from "react-bootstrap";
import { useCustomToast } from "./useCustomToast"

export const CustomToast = ({ children, id }) => {

    const { removeToast } = useCustomToast();
    const [showToast, setShowToast] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => removeToast(id), 8000);
        return () => clearTimeout(timer);
    }, [id, removeToast]);

    return (
    <Toast className={"toast-" + (children.variant ?? 'normal')}
        show={showToast} onClose={() => setShowToast(false)} autohide={true} delay={6800}
        style={{zIndex:'90000'}}>
        { children.header && <Toast.Header className="d-flex justify-content-between">
            <span className="fw-bold">{children.header}</span>
        </Toast.Header> }
        <Toast.Body> { children.body || children } </Toast.Body>
    </Toast>);

}

