import React from 'react';
import { LanguageSelector } from '../utils/translations/LanguageSelector';

function VitagFooter({ footerText = 'ViTAG© 2022 - ' + (new Date().getFullYear()) }) {
    return (
        <div className='d-flex justify-content-between px-4 pb-md-3'
            style={{position:'absolute',bottom:'0',right:'0',left:'0',zIndex:0}}>
            <span className="text-white">{footerText}</span>
            <LanguageSelector buttonClass='btn-sm btn-link text-white'/>
        </div>
    );
}

export { VitagFooter };
