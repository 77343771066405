import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

function Skeleton() {
    return (
        <div className="d-flex flex-column">
            <div className="skeleton-header px-4 py-2 d-flex justify-content-between">
                <div className="my-auto d-flex d-md-none">
                    <img src="/assets/imgs/isotipo-negative.svg" alt="ViTAG Logo"/>
                </div>
                <div className="my-auto d-none d-md-flex">
                    <img src="/assets/imgs/logo-white.svg" alt="ViTAG Logo" height="48px"/>
                </div>
                <div className="d-flex my-auto">
                    <div className="d-flex flex-column gap-1 pr-2 my-auto align-items-end">
                        <div className="skeleton-white-line animated-background" style={{width:'120px'}}></div>
                        <div className="skeleton-white-line animated-background" style={{width:'80px'}}></div>
                    </div>
                    <FontAwesomeIcon icon={faUserCircle} size='2x' className="skeleton-white animated-background"/>
                </div>
            </div>
            <div className="skeleton-tab d-flex px-5">
                <div className="skeleton-tab-item d-flex px-4 pt-2 active">
                    <span className="skeleton-tab-item-text animated-background my-auto"></span>
                </div>
                <div className="skeleton-tab-item d-flex px-4 pt-2">
                    <span className="skeleton-tab-item-text animated-background my-auto"></span>
                </div>
                <div className="skeleton-tab-item d-flex px-4 pt-2">
                    <span className="skeleton-tab-item-text animated-background my-auto"></span>
                </div>
                <div className="skeleton-tab-item d-flex px-4 pt-2">
                    <span className="skeleton-tab-item-text animated-background my-auto"></span>
                </div>
            </div>
            <div className="row w-100 mt-5 px-5">
            {Array.from({length:20}, (_, i) => 
                <div key={i} className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
                    <div className="skeleton-video w-100">
                        <span className="animated-background"></span>
                        <span className="animated-background"></span>
                        <span className="animated-background"></span>
                    </div>
                </div>)}
            </div>
        </div>
    )
}

export { Skeleton };
